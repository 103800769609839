var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isClicked)?_c('svg',{staticClass:"mapMarkerr",style:({
      top: this.marker.top,
      left: this.marker.left,
      right: this.marker.right,
      bottom: this.marker.bottom,
    }),attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"opacity":"0.8","cx":"5.16667","cy":"4.83334","r":"4.66667","fill":"white"}})]):_c('svg',{staticClass:"mapMarkerr",style:({
      top: this.marker.top,
      left: this.marker.left,
      right: this.marker.right,
      bottom: this.marker.bottom,
    }),attrs:{"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"opacity":"0.2","cx":"5.16667","cy":"4.83334","r":"4.66667","fill":"white"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }