<template>
  <div>
    <Mavlogo class="mavlogo" />
    <SkipButton v-if="show" to="/congratulations"></SkipButton>

    <div class="videoBgWrapper">
      <video
        src="videos/outro.mp4"
        @error="onVideoError"
        type="video/mp4"
        class="videoBg"
        controls
        controlsList="nodownload"
        disablePictureInPicture
        autoplay
        id="intro-vid"
      >
        <track
          default
          src="vtt/outro.vtt"
          kind="subtitles"
          srclang="hu"
          label="Magyar"
        />
      </video>
    </div>
  </div>
</template>

<script>
import SkipButton from "../components/SkipButton.vue";
import Mavlogo from "../components/MavLogo.vue";
import "video.js/dist/video-js.css";

export default {
  name: "Outro",
  components: {
    SkipButton,
    Mavlogo,
  },
  data: function () {
    return {
      show: false,
      scene: null,
      success: null,
    };
  },
  methods: {
    onVideoError() {
      let vid = document.getElementById("intro-vid");
      vid.src = "videos/720/outro_720.mp4";
    },
  },
  mounted: function () {
    var self = this;
    document.getElementById("intro-vid").onended = function () {
      self.$router.push("congratulations");
    };

    setTimeout(function () {
      self.show = true;
    }, 3000);

    // var v = document.getElementById('intro-vid')
    // v.textTracks[0].mode = "hidden"
  },
};
</script>