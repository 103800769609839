export default {
    toDegree (rad) {
        var pi = Math.PI;
        return rad * (180 / pi);
      },
      toRad (degree) {
        var pi = Math.PI;
        return degree * (pi / 180);
      },
      getDegreeDiff (deg_1, deg_2)
      {   
      return (deg_1-deg_2+540) % 360 - 180;
      },
      subtractMultipleRounds(degree) {
        var hanyszor = degree / 360;
        hanyszor = hanyszor >= 0 ? Math.floor(hanyszor) : Math.floor(hanyszor) + 1;
        if (Math.abs(hanyszor) >= 1) {
          degree = degree - hanyszor * 360;
        }
        return degree;
      },
      resetCamera: function(x,y) {
        var camera = document.querySelector("a-camera");
      camera.setAttribute("look-controls", "magicWindowTrackingEnabled", false);
        camera.components["look-controls"].pitchObject.rotation.x = x;
        camera.components["look-controls"].yawObject.rotation.y = y;
        setTimeout(function() {
        camera.setAttribute("look-controls", "magicWindowTrackingEnabled", true);
      }, 2000)
    },
    toggleFullscreen: function() {
        if (
          (document.fullScreenElement && document.fullScreenElement !== null) ||
          (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
          if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
    }
}