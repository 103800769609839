<template>
  <div class="mav-congrats-container">
      
      <div class="mav-tutorial-title">
            <div class="mav-tutorial-title-logo">
                <a href="#">
                    <img src="utkozespont-logo.svg" alt="Ütközéspont logo">
                </a>
            </div>
            <div class="mav-tutorial-title-divider"></div>
            <div class="mav-tutorial-title-text">
                Köszönjük a játékot!
            </div>
        </div>

        <FullScreenButton></FullScreenButton>

        <div class="mav-congrats-content">
            <div class="mav-congrats-share-container">

                <h2 class="mav-congrats-title">Kedves játékosunk!</h2>

                <p class="mav-congrats-description">Reméljük az elmúlt percekben jól szórakoztál, és közben sikerült felhívnunk a figyelmedet a vasúti átkelők veszélyeire is. Ha tetszett a játékunk és Te is fontosnak tartod, hogy üzenetünk minél több emberhez elérjen, kérjük az alábbi linkre kattintva oszd meg az élményt másokkal is. Köszönjük, hogy velünk játszottál!
                </p>

            <!-- <div data-layout="button_count" data-href="https://www.your-domain.com/your-page.html"  class="mav-share-button-outer share-button-margin fb-share-button">
                <div class="mav-share-button mav-button mav-button-secondary">
                    <span class="mav-main-text">Megosztás</span>
                </div>
            </div> -->
            <div class="mav-share-button-outer share-button-margin fb-share-button">

            <!-- <div class="mav-share-button mav-button mav-button-secondary" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="large">
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore mav-main-text">Megosztás</a>
            </div> -->

            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Futkozespont.hu%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore mav-main-text">
                <div class="mav-share-button mav-button mav-button-secondary" data-href="https://utkozespont.hu" data-layout="button" data-size="large">
                Megosztás
                </div>
            </a>
            </div>
            </div>

            <div class="mav-congrats-badge-container">
                <div class="congrats-background">
      <img src="congrats-background.svg" alt="background">
      </div>
                <div class="badge-image">
                    <img class="badge-image-inner" v-if="this.getQuizPoints <= 3" src="/badges/lelkes_ujonc.svg" alt="nyomozok kiralya badge">
                    <img class="badge-image-inner" v-if="this.getQuizPoints <= 6 && this.getQuizPoints > 3" src="/badges/szemfules_helyszinelo.svg" alt="nyomozok kiralya badge">
                    <img class="badge-image-inner" v-if="this.getQuizPoints > 6" src="/badges/nyomozokkiralya.svg" alt="nyomozok kiralya badge">
                </div>

                    <h2 class="congrat-heading">Gratulálunk!</h2>
                    <div class="description-container">
                        <p class="description" v-if="this.getQuizPoints <= 3">Látszik az igyekezet, legközelebb talán még jobban sikerül.</p>
                        <p class="description" v-if="this.getQuizPoints <= 6 && this.getQuizPoints > 3">Jó helyszínelő vagy.</p>
                        <p class="description" v-if="this.getQuizPoints > 6">Úgy néz ki semmi fontos információ nem kerülte el a figyelmed, te vagy a nyomozók királya!</p>
                    </div>
                <div>
                    <img src="congrats-mav-logo.svg" alt="mav logo">
                </div>
            </div>

            <div class="mav-congrats-campaign-container">
                <div class="badges-container">
                    <div id="badge-1"><img src="/badges/szemfules_helyszinelo.svg" alt="ugyes helyszinelo badge"></div>
                    <div id="badge-2"><img src="/badges/nyomozokkiralya.svg" alt="nymomozok kiralya badge"></div>
                    <div id="badge-3"><img src="/badges/lelkes_ujonc.svg" alt="lelkes amator badge"></div>
                </div>

                <div class="mav-congrats-separator"></div>

                <div @click="openVideoModal" class="mav-congrats-watch-video-container">
                    <div class="play-container-2">
                        <img src="play.svg" alt="play icon">
                    </div>
                    <p class="watch-video-text">Kampány videó megtekintése</p>
                </div>
                    <div class="mav-share-button-outer home-button-space">

                    <a href="https://utkozespont.hu">
                        <div class="mav-home-button mav-button mav-button-primary">
                            <span class="home-button-text">Vissza a főoldalra</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>


        <div class="mav-main-frame">
            <div class="mav-main-frame-item mav-main-frame-top-left">
                <img src="/utkozespont-frame-top-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-top-right">
                <img src="/utkozespont-frame-top-right.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-left">
                <img src="/utkozespont-frame-bottom-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-right">
                <img src="/utkozespont-frame-bottom-right.svg" alt="Frame item">
            </div>
        </div>

        <VideoModal :name="'clue100'" @closeModalEvent="toggleModal" v-if="this.showModal" :video="'kampany.mp4'"></VideoModal>
  </div>
</template>

<script>
import FullScreenButton from "../components/FullScreenButton";
import VideoModal from "../components/VideoModal";
import axios from 'axios'

export default {
    name:"Congratulations",
    components: {
        FullScreenButton,
        VideoModal,
    },
    computed: {
        getQuizPoints: function() {
           return this.$store.getters.getQuizPoints;
        }
    },
    methods: {
        handleScroll() {
            var currentScrollPos = window.pageYOffset;
            if (currentScrollPos < this.prevScrollpos) {
                if(currentScrollPos < 100) {
                let title = document.querySelector(".mav-tutorial-title");
                if(title) title.style.top = this.topPos+'px';
                let fs = document.querySelector(".mav-main-fullscreen");
                if(fs) fs.style.top = this.topPos+'px'
                

                }
            } else {
                if(currentScrollPos > 0){
                    let title = document.querySelector(".mav-tutorial-title");
                    if(title) title.style.top = "-70px";
                    let fs = document.querySelector(".mav-main-fullscreen");
                    if(fs) fs.style.top = "-70px"
                }
            }
            this.prevScrollpos = currentScrollPos;
        },
        restartGame() {
            //NAVIGATE
            this.$router.push({name: 'Home'});
            //RESET STORE POINTS... ANSWERS CLUES..
            this.$store.commit('resetClickedMarkers');
            this.$store.commit('setQuizPoints', 0);
        },
        toggleModal() {
            this.showModal = false
        },
        openVideoModal() {
            // console.log("ASD");
            this.showModal = true
        }
    },
    data: function() {
        return {
            topPos: 30,
            showModal: false,
            prevScrollPos: window.pageYOffset,
        }
    },
    beforeDestroy: function() {
       window.removeEventListener("scroll", this.handleScroll);
    },
    
    mounted: function() {
        if(typeof localStorage.uuid !== 'undefined') {
            axios
                .get('https://backend.utkozespont.hu/finish/' + localStorage.uuid)
                .then(response => (this.success = response.data.success))
                .catch(e => {
                    console.error(e);
                });
        }
        var self = this;
        let rect =  document.querySelector(".mav-tutorial-title").getBoundingClientRect();
        self.topPos = rect.top;
        window.addEventListener("scroll", this.handleScroll);
    },
}
</script>

<style>
.badge-image-inner {
    width: 100%;
    height: auto;
}
.congrats-background {
    position:absolute;
    left:0px;
    top:0px;
    opacity: 0.04;
}
.home-button-text {

font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 23px;
}
.mav-congrats-container {
    display: flex;
  /* padding-right: 69px; */
  /* padding-left: 69px; */
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  /* background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%); */
  background: #001135;
  background-image: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
  /* background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%); */
  /* backdrop-filter: blur(42px); */
  /* background: white; */
  min-height: 100vh;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  overflow-y: auto;
  overscroll-behavior: unset;
  overflow-x: hidden;
}
.mav-congrats-content {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.congrat-heading {
    margin-top: 33px;

font-style: normal;
font-weight: bold;
font-size: 32px;
align-self: center;
width: 458px;
line-height: 38px;
/* text-align: center; */
color: #FFF;
}
.watch-video-text {

font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
display: flex;
align-items: center;

/* mav-white */

color: #FFFFFF;
width: 103px;
}
.play-logo {
    width: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.badge-image  {
    max-width: 182.77px;
    width: 182.77px;
    height: auto;
    margin-top:-90px;
    /* transform: translateY(-40%); */
}
.mav-home-button {
    width:255px;
    height: 60px;
}
.mav-main-text {
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 22px;
/* or 183% */

display: flex;
align-items: center;

/* mav-white */

color: #FFFFFF;
}
.mav-congrats-description {
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 22px;
width: 180px;
/* or 183% */

display: flex;
align-items: center;
color: #FFF;
}
.mav-congrats-title {
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 25px;
display: flex;
align-items: center;

/* mav-white */

color: #FFFFFF;
margin-bottom: 31px;
margin-top: 48px;
}
.mav-congrats-share-container {
    max-width: 270px;
    background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(42px);
display: flex;
align-items: center;
justify-content: center;
text-align: center;
flex-direction: column;
/* Note: backdrop-filter has minimal browser support */

border-radius: 5px;
}
.mav-congrats-badge-container {
    max-width: 609px;
    width:609px;
    height: 434px;
    background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(42px);
justify-content: flex-start;
/* Note: backdrop-filter has minimal browser support */

border-radius: 5px;
display: flex;
text-align: center;
align-items: center;
flex-direction: column;

}

.mav-congrats-campaign-container {
    max-width: 277px;
    width: 277px;
    flex-direction: column;
    display:flex;
    height: 434px;
    border-radius: 5px;
    justify-content: space-between;
}
.mav-share-button {
    width: 100%;
    height: 40px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mav-share-button-outer {
padding: 8px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 100px;  
    cursor: pointer;
    
       

}

.mav-congrats-watch-video-container {
    cursor: pointer;
    width: 100%;
    height: 119px;
   border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: .5s all;
}
.mav-congrats-watch-video-container:hover {
    background-color: rgba(255,255,255,0.1);
    transition: .5s all;
}
.share-button-margin {
    margin: 18px 40px 36px 40px; 
}
.home-button-space {
    padding: 10px;

}
/* .mav-congrats-share {
    max-width: 190px;
} */
.description {
    margin: 20px auto 50px;

font-size: 18px;
align-self: center;
/* width: 458px; */
line-height: 20px;
/* text-align: center; */
color: #FFF;
max-width: 90%;
}

.description-container {
    width: 90%;
}
.badges-container {
    display: flex;
    flex-direction: row;
}
.mav-congrats-separator {
height: 0px;
width: 100%;
border: 1px solid rgba(255, 255, 255, 0.3);
}

.play-container-2 {
    height: 50px;
    width: 50px;
    position: relative;
    display:flex;
    /* margin-left: 41px; */
    /* margin-right: 20px; */
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    background: rgba(0, 17, 53, 0.32);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(12px) !important;
border-radius: 100px;
cursor: pointer;
}
#badge-2 {
        max-width: 122px;
        height: auto;
    }

@media only screen and (max-width: 1440px) { 
    .badge-image {
        height: auto;
        width: 80%;
    }
}

@media only screen and (max-width: 1280px) { 
    .mav-congrats-content {
    display:flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
}
.mav-congrats-share-container {
    max-width: 609px;
    width: 609px;
    margin-top: 200px;
 
}
.mav-congrats-badge-container {
   margin-top: 150px;
   margin-bottom: 50px;

}

.mav-congrats-campaign-container {
    margin-bottom: 100px;
    max-width: 609px;
    width: 609px;
    align-items: center;
}

}

@media only screen and (max-width: 980px) { 
   
.mav-congrats-share-container {
    margin-top: 150px;
}

}

@media only screen and (max-width: 768px) { 
   
.mav-congrats-content {
    display:flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
}
.mav-congrats-share-container {
    width: 80%;
    margin-top: 150px;
 
}
.mav-congrats-badge-container {
   margin-top: 150px;
   margin-bottom: 50px;
   width: 80%;

}

.mav-congrats-campaign-container {
    margin-bottom: 100px;
    width: 80%;
    align-items: center;
}

}



</style>