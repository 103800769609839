<template>
  <div
    v-if="!this.isMobile"
    @mousedown="setVolume"
    v-on:mouseup="mouseUp"
    @mousemove="setVolumeOnMouseMove"
    class="volume"
  >
    <img class="volume-size" src="soundup.svg" alt="volume up" />
    <div id="bar">
      <div
        id="actual-volume"
        v-bind:style="{
          height: volume + '%',
          width: '5px',
          background: 'rgba(196, 196, 196, 0.8)',
        }"
      ></div>
    </div>
    <img class="volume-size" src="sounddown.svg" alt="volume down" />
  </div>
</template>

<script>
export default {
  name: "VolumeSetter",
  props: ["audioVolume", "id"],
  data: function () {
    return {
      volume: 70,
      drag: false,
      volumeHeight: 0,
      hanyados: 0,
    };
  },
  computed: {
    globalVolume: function () {
      return this.$store.getters.getGlobalVolume;
    },
    isMobile: function () {
      return this.$store.getters.isMobile;
    },
  },
  methods: {
    setVolume(e) {
      this.drag = true;
      this.setVolumeOnMouseMove(e);
     
    },
    mouseUp() {
      this.drag = false;
    },
    setVolumeOnMouseMove(e) {
      if (this.drag) {
        // console.log("clientY",e.clientY)
        let div = document.getElementById("bar");
        let bounds = div.getBoundingClientRect();
        //console.log(divY);
        var y = e.clientY - bounds.top;
        this.updateBar(y);
      }
    },
    updateBar(y) {
      //  console.log(adat)
      if (y < 0) {
        this.volume = 100;
      } else if (y > this.volumeHeight) {
        this.volume = 0;
        this.$store.commit("setGlobalVolume", 0);
      } else {
        this.volume = Math.floor(
          (((y - this.volumeHeight) * -1) / this.volumeHeight) * 100
        );
        this.$store.commit(
          "setGlobalVolume",
          Math.floor((((y - this.volumeHeight) * -1) / this.volumeHeight) * 100)
        );
      }

      let vid = document.getElementById(this.id);
      vid.volume = this.volume / 100;
    },
  },
  mounted: function () {
    let volHeight = document.querySelector("#bar");
    this.volumeHeight = volHeight.clientHeight;
    this.volume = this.audioVolume;
  },
  watch: {
    volume: function (newVolume) {
      if (newVolume <= 0) {
        this.$emit("volumeMuted");
      } else {
        this.$emit("volumeUnmuted");
      }
    },
  },
};
</script>

<style scoped>
.volume {
  display: none;
}
.cont {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: none;
}

.volume-size {
  pointer-events: none !important;
}

#actual-volume {
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .volume {
    position: absolute;
    width: 40px;
    height: 35vh;
    bottom: 0;
    margin-left: -5px;
    bottom: 75px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 24px;
  }
  #bar {
    width: 5px;
    height: 24vh;
    background: rgba(196, 196, 196, 0.2);
    display: flex;
    align-items: flex-end;
    z-index: 3;
  }
  .volume-size {
    width: 20px;
    height: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .volume {
    margin-left: -1px;
    bottom: 90px;
  }
}

@media only screen and (min-width: 1440px) {
  .volume {
    margin-left: 6px;
    bottom: 110px;
  }
}
</style>