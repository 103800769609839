<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
     <span class="nr">{{this.id}}.</span> 
     <span :class="[selected === 'Válasz' ? 'inactive-answer' :'', 'default-selected']">{{ selected.substring(0,15) }}</span>
     <!-- <span v-else >{{ selected.substring(0,15) }}</span> -->
     <div :class="['select-icon', 'is-closed']"></div>
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
      class="item"
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option.answer;
          open = false;
          $emit('input', {questionId: id,chosen:option.answer, chosenId:option.id});
        "
      >
        <p v-if="selected===option.answer" >{{ option.answer }}</p>
        <p v-else class="inactive-answer" >{{ option.answer }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    id: {
      options: {
        type: Number,
        required: true,
      }
    }
  },
  data() {
    return {
      selected: "",
      open: false,
      isOpen: 'is-open',
      isClosed: 'is-closed'
    };
  },
  mounted() {
    this.selected = this.default;
    // this.$emit("input", this.selected);
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background:#001135;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #001135;
}
::-webkit-scrollbar-corner {
    background: #001135;
    display:none;
}
.default-selected {
  color: #FFF;
}
.nr {

font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 25px;
margin-right: 5px;
/* mav-yellow */
color: #FFCC33;
}
.inactive-answer {
  color: #FFFFFF80
}
.is-open {
   transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.is-closed {
  transform: rotate(45deg) translateY(-50%);
    /* -webkit-transform: rotate(45deg); */
}
.custom-select {
  position: relative;
  width: 185px;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
font-style: normal;
font-weight: bold;
margin-bottom: 19px;
margin-right: 10px;
/* z-index: 2; */
margin-left: 10px;

/* mav-yellow */

/* color: #FFCC33; */
}

.custom-select .selected {
  /* background-color: #0a0a0a; */
  border-bottom: 1px solid #FFFFFF33;
  /* color: #fff; */
  /* padding-left: 1em; */
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

.custom-select .select-icon {
  position: absolute;
  content: "";
  /* top: 22px; */
  /* right: 1em; */
  /* width: 0; */
  /* height: 0; */
  /* transform: translateY(50%); */
   border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  /* padding: 3px; */
  border: solid white;
  border-width: 0 2px 2px 0;
  top: 50%;
  right: 5%;
  display: inline-block;
  padding: 3px;

  /* border-color: #fff transparent transparent transparent; */
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  font-size: 14px;
line-height: 18px;
  position: absolute;
  overflow-y: scroll;
overflow-x: hidden;
/* height: 217px; */
max-height: 190px;
position:relative;
  /* background: linear-gradient(180deg, #001135 0%, rgba(0, 17, 53, 0) 40.63%); */
  background-color: #0a0a0a;
  background-color: #001135;
  left: 0;
  right: 0;
  z-index: 30;
  padding-right: 16px;
  padding-left: 16px;
}

.custom-select .items .item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  padding-top: 10px;
    color: #FFFFFF;
    /* opacity: 0.5; */
font-style: normal;
font-weight: normal;


}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  /* background-color: #ad8225; */
}

.selectHide {
  display: none;
}

@media only screen and (max-width: 1440px) { 
   .custom-select {
     width: 150px;
   }
  
   .custom-select .items {
  font-size: 12px;
  line-height: 16px;
  padding-right: 8px;
  padding-left: 8px;
}

}
</style>
