import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VuePageTransition from 'vue-page-transition'
import helpers from './helpers'


import VModal from 'vue-js-modal';
import AFRAME from 'aframe'

Vue.config.productionTip = false;
Vue.prototype.$aframe = AFRAME;
Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-sphere',
  'a-cylinder',
  'a-plane',
  'a-text',
  'a-ring',
  'a-circle',
  'a-rounded',
  'a-image',
  'a-assets',
  'a-videosphere',
  'a-asset-item',
];

const plugin = {
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}


Vue.use(plugin)
Vue.use(VuePageTransition)
Vue.use(VModal)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
