<template>
    <!-- <router-link class="btn next" :to="this.to"> -->
        <div id="btn" class="btn next">
            <div class="btn-inner">
                <span class="btn-text" style="color: white">Kihagyás</span>
                <img class="rotate" src="Vector.svg" alt="">
            </div>
        </div>    

    <!-- </router-link> -->
</template>

<script>
export default {
  props: ["to"],
  mounted: function () {
    var self = this;
    document.getElementById('btn').addEventListener('click', () => { self.$router.push({ path: self.to }) })
  }
}
</script>

<style scoped>
  .btn.next {
    position: absolute;
    right: 6.6%;
  }
  .rotate {
    margin-left: 30px;
    margin-right: -15px;
    height: 15px;
  }
 .btn {
   cursor: pointer;
  position: absolute;
  display: flex;
  z-index: 1;
  background: rgba(0, 40, 122, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(42px);
}

.btn-text {
  font-weight: 600;
  font-size: 15px;
  transform: rotate(360deg);
}

.btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(87.46deg, #0075FF -46.67%, #00287A 67.69%);
  box-shadow: 0px 4px 20px rgba(32, 32, 32, 0.5);
  border-radius: 100px;
  /* transform: rotate(-180deg); */
}


@media only screen and (min-width: 320px) {
  .btn-inner {
    width: 120px;
    height: 30px;
  }
  .btn {
    padding: 4px;
  }
  .btn-text {
    display: block;
    font-size: 12px;
  }
  .rotate {
    margin-left: 10px;
    margin-right: 0;
    height: 12px;
  }
  .btn.next {
    right: 28px;
    top: 30px;
  }
}

@media only screen and (min-width: 768px) { 
  .btn {
    padding: 6px;
  }
  .btn-inner {
    width: 120px;
    height: 40px;
  }

  .btn-text {
    display: block;
  }
  .rotate {
    margin-left: 10px;
    margin-right: 0;
    height: 13px;
  }
  .btn.next {
    right: 48px;
    top: 48px;
  }
}

@media only screen and (min-width:1024px) {
  .btn {
    width: 176px;
    height: 56px;
  }
  .btn-inner {
    width: 160px;
    height: 40px;
  }
  .rotate {
    margin-left: 25px;
    margin-right: -15px;
    height: 14px;
  }
  .btn-text {
    font-size: 14px;
  }
  .btn-text {
    display: block;
  }
  .btn.next {
    right: 75px;
    top: 60px;
  }
}

@media only screen and (min-width: 1440px) { 
  .btn {
    width: 196px;
    height: 66px;
  }
  .btn-inner {
    width: 180px;
    height: 50px;
  }
  .btn-text {
    font-size: 15px;
  }
  .btn.next {
    right: 120px;
    top: 66px;
  }
}



</style>