<template>
    <!-- <router-link class="btn next" :to="this.to"> -->
        <div @click="goPrev" v-if="this.$store.state.btnShow" id="btn-prev" class="prev btn2">
          <div class="btn-inner">
            <span class="btn-text">{{this.text}}</span>
            <img class="rotate" src="Vector.svg" alt="">
          </div>
        </div>      


        <!-- <div id="btn" class="btn next">
          <div class="btn-inner">
            <span v-if="this.to == '/still_1'" class="btn-text" style="color: white">Kihagyás</span>
            <img class="rotate" src="Vector.svg" alt="">
          </div>
        </div> -->

    <!-- </router-link> -->
</template>

<script>
export default {
  props: ["to","text"],
  /* mounted: function () {
    console.log(this.to)
    var self = this;
    if(document.getElementById('btn-prev')) {
      document.getElementById('btn-prev').addEventListener('click', () => { self.$router.push({ path: self.to }) })
    }
  }, */
  methods: {
    goPrev: function() {
      this.$emit("previousClicked");
    }
  }
}
</script>

<style scoped>
  .prev {
    position: absolute;
    left: 6.6%;
    bottom: 9.59%;
  }
  .rotate {
    margin-left: 21.5px;
    margin-right: -23.41px;
    max-width: 12.35px;
    height: auto;
  }
 .btn2 {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  background: rgba(0, 40, 122, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);

  transform: rotate(180deg);

}

.btn-text {
  transform: rotate(180deg);

font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 16px;
align-items: center;
text-align: center;

/* mav-blue */

color: #00287A;
}

.btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(32, 32, 32, 0.5);
  border-radius: 100px;
  /* transform: rotate(-180deg); */
}

@media only screen and (min-width: 320px) and (orientation: portrait) {
  .btn2 {
    padding: 4px;
  }

  .btn-inner {
    width: 120px;
    height: 30px;
  }
  .btn-text {
    display: block;
    padding-left: 10px;
    font-size: 12px;
  }
  .rotate {
    margin-left: 0;
    margin-right: 0;
    height: 12px;
  }
  .prev {
    left: 28px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 320px) and (orientation: landscape) {
  .btn2 {
    padding: 6px;
  }

  .btn-inner {
    width: 120px;
    height: 30px;
  }
  .btn-text {
    display: block;
    padding-left: 10px;

  }
  .rotate {
    margin-left: 0;
    margin-right: 0;
    height: 12px;
  }
  .prev {
    left: 28px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 600px) { 
  .prev {
    bottom: 43px;
  }
}


@media only screen and (min-width: 768px) { 
  .btn2 {
    padding: 6px;
  }
  .btn-inner {
    width: 120px;
    height: 40px;
  }

  .btn-text {
    display: block;
    padding-left: 10px;

  }
  .rotate {
    margin-left: 0;
    margin-right: 0;
    height: 13px;
  }
  .prev {
    left: 48px;
    bottom: 48px;
  }
}

@media only screen and (min-width:1024px) {
  
  .btn-inner {
    width: 120px;
    height: 40px;
  }
  
  .btn-text {
    display: block;
  }
  .prev {
    left: 41px;
    bottom: 52px;
  }
}

@media only screen and (min-width: 1440px) { 
  
  .btn-inner {
    width: 140px;
    height: 40px;
  }
  
  .prev {
    left: 54px;
    bottom: 60px;
    padding: 8px;
  }
} 






</style>