<template>
  <div id="google" class="modaloverlay">
    <div id="myDiv" class="mymodal">
      <transition name="fade">
      <img v-if="this.show" @click.stop="closeModal" id="closemodal" src="closemodal.svg" height="50" width="50" alt="">
      </transition>
    <video class="modal-video" autoplay width="1160" controls controlsList="nodownload" disablePictureInPicture id="video">
      <source :src="!this.isLowTexture ? './clue/'+this.video : './clue/720/'+this.video" type="video/mp4">
      <track default :src="this.subtitle" kind="subtitles" srclang="hu" label="Magyar">
      Your browser does not support the video tag.
    </video>
  </div>
</div>
</template>

<script>
export default {
    name: "VideoModal",
    props: ["video", "name", "subtitle"],
     methods: {
      closeModal: function() {
        this.$emit("closeModalEvent", this.name);
      }
    },
    data: function() {
      return {
        show: false
      }
    },
    computed: {
      isLowTexture: function() {
        return this.$store.getters.getIsLowTexture
      }
    },
    mounted: function() {
      var self = this;
      // document.getElementById('video').textTracks[0].mode = "hidden"
      setTimeout(function() {
        self.show = true;
      },1500)
      setTimeout(() => {
        document.getElementById('google').addEventListener('click', function(){
           self.$emit("closeModalEvent", self.name);
        })
      }, 1500)
    },
    beforeDestroy: function() {
      this.show = false;
    }
}
</script>

<style scoped>
.modal-video {
  width: 100%;
  max-height: 100%;
}
#closemodal {
  position: absolute;
  right: 33px;
  top: 31px;
  cursor:pointer;
  z-index: 10;
}
.modaloverlay {
  display: none;
  position: fixed;
  top:0;
  left:0;
  z-index: 10;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .7s
 }

.fade-enter,
.fade-leave-active {
    opacity: 0
}
.mymodal {
  /* justify-content: center; */
  max-width: 1000px;
  min-width: 820px;
    min-height: 500px;
    /* display: inline-block; */
   /* max-width:1000px;  */
  /* background:linear-gradient(180deg, rgba(0, 17, 53, 0) 60.37%, #001135 106.81%), url('../../public/tavolsagmero.jpg'); */
  border-radius: 5px;
  
  font-size: 16px;
  position: relative;
}

 @media only screen and (max-width: 768px) {
  .mymodal {
    min-width: 250px;
    min-height: 20%;
    width: 80%;
    /* background-size: cover; */
  }

  #closemodal {
  right: 10px;
  top: 10px;
  height: 30px;
  width: 30px;
}
 }

</style>