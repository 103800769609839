<template>
  <!-- <div @click="toggleFullScreen" id="fullscreen-container" class="tooltip">
    <img id="fullscreen-button" src="fullscreen-button.svg" alt="fullscreen-button">
    </div> -->

  <div v-if="!this.isIos" @click="toggleFullScreen" class="mav-main-fullscreen">
            <div class="mav-main-fullscreen-icon">
                <img src="/tutorial/utkozespont-fullscreen.svg" alt="Fullscreen icon">
            </div>
        </div>
</template>

<script>
export default {
  name: "FullScreenButton",
  data: function () {
    return {
      isToggled: !(
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ),
    };
  },
  computed: {
    isIos() {
      return this.$store.getters.getIsIos;
    }
  },
  methods: {
    toggleFullScreen: function() {
      this.$helpers.toggleFullscreen();
    }
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}
.tooltip .tooltiptext-left {
  display:inline;
  visibility: hidden;
  width: 125px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  color: #FFF;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  right: 120%;
  font-size: 0.875em;

font-style: normal;
font-weight: normal;
line-height: 18px;
  background: #001135;
border: 1px solid rgba(255, 255, 255, 0.3);
}
.btn {
  position: relative;
  z-index: 1;
}
.active {
  background-color: green;
}


.mav-main-fullscreen {
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 2;
    user-drag: none; 
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
}

.mav-main-fullscreen-icon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: rgba(0, 40, 122, 0.04);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-fullscreen-icon:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: transparent;
}

.mav-main-fullscreen-icon img {
      width: 13px;
      height: 13px;
  }
 
@media only screen and (min-width: 375px) {
  .mav-main-fullscreen {
      top: 30px;
      right: 30px;
  }

  .mav-main-fullscreen-icon {
      width: 32px;
      height: 32px;
  }

  .mav-main-fullscreen-icon img {
      width: 13px;
      height: 13px;
  }
}

@media only screen and (min-width: 768px) {
      .mav-main-fullscreen {
        top: 45px;
        right: 45px;
    }

    .mav-main-fullscreen-icon {
        width: 40px;
        height: 40px;
    }

    .mav-main-fullscreen-icon img {
        width: 15px;
        height: 15px;
    }
}

@media only screen and (min-width: 1024px) {
  .mav-main-fullscreen {
        top: 50px;
        right: 50px;
    }
    .mav-main-fullscreen-icon {
        width: 50px;
        height: 50px;
    }

    .mav-main-fullscreen-icon img {
        width: 20px;
        height: 20px;
    }

}

</style>
