<template>
  <div class="modaloverlay">
    <!-- <div class="mymodal">
        <h1 v-if="show" id="counter-number" style="color: white">{{ countDown }}</h1>

        <button v-if="show" id="counter-btn" @click="clickToCount()">
            asd
        </button>

        <h1 v-if="show" id="counter-number" style="color: white">{{ counter }}</h1>

      <h1 v-if="!this.show" style="color: white;">Gratulálunk a segítségeddel a tűzoltok megkezdhetik a sérült kimenekítését</h1>
      <div v-if="!this.show" @click.stop="closeModal" class="btn next" id="btn">
          <div class="btn-inner">
            <span class="btn-text" style="color: white">Tovább</span>
            <img class="rotate" src="Vector.svg" alt="">
          </div>
        </div>  

    </div> -->
    <div v-if="this.page === 0" class="counter-button-modal">
      <h3 class="instruction-text">      Segítsd gyorsaságoddal tűzoltók munkáját, hogy minél hamarabb kiszabadíthassák a sérültet! Nyomd meg a sárga gombot minél többször, mielőtt lejár az idő!
</h3>
    </div>
    <custom-button v-if="this.page=== 0" @clickEv="nextTask" :text="'Tovább'"></custom-button>


   <!-- BUTTON -->
     <div v-if="this.page===1" class="counter-container">
      <div class="timer-image">
        <img src="timer.svg" alt="clock">
      </div>
      <div class="counter-countdown">
        {{this.countDown}}
      </div>
    </div>

    <div v-if="this.page === 1" class="intro-counter-button-outer" @click="decrementCounter" style="touch-action: manipulation;">
      <div class="intro-counter-button-inner">
        {{this.counter}}
      </div>
    </div> 

    <div v-if="this.page === 2" class="counter-game-result">
      <div class="result-circle">
        <div class="firework-image">
          <img src="success.svg" alt="success">
        </div>
      </div>
      <h3 class="instruction-text">Gratulálunk, gyorsaságodnak köszönhetően a tűzoltók megkezdhetik a sérült kiszabadítását az autóból!</h3>
    </div>
    <custom-button @clickEv="closeModal" v-if="this.page === 2" :text="'Tovább'"></custom-button>

        <div v-if="this.page === 3" class="counter-game-result">
      <div class="result-circle">
        <div class="skull-image">
          <img src="failure.svg" alt="success">
        </div>
      </div>
      <h3 class="instruction-text">Sajnos ezúttal nem sikerült teljesítened a kihívást. Kattints a vissza gombra és próbáld meg újra!</h3>
    </div>
  <PrevButton :text="'Vissza'" @previousClicked="goBack" v-if="this.page === 3"></PrevButton>
</div>
</template>

<script>
import CustomButton from './CustomButton.vue';
import PrevButton from "./PrevButton";
export default {
    name: "CounterButton",
    props: ["name"],
    components: {
      CustomButton,
      PrevButton,
    },
    data: function() {
        return {
            show: true,
            counter: 5,
            countDown: 10,
            page:0,
            timeout: null,
            failed: 0,
        }
    },
    mounted: function() {
      this.$store.state.btnShow = true;
    },
    methods: {
      goBack() {
          this.countDown = 10;
          this.page = 1;
          this.countDownTimer();
      },
      nextTask: function() {
        this.page++;
      },
      decrementCounter: function() {
        this.counter--;
        if(this.counter == 0) {
          //Or emit event
                this.show = false
                this.page = 2;
                this.countDown = 1
                // document.getElementById('counter-btn').disabled = true
            } 
      },
        closeModal: function() {
            this.$emit("closeModalEvent", this.name);
            this.$store.state.btnShow = false
        },
        clickToCount: function() {
            this.counter++

            if(this.counter == 5) {
                this.show = false
                this.countDown = 1
                // document.getElementById('counter-btn').disabled = true
            } 

        },
        countDownTimer() {
          var self = this;
            if(this.countDown > 0) {
               this.timeout = setTimeout(() => {
                 self.countDown -= 1
                    self.countDownTimer()
                    if(this.countDown === 0){
                      // this.page = 3;
                      clearTimeout(this.timeout);
                    }
                    
                }, 1000)
            }
        }
    },
    watch: {
      page: function(newPage,oldPage) {
        if(newPage === 1 && oldPage === 0){
          this.countDownTimer()
        }
      },
      countDown: function(newCd, oldCd) {
      if(newCd === 0 && oldCd === 1){
        if(this.failed === 0) {
          if(this.counter != 0){
            this.page = 3;
          this.failed++;
          }
        }else {
          if(this.counter !=0){
            this.$emit("closeModalEvent", this.name);
          }else {
            this.page = 2;
          }
        }
      }
    },
    },
    
    beforeDestroy: function() {
      this.show = true;
    }
}
</script>

<style scoped>

#closemodal {
  position: absolute;
  right: 33px;
  top: 31px;
  cursor:pointer;
  z-index: 10;
}
.modaloverlay {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content:center;
  
  align-items: center;

  /* background: #001135; */
  background: linear-gradient(180deg, rgba(0, 17, 53, 0) 60.37%, #001135 106.81%);
}
.modaloverlay::after {
  content: "";
position:absolute;
left:0;
top:0;
width:100%;

height: 100%;
backdrop-filter: blur(12px);
background: linear-gradient(180deg, rgba(0, 17, 53, 0) 73.99%, #001135 99.89%);
transform: rotate(180deg);
z-index: -1;



}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .7s
 }

.fade-enter,
.fade-leave-active {
    opacity: 0
}
.mymodal {
  /* justify-content: center; */
  max-width: 1000px;
  min-width: 820px;
    min-height: 500px;
    /* display: inline-block; */
   /* max-width:1000px;  */
  /* background:linear-gradient(180deg, rgba(0, 17, 53, 0) 60.37%, #001135 106.81%), url('../../public/tavolsagmero.jpg'); */
  border-radius: 5px;
  
  font-size: 16px;
  position: relative;
}

.counter-game-result {
  max-width: 1013px;
  width: 100%;
height: 226px;
position:relative;
/* Note: backdrop-filter has minimal browser support */

border-radius: 5px;
z-index: 1;
display:flex;
justify-content: center;
align-items: center;
}
.counter-game-result::after {
  content: "";
  top:0;
  left:0;
  position:absolute;
  height: 100%;
  width: 100%;
background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
backdrop-filter: blur(10px);
z-index: -1;
/* Note: backdrop-filter has minimal browser support */

border-radius: 5px;
}
.skull-image {
  max-width:30px;
  height: auto;
}
.firework-image {
  height: auto;
  max-width:30px;
}
.counter-game-result .result-circle {
  position:absolute;
  left: 50%;
  top:0;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 100px;
  display:flex;
  justify-content: center;
  align-items: center;
/* Note: backdrop-filter has minimal browser support */

border-radius: 100px;
}
.counter-game-result .result-circle::after {
  content: "";
  position:absolute;
  left: 0;
  top:0;
  height: 100px;
  width: 100px;
  background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(10px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 100px;
z-index:-1;
}
.counter-countdown {

font-style: normal;
font-weight: normal;
font-size: 30px;
line-height: 24px;

/* identical to box height, or 80% */
/* mav-white */

color: #FFFFFF;
}
.counter-button-modal {
  
background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(10px);
/* Note: backdrop-filter has minimal browser support */
border-radius: 5px;
display:flex;
justify-content:center;
align-items:center;
 max-width: 1013px;
  width: 100%;
height: 248px;

}
.instruction-text {

font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
max-width: 688px;

/* or 150% */

text-align: center;

/* mav-white */

color: #FFFFFF;
}
.counter-container {
  width: 115px;
height: 59px;
background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content:center;
  margin-bottom: 56px;


}
.timer-image {
  max-width: 22px;
  height: auto;
  margin-top:5px;
  margin-right: 10px;
}
.intro-counter-button-inner {
  width: 273.91px;
  height: 273.91px;
  border-radius: 200px;
  background: #FFCC33;

font-style: normal;
font-weight: bold;
font-size: 144px;
line-height: 144px;
/* identical to box height, or 100% */

display: flex;
align-items: center;
justify-content: center;
text-align: center;

/* mav-white */

color: #FFFFFF;
}
.intro-counter-button-outer {
  padding: 38.04px;
  cursor: pointer;
user-select: none;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  background: rgba(255, 204, 51, 0.2);
border: 3px solid rgba(255, 204, 51, 0.3);
border-radius: 200px;

}
.btn.next {
    position: absolute;
    right: 6.6%;
    bottom: 9.59%;
  }
  .rotate {
    margin-left: 20.41px;
    margin-right: -25.25px;
    height: 15px;
    width: 12.35px;
    height: auto;
  }
 .btn {
   cursor: pointer;
  position: absolute;
  display: flex;
  z-index: 1;
  padding: 8px;
  background: rgba(0, 40, 122, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

}

.btn-text {

font-style: normal;
font-weight: 600;
font-size: 13px; 
line-height: 16px;
align-items: center;
text-align: center;

/* mav-blue */

color: #FFF;
}

.btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
  box-shadow: 0px 4px 20px rgba(32, 32, 32, 0.5);
  border-radius: 100px;
  /* transform: rotate(-180deg); */
}

@media only screen and (max-width: 1440px) { 
  
  .btn-inner {
    width: 140px;
    height: 40px;
  }
  
  .btn.next {
    right: 54px;
    bottom: 60px;
  }
}

@media only screen and (max-width: 1250px) { 
  
  .counter-game-result {
    width: 80%;
  }
  .counter-button-modal {
  width: 80%;
}
}

@media only screen and (max-width:1024px) {
  
  .btn-inner {
    width: 120px;
    height: 40px;
  }
  .rotate {
    margin-left: 20px;
    margin-right: -10px;
    max-width: 12.35px;
    height: auto;
  }
  /* .btn-text {
    font-size: 14px;
  } */
  .btn-text {
    display: block;
  }
  .btn.next {
    right: 41px;
    bottom: 52px;
  }
}


@media only screen and (max-width: 768px) { 
 /*  .btn {
    width: 100px;
    height: 50px;
  } */
  .btn {
    padding: 6px;
  }
  .btn-inner {
    width: 85px;
    height: 33.48px;
  }

  .btn-text {
    display: none;
  }
  .rotate {
    /* margin-left: 0;
    margin-right: 0; */
    height: 13px;
  }
  .btn.next {
    right: 48px;
    bottom: 48px;
  }
  .intro-counter-button-inner {
      width: 200px;
    height: 200px;
    font-size: 110px;
line-height: 144px;
}
.intro-counter-button-outer {
      padding: 28px;
}
.modaloverlay {
  justify-content: center;
}
.counter-container {
  margin-bottom: 30px;
}
.instruction-text {
    font-size: 16px;
    width: 80%;
    line-height: 30px;
  }
  .counter-game-result {
    height: 190px;
  }
  .counter-game-result .result-circle {
    height: 80px;
    width:80px;
  }

  .counter-game-result .result-circle::after {
    height: 80px;
    width:80px;
  }
  .firework-image {
    max-width: 24px;
  }
  .skull-image {
  max-width:20px;
}
}



@media only screen and (max-width: 425px) and (orientation: portrait) {
  .btn-inner {
    width: 25px;
    height: 25px;
  }
  .btn {
    padding: 4px;
  }
  .btn-text {
    display: none;
  }
  .rotate {
    margin-left: 0;
    margin-right: 0;
    height: 12px;
  }
  .btn.next {
    right: 28px;
    bottom: 51px;
  }
  .intro-counter-button-inner {
      width: 100px;
    height: 100px;
    font-size: 50px;
line-height: 144px;
}
.intro-counter-button-outer {
      padding: 12px;
}
.counter-container {
  width: 90px;
    height: 45px;
}
.timer-image {
  max-width: 16px;
}
.counter-countdown {
  font-size: 24px;
}
.instruction-text {
  font-size:12px;
  width: 80%;
  line-height: 25px;
}
.counter-game-result {
  height: 180px;
}

  .counter-game-result .result-circle {
    height: 60px;
    width:60px;
  }

  .counter-game-result .result-circle::after {
    height: 60px;
    width:60px;
  }
  .firework-image {
    max-width: 20px;
  }
  .skull-image {
  max-width:18px;
}
}

@media only screen and (max-height: 425px) and (orientation: landscape) {
  .btn-inner {
    width: 55px;
    height: 31.5px;
  }
  .btn {
    padding: 6px;
  }
  .btn-text {
    display: none;
  }
  .rotate {
    margin-left: 0;
    margin-right: 0;
    height: 12px;
  }
  .btn.next {
    right: 28px;
    bottom: 43px;
  }
  .instruction-text {
  font-size:12px;
  width: 80%;
}
  .intro-counter-button-inner {
      width: 100px;
    height: 100px;
    font-size: 50px;
line-height: 144px;
}
.intro-counter-button-outer {
      padding: 15px;
}
.counter-container {
  width: 90px;
    height: 45px;
}
.timer-image {
  max-width: 16px;
}
.counter-countdown {
  font-size: 24px;
}
.instruction-text {
  font-size:16px;
}
.counter-game-result {
  height: 180px;
}

  .counter-game-result .result-circle {
    height: 80px;
    width:80px;
  }

  .counter-game-result .result-circle::after {
    height: 80px;
    width:80px;
  }
  .firework-image {
    max-width: 20px;
  }
  .skull-image {
  max-width:18px;
}
  .counter-button-modal {
  width: 60%;
}

}





</style>