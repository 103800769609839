<template>
  <div id="splash">
    <div>
      <img id="load_logo" src="load_logo.svg" alt="">
    </div>

    <div class="progress" id="progress">
      <div class="fill" id="fill"></div>
    </div>

    <div>
      <!-- <h2 style="color: white">{{ loadingPercent }}%</h2> -->
    </div>

    <img src="/tutorial/utkozespont-frame-bottom-right.svg" alt="" class="bottom-right camera-effect">
    <img src="/tutorial/utkozespont-frame-bottom-left.svg" alt="" class="bottom-left camera-effect">
    <img src="/tutorial/utkozespont-frame-top-right.svg" alt="" class="top-right camera-effect">
    <img src="/tutorial/utkozespont-frame-top-left.svg" alt="" class="top-left camera-effect">

  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ["scene", "minLoading"],
  data: function () {
    return {
      loadingPercent: 0,
    }
  },
  mounted: function () {
    // var splash = document.querySelector("#splash");

    /* setTimeout(function () {
      splash.style.display = "none";
    }, 3000) */
    
    /* var self = this;
    let interval = setInterval(function() {
      console.log("FUT")
      let video = document.querySelector("#vid")
     switch (video.readyState) {
        case 0:
          document.getElementById('fill').style.width = '0%'
          console.log("VÁLTOZIK 0");
          self.loadingPercent = 0;
          break;
        case 1:
          document.getElementById('fill').style.width = '25%'
          console.log("VÁLTOZIK 1");
          self.loadingPercent = 25;
          break;
        case 2:
          document.getElementById('fill').style.width = '50%'
          console.log("VÁLTOZIK 2");
          self.loadingPercent = 50;
          break;
        case 3:
          document.getElementById('fill').style.width = '75%'
          console.log("VÁLTOZIK 3");
          self.loadingPercent = 75;
          break;
        case 4:
          document.getElementById('fill').style.width = '100%'
          self.loadingPercent = 100;
          console.log("CLEARINTERVAL")
          clearInterval(interval);
          setTimeout(function() {
            document.querySelector("#splash").style.display = "none";
          }, 500)
          break;
      } */

      /* switch (document.readyState) {
        case 'loading':
          document.getElementById('fill').style.width = '50%'
          console.log("VÁLTOZIK 0");
          self.loadingPercent = 0;
          break;
        case "interactive":
          document.getElementById('fill').style.width = '50%'
          self.loadingPercent = 50;
          console.log("interactive")
          break;
        case 'complete':
          document.getElementById('fill').style.width = '100%'
          self.loadingPercent = 100;
          console.log("CLEARINTERVAL")
          clearInterval(interval);
          setTimeout(function() {
            document.querySelector("#splash").style.display = "none";
          }, 500)
          break;
        } */
      // },50)
    /* var time = Date.now();
    var goal = this.$props.minLoading;
    var splash = document.querySelector("#splash");
    console.log(document.readyState);

    if (document.readyState != "complete") {
      document.onreadystatechange = () => {
        console.log("Not loaded yet");
        this.$props.scene.addEventListener("loaded", function () {
          console.log("Show on loaded");

          if (time + goal > Date.now()) {
            var x = Date.now() - time + goal;
            setTimeout(function () {
              splash.style.display = "none";
            }, x);
          } else {
            splash.style.display = "none";
          }
        });
      };
    } else {
      if (time + goal > Date.now()) {
        var x = Date.now() - time + goal;
        setTimeout(function () {
          splash.style.display = "none";
        }, x);
      } else {
        splash.style.display = "none";
      }
    } */
  }
};
</script>

<style>
#splash {
  background: #001135;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;
}

#load_logo {
  height: 50px;
  width: 250px;
}

.progress {
  background-color:rgba(255, 255, 255, 0.1);
  height: 6px;
  width: 250px;
  overflow: hidden;
  margin: 20px 0;
}

.fill {
    height: 6px;
    width: 100%;
    background: repeating-linear-gradient(50deg, #FFCC33, #FFCC33 15%, transparent 15%, transparent 25%);
    animation: move 7s infinite linear;
    /* background-size: 400% 100%; */
}

@keyframes move {
0% {
    background-position: 0 0;
}

50% {
    background-position: 60px 0;
}

100% {
    background-position: 0 0;
}
}
</style>