import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from '../views/Intro.vue'
import Home from "../views/Home.vue";
import Still_1 from '../views/Still_1.vue';
import Still_2 from '../views/Still_2.vue';
import Still_3 from '../views/Still_3.vue';
import Quiz from "../views/Quiz.vue";
import Result from "../views/Result.vue";
import Guide from "../views/Guide.vue";
import Congratulations from "../views/Congratulations.vue";
import Outro from '../views/Outro.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/outro',
    name: 'Outro',
    component: Outro,
    beforeEnter: (to, from, next) => {
      if(from.name !== 'Results') next({name: 'Home'})
      else next();
    } 
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/still_1',
    name: 'Still_1',
    component: Still_1,
    beforeEnter: (to, from, next) => {
      if(from.name !== 'Intro' && from.name !== 'Still_2') next({name: 'Home'})
      else next();
    } 
  },
  {
    path: '/still_2',
    name: 'Still_2',
    component: Still_2,
    beforeEnter: (to, from, next) => {
      if(from.name !== 'Still_1' && from.name !== 'Still_3') next({name: 'Home'})
      else next();
    } 
  },
  {
    path: '/still_3',
    name: 'Still_3',
    component: Still_3,
     beforeEnter: (to, from, next) => {
      if(from.name !== 'Still_2') next({name: 'Home'})
      else next();
    } 
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz,
     beforeEnter: (to, from, next) => {
      if(from.name !== 'Still_3') next({name: 'Home'})
      else next();
    } 
  },
  {
    path: '/results',
    name: 'Results',
    component: Result,
   /*   beforeEnter: (to, from, next) => {
      if(from.name !== 'Quiz') next({name: 'Home'})
      else next();
    }  */
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
  },
  {
    path: '/congratulations',
    name: 'Congratulatons',
    component: Congratulations,
     beforeEnter: (to, from, next) => {
      if(from.name !== 'Outro') next({name: 'Home'})
      else next();
    } 
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
