<template>
  <div class="mav-quiz-container">
    <!-- <div class="mav-quiz-blur"></div> -->
    <div class="mav-tutorial-title">
            <div class="mav-tutorial-title-logo">
                <a href="#">
                    <img src="utkozespont-logo.svg" alt="Ütközéspont logo">
                </a>
            </div>
            <div class="mav-tutorial-title-divider"></div>
            <div class="mav-tutorial-title-text">
                Baleset feltárása
            </div>
        </div>
        <full-screen-button></full-screen-button>
      <div class="newspaper">

        <div class="newspaper-heading">
          <div class="newspaper-utkozespont-logo">
          <img src="newspaper-utkozespont-logo.svg" alt="">
          </div>
          <div class="newspaper-magyarhirlap-logo">
          <img id="hirlap" src="hirek.png" alt="magyar hirlap">
          </div>
          <div class="newspaper-mav-logo">
          <img src="newspaper-mav-logo.png" alt="">
          </div>
        </div>

        <div class="newspaper-separator"></div>
        <div class="newspaper-content-container">

        <div class="newspaper-article-title">
        <img id="article-title" src="newspaper-article-title.png" alt="">
        </div>

        <div class="newspaper-content">
          <div class="newspaper-image-container">
          <img src="newspaper-content.png" alt="">
          </div>
          <div class="newspaper-description-container">
            <p id="quiz-form">Közvetlenül <span  :class="{filled: checkIfFilled(1)}">(1)</span> .............. végállomása előtt <span :class="{filled: checkIfFilled(2)}">(2)</span> 
              .............. elsodort és <span :class="{filled: checkIfFilled(3)}">(3)</span> .............. a sínen egy autót a vonat. A balesetben <span :class="{filled: checkIfFilled(4)}">(4)</span> ..............  sérült meg. <span :class="{filled: checkIfFilled(5)}">(5)</span>  .............. áthajtó <span :class="{filled: checkIfFilled(6)}">(6)</span>  .............. éves sofőr
              <span :class="{filled: checkIfFilled(7)}">(7)</span> .............. <span :class="{filled: checkIfFilled(8)}">(8)</span> .............. feltételezhetően <span :class="{filled: checkIfFilled(9)}">(9)</span> ..............</p>
          </div>
        </div>

        </div>
      </div>




      <div class="separator"></div>
      



      <div class="quiz-container">
        <div class="main-text-container">
          <div class="circle-container">
            <img id="writing-icon" src="writing-icon.svg" alt="writing-icon" />
          </div>
          <p id="main-text">
            Most, hogy véget ért a helyszínelés, reméljük már tisztábban látjuk
            az eseményeket. A megfelelő mondatrészek kitöltésével rekonstruáld
            az esetet!
          </p>
        </div>
        <div class="select-wrapper">
          <CustomSelect
            v-for="question in questions"
            :key="question.id"
            :id="question.id"
            :options="question.answers"
            default="Válasz"
            class="select"
            @input="setChosenAnswer"
          />
        </div>
      </div>
    
    <div class="mav-main-frame">
            <div class="mav-main-frame-item mav-main-frame-top-left">
                <img src="/utkozespont-frame-top-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-top-right">
                <img src="/utkozespont-frame-top-right.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-left">
                <img src="/utkozespont-frame-bottom-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-right">
                <img src="/utkozespont-frame-bottom-right.svg" alt="Frame item">
            </div>
        </div>
          <footer class="mav-main-footer mav-buttons-footer mav-buttons-footer-right">
            <div @click="validateAnswers" class="mav-button-outer">
                <a class="mav-button mav-button-icon mav-button-icon-right mav-button-secondary">
                    <span>Beküldés</span>
                            <img src="/tutorial/utkozespont-yellow-arrow-right.svg" alt="Arrow icon">
                </a>
            </div>
            </footer>
        <!-- <CustomButton @clickEv="validateAnswers" :text="'Beküldés'" /> -->
  </div>
</template>

<script>
// import NextButton from "../components/NextButton.vue";
// import CustomButton from "../components/CustomButton.vue";
import CustomSelect from "../components/CustomSelect.vue";
import FullScreenButton from "../components/FullScreenButton.vue"
export default {
  name: "Quiz",
  components: {
    CustomSelect,
    FullScreenButton,
    // NextButton,
    // CustomButton,
  },
  computed: {
        isIos() {
            return this.$store.getters.getIsIos;
        }
    },
  data: function () {
    return {
      prevScrollpos: window.pageYOffset,
      topPos: 30,
      questions: [
        {
          id: 1,
          answers: [
            { id: 1, answer: "debreceni" },
            { id: 2, answer: "budapesti" },
            { id: 3, answer: "esztergomi" },
            { id: 4, answer: "bicskei" },
          ],
        },
        {
          id: 2,
          answers: [
            { id: 1, answer: "dec 7." },
            { id: 2, answer: "aug 4." },
            { id: 3, answer: "május 4." },
            { id: 4, answer: "aug 7." },
          ],
        },
        {
          id: 3,
          answers: [
            { id: 1, answer: "azonnal félrelökött maga elől" },
            { id: 2, answer: "173 métert tolt maga előtt" },
            { id: 3, answer: "kettészakított" },
          ],
        },
        {
          id: 4,
          answers: [
            { id: 1, answer: "egy kerékpáros és a sofőr" },
            { id: 2, answer: "a sofőrön kívül más nem" },
            { id: 3, answer: "a sofőr és a mozdonyvezető" },
          ],
        },
        {
          id: 5,
          answers: [
            { id: 1, answer: "A meghibásodott jelzőlámpa miatt óvatlanul" },
            {
              id: 2,
              answer:
                "A piros jelzés ellenére szabálytalanul",
            },
            { id: 3, answer: "150 km/h sebességel haladva" },
          ],
        },
        {
          id: 6,
          answers: [
            { id: 1, answer: "27" },
            { id: 2, answer: "33" },
            { id: 3, answer: "43" },
          ],
        },
        {
          id: 7,
          answers: [
            { id: 1, answer: "könnyebb sérülésekkel megúszta a balesetet" },
            { id: 2, answer: "súlyosan megsérült" },
            {
              id: 3,
              answer: "később a kórházba szállítást követően életét vesztette",
            },
          ],
        },
        {
          id: 8,
          answers: [
            { id: 1, answer: "Alkoholos befolyásoltság alatt állt" },
            { id: 2, answer: "Nem fogyasztott alkoholt" },
            { id: 3, answer: "Gyógyszeres kezelés alatt ált" },
          ],
        },
        {
          id: 9,
          answers: [
            {
              id: 1,
              answer:
                "sietett és azt hitte, hogy még éppen átér a vonat előtt. Türelmetlenségéért majdnem az életével fizetett.",
            },
            {
              id: 2,
              answer:
                "megzavarta egy síneken áthajtó biciklis, aki miatt nem nézett szét. Figyelmetlenségéért majdnem az életével fizetett.",
            },
          ],
        },
        
      ],
      chosenAnswers: [
        { id: 1, chosen: "", chosenId: null, isCorrect: false },
        { id: 2, chosen: "", chosenId: null, isCorrect: false },
        { id: 3, chosen: "", chosenId: null, isCorrect: false },
        { id: 4, chosen: "", chosenId: null, isCorrect: false },
        { id: 5, chosen: "", chosenId: null, isCorrect: false },
        { id: 6, chosen: "", chosenId: null, isCorrect: false },
        { id: 7, chosen: "", chosenId: null, isCorrect: false },
        { id: 8, chosen: "", chosenId: null, isCorrect: false },
        { id: 9, chosen: "", chosenId: null, isCorrect: false },
      ],
      correctAnswers: [
          {id: 1, correctId: 3},
          {id: 2, correctId: 3},
          {id: 3, correctId: 2},
          {id: 4, correctId: 2},
          {id: 5, correctId: 2},
          {id: 6, correctId: 2},
          {id: 7, correctId: 1},
          {id: 8, correctId: 2},
          {id: 9, correctId: 1},
      ]
    };
  },
  mounted: function() {
    var self = this;
   let rect =  document.querySelector(".mav-tutorial-title").getBoundingClientRect();
   self.topPos = rect.top;
    document.documentElement.classList.remove("a-fullscreen");
    window.addEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll: function() {
   //Scroll Effekt
   var self = this;
  var currentScrollPos = window.pageYOffset;
  if (currentScrollPos < this.prevScrollpos) {
    if(currentScrollPos < 100) {
      let title = document.querySelector(".mav-tutorial-title")
      title.style.top = self.topPos+'px';
      let fs = document.querySelector(".mav-main-fullscreen");
      if(fs) fs.style.top = self.topPos+'px';
    }
  } else {
    if(currentScrollPos > 0){
      let title = document.querySelector(".mav-tutorial-title");
      title.style.top = "-70px";
      let fs = document.querySelector(".mav-main-fullscreen")
      if(fs) fs.style.top = "-70px";
    }
  }
  this.prevScrollpos = currentScrollPos;
    },
    setChosenAnswer: function (ev) {
      this.chosenAnswers.forEach((ans) => {
        if (ans.id === ev.questionId) {
          ans.chosen = ev.chosen;
          ans.chosenId = ev.chosenId;
        }
      });
    },
    validateAnswers: function() {
        var self = this;
        let counter = 0;
        this.chosenAnswers.forEach((chosen,i) => {
            // correctAnswer = this.correctAnswers.find(correct => correct.correctId == chosen.chosenId);
            if(chosen.chosenId === self.correctAnswers[i].correctId){
                chosen.isCorrect = true;
                counter++;
            }
        });
        this.$store.commit("setQuizAnswers", this.chosenAnswers);
        this.$store.commit("setQuizPoints",counter);

        this.$router.push("results")
    },
    checkIfFilled: function(questionId) {
     let chosen = this.chosenAnswers.find(ans => ans.id === questionId);
     if(chosen.chosen !== ""){
       return true;
     }else {
       return false;
     }
    }
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll",this.handleScroll);
  }
};

</script>

<style>

.filled {
  color: #FFCC33;
}
.not-filled {
  font-style: normal;
font-weight: 600;
}
#main-text {
  padding: 30px 22px 30px 58px;
  width: 642px;
  font-size: 16px;
  line-height: 20px;

  font-style: normal;
font-weight: 600;
color: #FFF;
}
.mav-quiz-container {
  display: flex;
  /* padding-right: 69px; */
  /* padding-left: 69px; */
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  /* background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%); */
  background: #001135;
  background-image: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
  /* background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%); */
  /* backdrop-filter: blur(42px); */
  /* background: white; */
  min-height: 100vh;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  overflow-y: auto;
  overscroll-behavior: unset;
  /* color: #ffffff; */
}
.select-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 630px;
  margin:auto;
}
.main-text-container {
    margin-bottom: 33px;
  border: 1px solid rgba(255, 255, 255, 0.3);
 
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  position: relative;
}
.circle-container {
  height: 65px;
  width: 65px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  background-color: #001135;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
#writing-icon {
  margin-right: 3px;
  width: 26px;
  height: auto;
}
.newspaper {
  /* max-width: 522.97px; */
  max-width: 522.97px;
  height: auto;
  /* z-index:1000; */
  /* background-image: url(../../public/newspaper-bg.png); */
  /* mix-blend-mode: multiply; */
  background-color: #FFF8F0;
/* opacity: 0.3; */
}
.newspaper-article-title {
  max-width: 445px;
  max-height:22.4px;
  margin-bottom: 24px;
  min-width: 200px;
}
.newspaper-utkozespont-logo {
  max-width: 60px;
  max-height: 17px;
}
.newspaper-image-container {
  max-width: 225px;
  max-height:180px;
  min-width: 120px;
  min-height: 80px;
  margin-right: 18px;
}
.newspaper-mav-logo {
  max-width: 64px;
  max-height: 17px;
  mix-blend-mode: luminosity;
}
.newspaper-magyarhirlap-logo {
  max-width: 240px;
  max-height:36.49px;
}
.separator {
  width: 0px;
  height: 359px;
  opacity: 0.2;
  /* mav-white */

  border: 1px solid #ffffff;
}
.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 69px;
  padding-left: 69px;
}
.quiz-container {
    align-items: center;
    max-width: 642px;
}
.newspaper-heading {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 25px;
}
.newspaper-separator {
  background: #000000;
  height: 0px;
  width: 100%;
  border: 1px solid #000;
  margin-top: 15px;
  margin-bottom: 24px;
}
.newspaper-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
#quiz-form {
  max-width: 216px;

font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
margin-top: 6px;
/* or 125% */

text-align: justify;

color: #1A1A1A;
}
.newspaper-content-container {
  padding-right: 33px;
  padding-left: 33px;
  padding-bottom: 25px;
}
.newspaper-description-container span {
  font-style: normal;
font-weight: 600;
}






@media only screen and (max-width: 1440px) { 
   #main-text {
     width: 550px;
   }
   .select-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 550px;
  margin:auto;
}
}
/* ÁLLÍTOTT MOBIL */
@media only screen and (max-width: 1250px )  and (max-height: 1024px){ 
  .mav-quiz-container {
  flex-direction: column;
  justify-content: space-evenly;
  overflow: auto;
  /* padding-bottom: 120px; */
}
  .separator {
  display: none;
}
.mav-quiz-container {
     padding-top: 120px;
     padding-bottom: 140px;
   }
   #main-text {
     width: 80%;
     padding: 5px 5px 5px 58px;
     font-size: 14px;
     line-height: 18px;
   }
   .main-text-container {
     width: 80%;
   }
   .select-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin:auto;
}
.circle-container {
  height: 50px;
  width: 50px;
}
.newspaper {
  max-width: 80%;
}
.newspaper-content {
  flex-direction: column;
}
#quiz-form {
  font-size: 12px;
  line-height: 16px;
}
.newspaper-article-title {
  margin-bottom: 5px;
  max-width: 400px;
}
.newspaper-mav-logo {
  max-width: 40px;
  height: auto;
}
.newspaper-magyarhirlap-logo {
  max-width: 200px;
}
.newspaper-utkozespont-logo {
  max-width: 40px;
  height: auto;
}
.newspaper-image-container {
  margin-right: 0px;
  margin-bottom: 10px;
}
.newspaper-content-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
  padding-right: 14px;
  padding-left: 14px;
}
.newspaper-separator {
  margin-top:5px;
  margin-bottom: 8px;
}
.quiz-container {
  margin-top: 20px;
}
.mav-main-footer {
  position: static;
}
/* .newspaper-content-container {
  padding-right: 33px;
  padding-left: 33px;
  
} */
}


@media only screen and (max-width: 1250px )  and (max-height: 1400px) and (min-height: 1024px){ 
  .mav-quiz-container {
  flex-direction: column;
  justify-content:flex-start;
  padding-top: 160px;
  overflow: auto;
  /* padding-bottom: 120px; */
}
  .separator {
  display: none;
}
   #main-text {
     width: 80%;
     padding: 10px 10px 10px 58px;
     font-size: 14px;
     line-height: 18px;
   }
   .main-text-container {
     width: 100%;
   }
   .select-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin:auto;
}
.circle-container {
  height: 50px;
  width: 50px;
}
.newspaper {
  max-width: 80%;
  width: 60%;
  margin-bottom: 60px;
}
.newspaper-content {
  flex-direction: row;
  justify-content: space-evenly;
  
}
#quiz-form {
  font-size: 16px;
    line-height: 20px;
}
.newspaper-article-title {
  margin-top: 10px;
    margin-bottom: 20px;
}
.newspaper-image-container {
  margin-right: 0px;
  margin-bottom: 10px;
}
.newspaper-content-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
  padding-right: 14px;
  padding-left: 14px;
  width: 100%;
}
.newspaper-separator {
  margin-top:5px;
  margin-bottom: 8px;
}

.mav-main-footer {
  position: static;
}
.newspaper-content {
  width: 100%;
    align-self: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 40px;
}
/* .newspaper-content-container {
  padding-right: 33px;
  padding-left: 33px;
  
} */
}








</style>