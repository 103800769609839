<template>
  <div id="google" class="modaloverlay">
    <div id="myDiv" class="mymodal">
      <transition name="fade">
        <img v-if="this.show" @click.stop="closeMdl" id="closemodal" src="closemodal.svg" width="50" alt="">
      </transition>
      <!-- <img class="modal-img" id="img-train" src="menetrend.jpg" alt="necklace"> -->
      <!-- <p class="typer-container">{{this.text}}</p> -->
      <div v-if="this.text != null"  class="typer-container">
        <div class="typer-container-icon">
            <img src="utkozespont-search-icon.svg" alt="Search icon">
        </div>
        <div  class="typer-container-text"><vue-typer  :pre-type-delay="600" :type-delay="40" :repeat="0" :text="this.text"></vue-typer></div>
    </div>
  </div>
</div>
</template>

<script>
import { VueTyper } from 'vue-typer'

export default {
    name: "ImageModal",
    props: ["background", "text","name"],
    components: {
      VueTyper,
    },
    data: function() {
      return {
        show: false,
      }
    },

    mounted: function() {
      var self = this;
      setTimeout(function() {
        self.show = true;
      },1500)
      setTimeout(() => {
        document.getElementById('google').addEventListener('click', function(){
          self.$emit("closeModalEvent", self.name);
        })
      }, 1500)
      var bgImg = new Image();
      // var self = this;
      let myDiv = document.getElementById("myDiv");
      bgImg.src = this.background;
    bgImg.onload = function() {
      myDiv.style.backgroundImage = 'url(' + bgImg.src + ')';
    };
    
    },
    methods: {
      closeMdl: function() {
          this.$emit("closeModalEvent", this.name);
      }
    },
    beforeDestroy: function() {
      this.show = false;
    }
}
</script>

<style scoped>

.typer-container-text {

    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.typer-container {
    width: 90%;
    min-height: 60px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    padding: 20px 40px;
    margin-left: 10px;
}
.typer-container::after {
    content: "";
    background: rgba(0, 40, 122, 0.04);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: -1;
}
.typer-container-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}
.typer-container-icon::after {
    content: "";
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50px;
    z-index: -1;
}
#closemodal {
  position: absolute;
  right: 33px;
  top: 31px;
  cursor:pointer;
  z-index: 10;

}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
 }

.fade-enter,
.fade-leave-active {
    opacity: 0
}

.modaloverlay {
  display: none;
  position: fixed;
  z-index: 10;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(12px);
  background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
}
.mymodal {
  justify-content: center;
  max-width: 1000px;
  min-width: 820px;
    min-height: 500px;
    /* display: inline-block; */
   /* max-width:1000px;  */
  /* background:linear-gradient(180deg, rgba(0, 17, 53, 0) 60.37%, #001135 106.81%), url('../../public/tavolsagmero.jpg'); */
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  font-size: 16px;
  position: relative;
}

 

 

 @media only screen and (max-width: 768px) and (orientation: portrait){
  .mymodal {
    min-width: 250px;
    min-height: 50%;
    width: 100%;
    background-size: cover;
  }
  .vue-typer {
    max-width: 60%;
    font-size: 12px;
    line-height: 15px;
  }
  .typer-container {
    max-width: 60vw;
    font-size: 12px;
    padding: 20px 40px;
    min-height: 70px;

  }
  #closemodal {
  right: 10px;
  top: 10px;
  width: 35px;
  height: auto;

}
 }

 @media only screen and (max-width: 1000px) and (orientation: landscape){
  .mymodal {
    min-width: 250px;
    min-height: 70%;
    width: 60%;
    background-size: cover;
  }
  .vue-typer {
    max-width: 100%;
    font-size: 12px;
    line-height: 15px;
  }
  .typer-container {
    max-width: 90%;
    width: 100%;
    font-size: 12px;
    padding: 5px 40px;
    min-height: 40px;
    display:flex;
    justify-content: center;
    align-content: center;
    bottom: 10px;

  }
  #closemodal {
  right: 10px;
  top: 10px;
  width: 35px;
  height: auto;

}

.typer-container-icon {
  height: 30px;
  width: 30px;
  left: -15px;
}

.typer-container-icon img {
   max-width: 40%;
 }
 .typer-container-icon::after {
   height: 30px;
   width: 30px;
 }
 }

/*   @media only screen and (max-width: 1100px) and (orientation: landscape){
  .mymodal {
    min-width: 250px;
    min-height: 50%;
    width: 65%;
    background-size: cover;
  }
  .vue-typer {
    max-width: 100%;
    font-size: 12px;
    line-height: 15px;
  }
  .typer-container {
    max-width: 90%;
    width: 100%;
    font-size: 12px;
    padding: 5px 40px;
    min-height: 40px;
    display:flex;
    justify-content: center;
    align-content: center;
    bottom: 10px;

  }
  #closemodal {
  right: 10px;
  top: 10px;
  width: 35px;
  height: auto;

}

.typer-container-icon {
  height: 30px;
  width: 30px;
  left: -15px;
}

.typer-container-icon img {
   max-width: 40%;
 }
 .typer-container-icon::after {
   height: 30px;
   width: 30px;
 }
 } */

 @media only screen and (max-width: 768px) and (orientation: landscape){
  .mymodal {
    min-width: 250px;
    min-height: 80%;
    width: 80%;
    background-size: cover;
  }
  .vue-typer {
    max-width: 100%;
    font-size: 12px;
    line-height: 15px;
  }
  .typer-container {
    max-width: 90%;
    width: 100%;
    font-size: 12px;
    padding: 5px 40px;
    min-height: 40px;
    display:flex;
    justify-content: center;
    align-content: center;
    bottom: 10px;

  }
  #closemodal {
  right: 10px;
  top: 10px;
  width: 35px;
  height: auto;

}

.typer-container-icon {
  height: 30px;
  width: 30px;
  left: -15px;
}

.typer-container-icon img {
   max-width: 40%;
 }
 .typer-container-icon::after {
   height: 30px;
   width: 30px;
 }
 }
/* LANDSCAPE BIG */
 
 
  @media only screen and (max-width: 425px) {
    .typer-container {
    /* width: 90%; */
    position: absolute;
    bottom: 10px;
    min-height: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    padding: 10px 10px;
    margin-left: 10px;
    max-width: 90vw;
}
.typer-container-icon {
  height: 35px;
  width: 35px;
  left: -17.5px;
}

.typer-container-icon img {
   max-width: 40%;
 }
 .typer-container-icon::after {
   height: 35px;
   width: 35px;
 }
 .vue-typer {
    max-width: 80%;
    font-size: 12px;
    line-height: 15px;
  }
 }
</style>