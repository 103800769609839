<template>
  <div>
    <NextButton text="Összegzés" to="/outro"></NextButton>
    <PrevButton @previousClicked="goPrev" text="Vissza"></PrevButton>
    <img
      src="/tutorial/utkozespont-frame-bottom-right.svg"
      alt=""
      class="bottom-right camera-effect"
    />
    <img
      src="/tutorial/utkozespont-frame-bottom-left.svg"
      alt=""
      class="bottom-left camera-effect"
    />
    <img
      src="/tutorial/utkozespont-frame-top-right.svg"
      alt=""
      class="top-right camera-effect"
    />
    <img
      src="/tutorial/utkozespont-frame-top-left.svg"
      alt=""
      class="top-left camera-effect"
    />
    <MavLogo></MavLogo>
    <full-screen-button />

    <div class="minimap-container" v-bind:class="{ 'minimap-container-bottom':this.$store.state.btnShow }">
      <div
        v-if="this.isMobile"
        @click="showVolumeFn"
        class="sound-container tooltip"
      >
        <span class="tooltiptext-left">Hangerő</span>

        <img
          v-if="!this.isMuted"
          src="soundon.svg"
          id="sound-size"
          alt="sound-button"
        />
        <img v-else src="sound.svg" id="sound-size" alt="sound-button" />
      </div>

      <div
        v-if="!this.isMobile"
        @click="showVolumeFn"
        class="sound-container tooltip"
      >
        <span class="tooltiptext-left">Hangerő</span>
        <div v-if="this.showVolume" id="failure-size">
          <img src="failure.svg" alt="" />
        </div>
        <img
          v-if="this.globalVolume && !this.showVolume"
          src="soundon.svg"
          id="sound-size"
          alt="sound-button"
        />
        <img
          v-if="!this.globalVolume && !this.showVolume"
          src="sound.svg"
          id="sound-size"
          alt="sound-button"
        />
      </div>
      <volume-setter
        id="audio2"
        :audioVolume="this.getAudioVolume()"
        @volumeUnmuted="unMute"
        @volumeMuted="mute"
        v-if="showVolume"
      ></volume-setter>
      <minimap :markers="this.minimapMarkers" />

      <div @click="playVideo" class="play-container tooltip">
        <span class="tooltiptext">Lejátszás</span>
        <img src="play.svg" id="play-size" alt="play-button" />
      </div>
    </div>

    <div v-if="!this.$store.state.btnShow" class="around-container tooltip">
      <span class="tooltiptext">Forgatás</span>
      <img src="around.svg" class="around-img" alt="play-button" />
    </div>

    <!-- <loading :scene="scene" :minLoading="3000" /> -->
    <loading v-if="showLoading" :scene="scene" />
    <a-scene cursor="rayOrigin: mouse">
      <a-assets id="assets3" timeout="1">
        <!-- Using the asset management system. 
          <video id="vid" muted playsinline crossorigin='anonymous' loop="true" src="360.mp4"/>
          https://github.com/aframevr/aframe/issues/1846
          <video id="vid" preload="auto" src="https://cdn.aframe.io/videos/bunny.mp4" autoplay loop crossorigin="anonymous" muted playsinline webkit-playsinline></video>

          -->
        <video
          v-if="!this.isLowTexture"
          id="vid2"
          preload="auto"
          src="videos/Mav_2_4096_rf24.mp4"
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
          loop
          muted
        >
          <audio id="audio2" src="mavmix2.mp3" loop="true"></audio>
        </video>

        <video
          v-else
          id="vid2"
          preload="auto"
          src="videos/mav_1920_2.mp4"
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
          loop
          muted
        >
          <audio id="audio2" src="mavmix2.mp3" loop="true"></audio>
        </video>
      </a-assets>
      <a-videosphere src="#vid2"></a-videosphere>
      <!-- <Camera></Camera> -->
      <a-entity id="cameraentity2" resetorientation2
        ><a-camera
          id="camera2"
          rotation="0 0 0"
          look-controls="enabled:true; magicWindowTrackingEnabled: true; reverseMouseDrag: true"
          zoom-controls
          fov="70"
          rotation-reader
          position="0 0 0"
          wasd-controls="false"
        ></a-camera
      ></a-entity>
      <Mark
        v-for="marker in markers"
        :key="marker.id"
        :id="marker.id"
        :rotation="marker.rotation"
        :position="marker.position"
        v-on:click.native="toggleModal(marker.id)"
      ></Mark>

      <a-entity
        :id="'glass-ring-10'"
        style="z-index: 10"
        position="4 0.3 -1.85"
        rotation="0 -60 0"
      >
        <a-image
          id="location"
          src="mento.svg"
          width="2"
          height="2"
          scale="0.25 0.25 0.25"
          animation__circle="property: scale; to: 0.3 0.3 0.3; dur: 200; startEvents: grow"
          animation__leave="property: scale; to: 0.25 0.25 0.25; dur: 200; startEvents: shrink"
        ></a-image>
      </a-entity>
    </a-scene>
    <ImageModal
      @closeModalEvent="closeModal"
      name="clue7"
      v-if="this.modals.clue7"
      :text="'Lehet, hogy alkoholt fogyasztott?'"
      :background="'./clue/borosuveg.jpg'"
    ></ImageModal>
    <ImageModal
      @closeModalEvent="closeModal"
      name="clue8"
      v-if="this.modals.clue8"
      :background="'./clue/napellenzo.jpg'"
    ></ImageModal>
    <ImageModal
      @closeModalEvent="closeModal"
      name="clue10"
      v-if="this.modals.clue10"
      :text="'Ez biztos ennek az autónak a forgalmija?'"
      :background="'./clue/forgalmi.jpg'"
    ></ImageModal>
    <ImageModal
      @closeModalEvent="closeModal"
      name="clue15"
      v-if="this.modals.clue15"
      :background="'./clue/jogsi.jpg'"
    ></ImageModal>
    <ImageModal
      @closeModalEvent="closeModal"
      name="clue11"
      v-if="this.modals.clue11"
      :text="'Úgy látszik szeret kockáztatni...'"
      :background="'./clue/loganyu.jpg'"
    ></ImageModal>
    <!-- <VideoModal @closeModalEvent="closeModal" name="clue11" v-if="this.modals.clue11" :video="'roulette.mp4'"></VideoModal> -->
    <VideoModal
      @closeModalEvent="closeModal"
      name="clue9"
      v-if="this.modals.clue9"
      :video="'telefon.mp4'"
    ></VideoModal>

    <!-- <ImageModal @closeModalEvent="closeModal" name="clue9" v-if="this.modals.clue9" :text='"Egy bringaszállító..."' :background="'./tetocsomagtarto.jpg'"></ImageModal> -->
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
// import Camera from "../components/Camera.vue";
import NextButton from "../components/NextButton.vue";
import Mark from "../components/Mark.vue";
import Minimap from "../components/Minimap.vue";
import ImageModal from "../components/ImageModal.vue";
import VideoModal from "../components/VideoModal.vue";
import PrevButton from "../components/PrevButton.vue";
import MavLogo from "../components/MavLogo";
import FullScreenButton from "../components/FullScreenButton";
import VolumeSetter from "../components/VolumeSetter.vue";

// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Loading,
    FullScreenButton,
    MavLogo,
    VolumeSetter,
    Mark,
    // Camera,
    Minimap,
    ImageModal,
    VideoModal,
    PrevButton,

    NextButton,
  },
  data: function () {
    return {
      showLoading: true,
      isMuted: false,
      showVolume: false,
      scene: null,
      mapMarkers: [{}],
      modals: {
        clue7: false,
        clue8: false,
        clue9: false,
        clue10: false,
        clue11: false,
        clue15: false,
      },
      markers: [
        {
          id: "clue7",
          name: "borosuveg",
          rotation: "0 80 0",
          position: "-6 -1.6 -0.6",
        },
        {
          id: "clue8",
          rotation: "0 90 0",
          name: "napellenzo",
          position: "-6 1.4 1.3",
        },
        {
          id: "clue9",
          name: "telefon",
          rotation: "0 80 0",
          position: "-4 -2.2 -1.4",
        },
        {
          id: "clue10",
          name: "forgalmi",
          rotation: "0 -80 0",
          position: "5.2 -1.8 -0.8",
        },
        {
          id: "clue15",
          name: "jogsi",
          rotation: "0 -80 0",
          position: "5.2 -2.15 -1.15",
        },
        {
          id: "clue11",
          name: "loganyu",
          rotation: "0 90 0",
          position: "-6 0 0.95",
        },
      ],
      minimapMarkers: [
        {
          id: "clue9",
          left: "21%",
          top: "38%",
          right: "0px",
          bottom: "0px",
        },

        {
          id: "clue7",
          left: "12%",
          top: "42%",
          right: "0px",
          bottom: "0px",
        },
        {
          id: "clue11",
          left: "12%",
          top: "49%",
          right: "0px",
          bottom: "0px",
        },
        {
          id: "clue8",
          left: "14%",
          top: "55%",
          right: "0px",
          bottom: "0px",
        },
        {
          id: "clue10",
          top: "43%",
          right: "13%",
          bottom: "0px",
        },
        {
          id: "clue15",
          top: "43%",
          right: "20%",
          bottom: "0px",
        },
      ],
    };
  },
  mounted: function () {
    this.setInitAudio();
    var self = this;
    let vid = document.querySelector("#vid2");
    var playPromise = vid.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Automatic playback started!
          // Show playing UI.
          // console.log(success)
          // We can now safely pause video...
          vid.pause();
        })
        .catch((error) => {
          // Auto-play was prevented
          console.log(error);
          // Show paused UI.
        });
    }
    if (vid.readyState !== 4) {
      //HAVE_ENOUGH_DATA
      vid.addEventListener("canplaythrough", this.onCanPlay, false);
      vid.addEventListener("load", this.onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
    } else {
      //video is ready
      this.showLoading = false;
    }
    var audio = document.getElementById("audio2");
    audio.ontimeupdate = function () {
      if (this.currentTime / this.duration > 0.98) {
        this.currentTime = 0;
        this.play();
      }
    };

    document
      .getElementById("glass-ring-10")
      .addEventListener("mouseenter", () => {
        document.getElementById("location").emit("grow");
      });

    document
      .getElementById("glass-ring-10")
      .addEventListener("mouseleave", () => {
        document.getElementById("location").emit("shrink");
      });

    this.scene = document.querySelector("a-scene");
    document
      .getElementById("glass-ring-10")
      .addEventListener("click", function () {
        let camera = document.getElementById("camera2");
        if (self.$store.getters.getIsMobile) {
          self.$helpers.resetCamera(
            self.$helpers.toRad(0),
            self.$helpers.toRad(295)
          );
        } else {
          self.moveCameraToDegree(0, 295);
        }
        setTimeout(function () {
          requestAnimationFrame(step);
          var start = null;

          function step(timestamp) {
            if (start === null) start = timestamp;
            var progress = timestamp - start;
            if (progress === 0) {
              progress = 1;
            }
            let newFov = 70 - (70 * progress) / 800;
            camera.setAttribute("camera", "fov", newFov);
            if (progress < 800) {
              requestAnimationFrame(step);
            } else {
              camera.setAttribute("camera", "fov", 0);
            }
          }
          setTimeout(function () {
            self.$router.push({ path: "/still_3" }).catch(() => {});
          }, 900);
        }, 1100);
      });
  },
  computed: {
    globalVolume: function () {
      return this.$store.getters.getGlobalVolume;
    },
    isLowTexture: function () {
      return this.$store.getters.getIsLowTexture;
    },
    isMobile: function () {
      return this.$store.getters.getIsMobile;
    },
  },
  methods: {
    getAudioVolume() {
      /* let vid = document.getElementById("audio2");
      return vid.volume; */
      return this.globalVolume;
    },
    setInitAudio() {
      let audio = document.getElementById("audio2");
      if (!this.isMobile) {
        audio.volume = this.globalVolume / 100;
      } else if (this.isMobile) {
        if (this.globalVolume === 0) {
          audio.muted = true;
          this.mute();
        } else {
          audio.muted = false;
          this.unMute();
        }
      }
    },
    goPrev: function () {
      this.$router.push({ path: "still_1" });
    },
    onCanPlay() {
      let vid = document.querySelector("#vid2");
      let audio = document.querySelector("#audio2");
      vid.removeEventListener("canplaythrough", this.onCanPlay, false);
      vid.removeEventListener("load", this.onCanPlay, false);
      //video is ready
      vid.play();
      audio.play();
      this.showLoading = false;
    },
    unMute() {
      this.isMuted = false;
    },
    mute() {
      this.isMuted = true;
    },
    showVolumeFn: function () {
      let audio = document.querySelector("#audio2");
      if (this.isMobile) {
        if (!this.showVolume) {
          audio.muted = true;
          this.mute();
          this.$store.commit("setGlobalVolume", 0);
        } else if (this.showVolume) {
          audio.muted = false;
          this.unMute();
          this.$store.commit("setGlobalVolume", 70);
        }
      }
      this.showVolume = !this.showVolume;
    },
    closeModal(msg) {
      let clonedObj = {
        ...this.modals,
        [msg]: false,
      };
      this.modals = clonedObj;
      document.querySelector("#vid2").play();
      document.querySelector("#audio2").play();
    },
    toggleModal(clue) {
      // this.$emit(clue);
      let clonedObj = {
        ...this.modals,
        [clue]: true,
      };
      this.modals = clonedObj;

      // this.$modal.show(clue);

      if (clue == "clue9") {
        document.querySelector("#vid2").pause();
        document.querySelector("#audio2").pause();
      } else {
        document.querySelector("#vid2").pause();
      }
    },
    playVideo: function () {
      document.querySelector("#vid2").play();
      document.querySelector("#audio2").play();
    },

    moveCameraToDegree(x_destination, y_destination) {
      var start, x_start, y_start, x_rotation, y_rotation, y_start_degree;
      var duration = 1000;
      var camera = document.querySelector("a-camera");
      start = null;
      x_start = camera.components["look-controls"].pitchObject.rotation.x;
      x_rotation = x_destination - x_start;
      y_start = camera.components["look-controls"].yawObject.rotation.y;
      y_start_degree = this.$helpers.toDegree(y_start);

      const dest_szembe = 360 - y_destination;
      y_rotation = this.$helpers.toRad(y_destination) - y_start;
      // let yDegree = this.$helpers.toDegree(y_rotation);
      y_start_degree = this.$helpers.subtractMultipleRounds(y_start_degree);
      // yDegree = this.subtractMultipleRounds(y_rotation);

      if (y_start <= y_start_degree <= dest_szembe) {
        y_rotation = this.$helpers.toRad(
          -1 * this.$helpers.getDegreeDiff(y_start_degree, y_destination)
        );
      } else {
        y_rotation = this.$helpers.toRad(
          this.$helpers.getDegreeDiff(y_start_degree, y_destination)
        );
      }
      requestAnimationFrame(step);

      function step(timestamp) {
        if (start === null) start = timestamp;
        var progress = timestamp - start;
        camera.components["look-controls"].pitchObject.rotation.x =
          x_start + (x_rotation * progress) / duration;
        camera.components["look-controls"].yawObject.rotation.y =
          y_start + (y_rotation * progress) / duration;
        if (progress < duration) {
          requestAnimationFrame(step);
        }
      }
    },
  },
};
</script>
<style>
.vue {
  position: relative;
  z-index: 1;
}
</style>