<template>
  <div>
    <Mavlogo class="mavlogo" />

    <SkipButton v-if="show" to="/still_1"></SkipButton>
    <CounterButton
      style="z-index: 1"
      @closeModalEvent="closeModal"
      name="counter"
      v-if="this.counter"
    />

    <div id="videowrapper" class="videoBgWrapper">
      <video
        src="videos/intro.mp4"
        type="video/mp4"
        @error="onVideoError"
        playsinline
        class="videoBg"
        controls
        controlsList="nodownload nofullscreen"
        disablePictureInPicture
        autoplay
        id="intro-vid"
      >
        <track
          default
          src="vtt/intro.vtt"
          kind="subtitles"
          srclang="hu"
          label="Magyar"
        />
      </video>
    </div>
  </div>
</template>

<script>
import SkipButton from "../components/SkipButton.vue";
import CounterButton from "../components/CounterButton.vue";

import Mavlogo from "../components/MavLogo.vue";

import "video.js/dist/video-js.css";

// @ is an alias to /src

export default {
  name: "Intro",
  components: {
    SkipButton,
    CounterButton,
    Mavlogo,
  },
  data: function () {
    return {
      checkVideo: null,
      counter: false,
      show: false,
      isVideoShowing: true,
      scene: null,
      isHovered: false,
      // hoveredCursor: "../../public/searchicon50.png",
      movedPos: 0,
      one: 1,
      movedX: 0,
      isClicked: true,
      beginning: 220,
      ending: 320,
      lastDegree: 0,
      anim: null,
      playerOptions: {
        // videojs options
        muted: true,
        height: 320,
        width: 480,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "example.mp4",
          },
        ],
        // poster: "/static/images/author.jpg",
      },
    };
  },
  methods: {
    routeStill1() {
      self.$router.push({ path: "/still_1" });
    },
    onVideoError() {
      let vid = document.getElementById("intro-vid");
      vid.src = "videos/720/intro_720.mp4";
      this.$store.commit("setIsLowTexture", true);
    },
    closeModal() {
      this.counter = false;
      this.isVideoShowing = true;
      setTimeout(() => {
        this.show = true;
      }, 5000);
      // document.querySelector("#intro-vid").currentTime = 39;
      document.querySelector("#intro-vid").play();
      document
        .querySelector("#intro-vid")
        .removeEventListener("timeupdate", this.checkVideo);
    },
    toggleModal() {
      // var modal = document.getElementById("modal-1");
      // modal.classList.add("md-show");
      this.$modal.show("modal-1");
      // modal.style.display = "block";
    },
    toggleModal2() {
      this.$modal.show("modal-2");
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.show = true;
    }, 5000);

    var self = this;

    document.getElementById("intro-vid").onended = function () {
      self.$router.push({ path: "/still_1" });
    };

    var video = document.getElementById("intro-vid");

    this.checkVideo = function () {
      if (this.currentTime > 39) {
        this.currentTime = 39;
        this.pause();
        self.isVideoShowing = false;
        self.counter = true;
      }
    };

    video.addEventListener("timeupdate", this.checkVideo);

    // var v = document.getElementById('intro-vid')
    // v.textTracks[0].mode = "hidden"
  },
};
</script>
<style>
video::-webkit-media-text-track-display {
  font-size: 100%;
}

video::-webkit-media-text-track-background {
  background: none;
}
.mavlogo {
  left: 6.5%;
}
.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) and (min-aspect-ratio: 16/10) {
  .videoBg {
    object-fit: cover;
    position: fixed;
    width: 90%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (orientation: landscape) and (max-aspect-ratio: 16/10) {
  .videoBg {
    width: 100%;
    height: 100%;

    padding: 0;
  }
}
@media (orientation: portrait) {
  .videoBg {
    width: 100%;
    height: 100%;

    padding: 0;
  }
}

.default-text {
  color: white;
}
.section-center {
  justify-content: center;
  align-items: center;
}
.section {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.video-container {
  /* align-self: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; */

  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}
.image-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.vue {
  position: relative;
  z-index: 1;
}
.w3-animate-opacity {
  animation: opac 0.8s;
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.w3-animate-show {
  animation: show 0.8s;
  animation-fill-mode: forwards;
}

@keyframes show {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
}

.vm--modal {
  box-shadow: none !important;
  background-color: transparent !important;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.md-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  z-index: 2000;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
  background: rgba(40, 43, 49, 0.8);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

/* Content styles */
.md-content {
  color: #333;
  background: #fff;
}

/* Effect 1: Fade in and scale up */
.md-effect-1 .md-content {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.md-show.md-effect-1 .md-content {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.mouse-hover {
  cursor: url("../../public/searchicon50.png"), auto !important;
}
.camera-effect {
  height: 49px;
  width: 49px;
}
.top-right {
  position: absolute;
  z-index: 11;
}

.top-left {
  position: absolute;
  z-index: 11;
  /* transform: rotateY(180deg); */
}
.bottom-right {
  position: absolute;
  z-index: 11;
}
.bottom-left {
  position: absolute;
  z-index: 11;
}
.a-enter-vr-button {
  display: none;
}
.next-btn {
  display: none;
}
</style>