<template>
 <div class="mav-main mav-quiz">
        <div class="mav-quiz-background">
            <img src="utkozespont-typewriter-bg.png" alt="Máv Quiz background typewriter">
        </div>
        <div id="top" class="mav-main-title">
            <div class="mav-main-title-logo">
                <a href="#">
                    <img src="utkozespont-logo.svg" alt="Ütközéspont logo">
                </a>
            </div>
            <div class="mav-main-title-divider"></div>
            <div class="mav-main-title-text">Hogyan is történt?</div>
            <div class="mav-main-title-info mav-main-title-info-success">
                <div class="mav-main-title-info-icon mav-main-title-info-success-icon">
                    <img src="result/utkozespont-check-icon.svg" alt="Check icon">
                </div>
                <div class="mav-main-title-info-text">
                    <span v-if="this.points <=3">Látszik az igyekezet, legközelebb talán még jobban sikerül.</span>
                    <span v-if="this.points <=6 && this.points > 3">Jó helyszínelő vagy.</span>
                    <span v-if="this.points > 6">Remek helyszínelő vagy! - Semmi nem kerülte el a figyelmedet!</span>
                </div>
            </div>
        </div>
        <!-- <full-screen-button></full-screen-button> -->
        <div class="mav-main-content">
            <div class="mav-quiz-letter">
                <div class="mav-quiz-letter-head">
                    <div class="mav-quiz-letter-head-logo">
                        <img src="result/utkozespont-logo-old.svg" alt="Ütközéspont logo">
                    </div>
                    <div class="mav-quiz-letter-head-title">
                        <span>Vasúti Hírek</span>
                    </div>
                    <div class="mav-quiz-letter-head-mav-logo">
                        <img src="result/mav-logo-old.svg" alt="Máv logo">
                    </div>
                </div>
                <div class="mav-quiz-letter-body">
                    <div class="mav-quiz-letter-body-title">
                        Vonattal ütközött egy férfi a vasúti átjáróban
                    </div>
                    <div class="mav-quiz-letter-body-content">
                        <div class="mav-quiz-letter-body-content-img">
                            <img src="result/utkozespont-photo-old.jpg" alt="Ütközéspont baleset">
                        </div>
                        <div class="mav-quiz-letter-body-content-text">
                            Közvetlenül <span :class="[this.checkIfAnswerIsCorrect(1) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(1) esztergomi végállomása</span> előtt <span :class="[this.checkIfAnswerIsCorrect(2) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(2) május 4-én</span> elsodort és <span :class="[this.checkIfAnswerIsCorrect(3) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(3)173 métert tolt maga előtt</span> a sínen egy autót a vonat. A balesetben <span :class="[this.checkIfAnswerIsCorrect(4) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(4) más nem</span> sérült meg. <span :class="[this.checkIfAnswerIsCorrect(5) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(5) A piros jelzés ellenére
                            szabálytalanul</span> áthajtó <span :class="[this.checkIfAnswerIsCorrect(6) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(6) 33 éves</span> sofőr <span :class="[this.checkIfAnswerIsCorrect(7) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(7) könnyebb sérülésekkel megúszta a balesetet.</span> <span :class="[this.checkIfAnswerIsCorrect(8) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(8) Nem fogyasztott alkoholt,</span>  feltételezhetően <span :class="[this.checkIfAnswerIsCorrect(9) ? 'letter-highlight-correct' : 'letter-highlight-wrong','letter-highlight']">(9) sietett és azt hitte, hogy még időben átér a vonat előtt. Türelmetlenségéért
                            majdnem az életével fizetett.</span> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="mav-main-footer mav-buttons-footer">
            <div class="mav-quiz-count">
                <div class="mav-quiz-count-numbers">
                    <span class="mav-quiz-count-numbers-score">{{this.points}}</span>
                    <span class="mav-quiz-count-numbers-divider">/</span>
                    <span class="mav-quiz-count-numbers-full">9</span>
                </div>
                <div class="mav-quiz-count-text">
                    Helyes válasz
                </div>
            </div>
            <div @click="goTo()" class="mav-button-outer">
                <a class="mav-button mav-button-primary">
                    <span>Értékelés</span>
                </a>
            </div>
        </footer>
        <div class="mav-main-frame">
            <div class="mav-main-frame-item mav-main-frame-top-left">
                <img src="utkozespont-frame-top-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-top-right">
                <img src="utkozespont-frame-top-right.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-left">
                <img src="utkozespont-frame-bottom-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-right">
                <img src="utkozespont-frame-bottom-right.svg" alt="Frame item">
            </div>
        </div>
    </div>
</template>

<script>
// import FullScreenButton from '../components/FullScreenButton.vue';
export default {
    name: "Result",
    components: {
        // FullScreenButton,
    },
    computed: {
        points: function() {
            return this.$store.getters.getQuizPoints;
        },
        quizAnswers: function() {
            return this.$store.getters.getQuizAnswers;
        },
    },
    data: function() {
        return {
            topPos: 70,
            prevScrollpos: window.pageYOffset,
        }
    },
    mounted: function() {
        let rect =  document.querySelector(".mav-main-title").getBoundingClientRect();
   this.topPos = rect.top;
       window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll: function() {
         let title = document.querySelector(".mav-main-title");
   //Scroll Effekt
  var currentScrollPos = window.pageYOffset;
  if (currentScrollPos < this.prevScrollpos) {
    if(currentScrollPos < 100) {
      title.style.top = this.topPos+'px';
    }
  } else {
    if(currentScrollPos > 0){
      title.style.top = "-150px";
    }
  }
  this.prevScrollpos = currentScrollPos;
        },
        goTo(){
            this.$router.push({path: "outro"});
        },
        checkIfAnswerIsCorrect(id) {
            if(this.quizAnswers.length == 0){
                return false;
            }
           let ans = this.quizAnswers.find(answer => answer.id === id);
           return ans.isCorrect;
        }
    },
    beforeDestroy: function() {
        window.removeEventListener("scroll", this.handleScroll);
    }

}
</script>

<style scoped>
  .mav-main .mav-main-title {
transition: all 0.6s ease-in-out;
-webkit-transition: all 0.6s ease-in-out;
}
.mav-top-scrolled {
top: -100% !important;
}
.mav-main {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-image: none !important;
    /* padding: 30px; */
}

.mav-main>* {
    z-index: 1;
}

.mav-main-content {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.mav-main.mav-modal-open .mav-main-content {
    z-index: 10;
}

.mav-main.mav-quiz {
    background-color: #001135;
    padding: 0;
}

.mav-main.mav-quiz .mav-quiz-background {
    width: 100%;
    height: 100%;
    position: relative;
    top: 220px;
    z-index: 0;
    pointer-events: none;
}

.mav-main.mav-quiz .mav-quiz-background img {
    height: 100%;
    object-fit: cover;
    object-position: top center;
}

.mav-quiz-count {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100px;
    height: 100px;
    color: rgba(231, 234, 237, 0.6);
}

.mav-quiz-count::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

body.firefox .mav-quiz-count::before {
    background: rgba(0, 40, 122, 0.2);
}

.mav-quiz-count-numbers {
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 8px;
}

.mav-quiz-count-numbers-score {
    color: #fff;
}

.mav-quiz-count-numbers-divider {
    color: #FFCC33;
}

.mav-quiz-count-text {
    line-height: 1.2;
    font-weight: normal;
    font-size: 13px;
    max-width: 40px;
    text-align: center;
    margin-bottom: -10px;
}

.mav-main .mav-main-title {
    position: fixed;
    z-index: 2;
    top: 70px;
    left: 70px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.mav-main .mav-main-title .mav-main-title-logo {
    max-width: 220px;
    margin-right: 30px;
}

.mav-main .mav-main-title .mav-main-title-divider {
    margin-right: 30px;
    height: 50px;
    width: 1px;
    background-color: #fff;
    opacity: .3;
}

.mav-main .mav-main-title .mav-main-title-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    color: #fff;
    max-width: 100px;
}

.mav-main-title-info {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 5px;
    padding: 15px 15px 15px 25px;
    position: relative;
    margin-left: 30px;
}

.mav-main-title-info.mav-main-title-info-success {
    background: rgba(124, 172, 101, 0.15);
}

body.firefox .mav-main-title-info.mav-main-title-info-success {
    background: rgba(124, 172, 101, 0.2);
}

.mav-quiz-letter {
    position: absolute;
    top: 220px;
    left: 50%;
    transform: translateX(-50%);
    width: 860px;
    min-height: 559px;
    max-height: 559px;
    max-width: 90%;
    height: auto;
    background-color: #FFF8F0;
    color: #000;
}

.mav-quiz-letter::before {
    content: "";
    background-image: url(../../public/result/utkozespont-letter-texture.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.mav-quiz-letter::after {
    content: "";
    background-image: url(../../public/result/utkozespont-letter-shadow.png);
    background-repeat: no-repeat;
    background-size: 100% 132px;
    background-position: center bottom;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.mav-quiz-letter-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 50px;
    border-bottom: 2px solid #000000;
}

.mav-quiz-letter-head-logo img {
    width: 100px;
}

.mav-quiz-letter-head-mav-logo img {
    width: 100px;
}

.mav-quiz-letter-head-title {
    font-size: 64px;
    font-weight: bold;
    line-height: 1;
}

.mav-quiz-letter-body {
    display: flex;
    flex-direction: column;
    padding: 25px 50px 48px;
}

.mav-quiz-letter-body-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 25px 0;
    font-weight: bold;
    font-size: 38px;
    line-height: 1.2;
}

.mav-quiz-letter-body-content {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
}

.mav-quiz-letter-body-content-img {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
}

.mav-quiz-letter-body-content-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.mav-quiz-letter-body-content-text {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: justify;
    font-size: 20px;
    line-height: 1.25;
}

.letter-highlight {
    border-radius: 2px;
    padding-left: 3px;
    padding-right: 3px;
}

.letter-highlight-correct {
    background-color: rgba(90, 173, 84, 0.4);
}

.letter-highlight-wrong {
    background-color: rgba(232, 26, 26, 0.4);
}

.mav-main-title-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c4c4;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.mav-main-title-info-success-icon {
    background-color: #7CAC65;
}

.mav-button-outer {
    padding: 8px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 100px;
}

body.firefox .mav-button-outer {
    background: rgba(0, 18, 55, 0.2);
}

.mav-button {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    line-height: 1;
    font-size: 14px;
    color: #00287A;
    height: 40px;
    padding: 5px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-width: 140px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-button-primary {
    color: #00287A;
    background-color: #FFCC33;
}

.mav-button-primary::after {
    content: "";
    background: linear-gradient(90deg, #FFCC33 30%, #FFE9A7 105%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-button-primary {
    color: #00287A;
    background-color: #fff;
}

.mav-button-secondary {
    color: #fff;
    background-color: #00287A;
}

.mav-button-secondary::after {
    content: "";
    background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-button.mav-button-icon {
    justify-content: center;
}

.mav-button.mav-button-icon-left img {
    margin-right: 7px;
}

.mav-button.mav-button-icon-right img {
    margin-left: 7px;
}

.mav-main-nav {
    position: relative;
    top: 40px;
    left: 40px;
    max-width: 340px;
    margin-top: 50px;
}

.mav-main-nav-logo {
    margin-bottom: 40px;
}

.mav-main-nav-logo a {
    display: flex;
    align-items: center;
}

.mav-main-nav-item {
    padding: 10px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 100px;
    margin-bottom: 10px;
}

body.firefox .mav-main-nav-item {
    background: rgba(0, 18, 55, 0.2);
}

.mav-main-nav-item-button {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    line-height: 1;
    font-size: 18px;
    color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary {
    color: #00287A;
    background-color: #FFCC33;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary::after {
    content: "";
    background: linear-gradient(90deg, #FFCC33 30%, #FFE9A7 105%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary {
    color: #00287A;
    background-color: #fff;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary {
    color: #fff;
    background-color: #00287A;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary::after {
    content: "";
    background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-footer {
    position: relative;
    bottom: 70px;
    left: 70px;
    display: flex;
    align-items: center;
    max-width: 54%;
}

.mav-main-footer.mav-buttons-footer {
    max-width: 100%;
    width: calc(100% - 140px);
    justify-content: space-between;
    position: fixed;
}

.mav-main-fullscreen {
    position: fixed;
    top: 70px;
    right: 70px;
    z-index: 2;
}

.mav-main-fullscreen-icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: rgba(0, 40, 122, 0.04);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

body.firefox .mav-main-fullscreen-icon {
    background: rgba(0, 18, 55, 0.1);
}

.mav-main-frame {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    transform: translate(-50%, -50%);
    z-index: 0;
}

.mav-main-frame-item {
    position: absolute;
    width: 50px;
    height: 50px;
    opacity: 0.3;
}

.mav-main-frame-item.mav-main-frame-top-left {
    top: 0;
    left: 0;
}

.mav-main-frame-item.mav-main-frame-top-right {
    top: 0;
    right: 0;
}

.mav-main-frame-item.mav-main-frame-bottom-left {
    bottom: 0;
    left: 0;
}

.mav-main-frame-item.mav-main-frame-bottom-right {
    bottom: 0;
    right: 0;
}

.mav-main-frame-hidden {
    display: none;
}




.fade-enter-active,
.fade-leave-active {
    transition: opacity .7s
}

.fade-enter,
.fade-leave-active {
    opacity: 0
}

.mymodal {
    width: 820px;
    height: 500px;
    max-width: 100%;
    max-height: 90vh;
    border-radius: 5px;
    font-size: 16px;
    margin: 30px;
    position: relative;
    background: rgba(0, 40, 122, 0.04);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}





@media only screen and (max-width: 1180px) {
    .mav-main.mav-quiz .mav-main-title {
        top: 105px;
    }
}

@media only screen and (max-width: 1439px), (max-height: 799px) {
    .mav-main {
        background-position: 25% center;
        padding: 20px;
    }

    .mav-main-footer {
        max-width: 50%;
    }

    .mav-main-frame {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
    }

    .mav-main-nav {
        margin-top: 0;
        max-width: 300px;
    }

    .mav-main-nav-item-button {
        height: 50px;
    }

    .mav-main-title {
        top: 50px;
        left: 50px;
    }

    .mav-main-fullscreen {
        top: 50px;
        right: 50px;
    }

    .mav-main-footer {
        bottom: 50px;
        left: 50px;
    }

    .mav-main-footer.mav-buttons-footer {
        width: calc(100% - 100px);
    }
}

@media only screen and (max-width: 980px), (max-height: 600px) {
    .mav-main-footer {
        max-width: 50%;
    }

    .mav-main-nav {
        margin-top: 0;
        max-width: 220px;
        top: 40px;
        left: 40px;
    }

    .mav-main-nav-item-button {
        height: 45px;
        font-size: 16px;
    }

    .mav-main-nav-logo {
        margin-bottom: 20px;
    }

    .mav-main .mav-main-title .mav-main-title-logo {
        max-width: 150px;
        margin-right: 20px;
    }

    .mav-main .mav-main-title .mav-main-title-divider {
        margin-right: 20px;
        height: 45px;
    }

    .mav-main .mav-main-title .mav-main-title-text {
        font-size: 16px;
        line-height: 1.2;
    }

    .mav-main-fullscreen {
        top: 40px;
        right: 40px;
    }

    .mav-main-fullscreen-icon {
        width: 45px;
        height: 45px;
    }

    .mav-main-frame-item {
        width: 45px;
        height: 45px;
    }

    .mav-main-footer.mav-buttons-footer {
        width: calc(100% - 80px);
    }

    .mav-main-footer {
        bottom: 40px;
        left: 40px;
    }

    .mav-main-content {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mav-button-outer {
        padding: 6px;
    }

    .mav-button {
        height: 35px;
        min-width: 130px;
    }
}

@media only screen and (max-width: 980px) {
    .mav-main .mav-main-title {
        left: 40px;
        top: 40px;
    }

    .mav-main .mav-main-title .mav-main-title-info {
        max-width: calc(100% - 35px);
        flex: 1 0 100%;
        margin-left: 0;
        margin-top: 30px;
    }

    .mav-quiz-letter {
        max-width: 100%;
        width: calc(100% - 80px);
    }

    .mav-quiz-letter-body {
        padding: 25px 25px 48px;
    }

    .mav-quiz-letter-head {
        padding: 25px;
    }

    .mav-quiz-letter-head-title {
        font-size: 54px;
    }

    .mav-quiz-letter-body-title {
        font-size: 32px;
    }

    .mav-quiz-letter-body-content {
        margin-left: -10px;
        margin-right: -10px;
    }

    .mav-quiz-letter-body-content-img {
        padding-left: 10px;
        padding-right: 10px;
    }

    .mav-quiz-letter-body-content-text {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 800px), (max-height: 400px) {
    .mav-main {
        background-position: 20% center;
        padding: 15px;
    }

    .mav-main-title {
        top: 30px;
        left: 30px;
    }

    .mav-main-footer {
        max-width: 50%;
        bottom: 30px;
        left: 30px;
    }

    .mav-main-footer.mav-buttons-footer {
        width: calc(100% - 60px);
    }

    .mav-main-nav {
        max-width: 200px;
        top: 30px;
        left: 30px;
    }

    .mav-main-nav-item {
        padding: 8px;
    }

    .mav-main-nav-item-button {
        height: 40px;
        font-size: 14px;
    }

    .mav-main-nav-logo {
        margin-bottom: 20px;
    }

    .mav-main-fullscreen {
        top: 30px;
        right: 30px;
    }

    .mav-main-fullscreen-icon {
        width: 38px;
        height: 38px;
    }

    .mav-main-fullscreen-icon img {
        width: 16px;
        height: 16px;
    }

    .mav-main-frame {
        width: calc(100% - 25px);
        height: calc(100% - 25px);
    }
    
    .mav-quiz-letter {
        min-height: 730px;
        max-height: 800px;
    }

    .mav-quiz-letter-body-content {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }

    .mav-quiz-letter-body-content-img {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        margin-bottom: 25px;
    }

    .mav-quiz-letter-body-content-img img {
        max-height: 300px;
    }

    .mav-quiz-letter-body-content-text {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    .mav-main.mav-quiz .mav-quiz-background {
        top: 391px;
    }

    .mav-quiz-letter-head-title {
        font-size: 44px;
    }

    .mav-quiz-letter-body-title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 800px) {
    .mav-main .mav-main-title .mav-main-title-info {
        max-width: fit-content;
        max-width: -moz-fit-content;
    }
}

@media only screen and (max-width: 735px) {
    .mav-quiz-letter {
        width: 100%;
    }

    .mav-quiz-letter-body-content-text {
        font-size: 18px;
        line-height: 1.3;
    }
}

@media only screen and (max-width: 600px) {
    .mav-quiz-letter-head-title {
        font-size: 34px;
    }

    .mav-quiz-letter-body-title {
        font-size: 20px;
    }

    .mav-quiz-letter-body-content-img img {
        max-height: 280px;
    }

    .mav-main-title-info {
        font-size: 13px;
    }
}

@media only screen and (max-width: 480px) {
    .mav-main-title {
        max-width: calc(100% - 80px);
    }

    .mav-main-footer {
        max-width: 100%;
    }

    .mav-main .mav-main-title .mav-main-title-divider {
        display: none;
    }

    .mav-main.mav-quiz {
        max-height: 1400px;
    }

    .mav-main.mav-quiz .mav-quiz-background {
        top: 609px;
    }

    .mav-quiz-letter {
        min-height: 800px;
        max-height: 800px;
    }

    .mav-main.mav-quiz .mav-quiz-background img {
        max-height: 1200px;
    }

    .mav-quiz-letter-body {
        padding: 20px 20px 48px;
    }

    .mav-quiz-letter-head-title {
        font-size: 28px;
    }

    .mav-quiz-letter-head-logo img {
        width: 80px;
    }

    .mav-quiz-letter-head-mav-logo img {
        width: 80px;
    }

    .mav-quiz-letter-head {
        padding: 20px;
    }
}

@media only screen and (max-width: 370px) {
    .mav-quiz-letter-head-title {
        font-size: 24px;
    }
}

</style>