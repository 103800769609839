<template>
    <!-- <router-link class="btn next" :to="this.to"> -->
        <div v-if="this.$store.state.btnShow" class="btn next" id="btn">
          <div class="btn-inner">
            <span class="btn-text" style="color: white">{{this.text}}</span>
            <img class="rotate" src="Vector.svg" alt="">
          </div>
        </div>      
    <!-- </router-link> -->
</template>

<script>
export default {
  props: ["to","text"],
  mounted: function () {
    var self = this;
    if(document.getElementById('btn')) {
      document.getElementById('btn').addEventListener('click', () => { self.$router.push({ path: self.to }) })
    }
  }
}
</script>

<style scoped>
  .btn.next {
    position: absolute;
    right: 6.6%;
    bottom: 9.59%;
  }
  .rotate {
    margin-left: 20.41px;
    margin-right: -25.25px;
    height: 15px;
    width: 12.35px;
    height: auto;
  }
 .btn {
   cursor: pointer;
  position: absolute;
  display: flex;
  z-index: 1;
  padding: 8px;
  background: rgba(0, 40, 122, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(42px);
  -webkit-backdrop-filter: blur(42px);

}

.btn-text {
font-style: normal;
font-weight: 600;
font-size: 13px; 
line-height: 16px;
align-items: center;
text-align: center;

/* mav-blue */

color: #FFF;
}

.btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
  box-shadow: 0px 4px 20px rgba(32, 32, 32, 0.5);
  border-radius: 100px;
  /* transform: rotate(-180deg); */
}


@media only screen and (min-width: 320px) and (orientation: portrait) {
  .btn-inner {
    width: 120px;
    height: 30px;
  }
  .btn {
    padding: 4px;
  }
  .btn-text {
    display: block;
    font-size: 12px;
  }
  .rotate {
    margin-left: 10px;
    margin-right: 0;
    height: 12px;
  }
  .btn.next {
    right: 28px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 320px) and (orientation: landscape) {
  .btn-inner {
    width: 120px;
    height: 30px;
  }
  .btn {
    padding: 6px;
  }
  .btn-text {
    display: block;
  }
  .rotate {
    margin-left: 10px;
    margin-right: 0;
    height: 12px;
  }
  .btn.next {
    right: 28px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 600px) { 
  .btn.next {
    bottom: 43px;
  }
}

@media only screen and (min-width: 768px) { 
 /*  .btn {
    width: 100px;
    height: 50px;
  } */
  .btn {
    padding: 6px;
  }
  .btn-inner {
    width: 120px;
    height: 40px;
  }

  .btn-text {
    display: block;
  }
  .rotate {
    margin-left: 10px;
    margin-right: 0;
    height: 13px;
  }
  .btn.next {
    right: 48px;
    bottom: 48px;
  }
}

@media only screen and (min-width:1024px) {
  
  .btn-inner {
    width: 120px;
    height: 40px;
  }
  .rotate {
    margin-left: 8px;
    margin-right: -10px;
    max-width: 12.35px;
    height: auto;
  }
  /* .btn-text {
    font-size: 14px;
  } */
  .btn-text {
    display: block;
  }
  .btn.next {
    right: 41px;
    bottom: 52px;
  }
}

@media only screen and (min-width: 1440px) { 
  .btn-inner {
    width: 140px;
    height: 40px;
  }
  
  .btn.next {
    right: 54px;
    bottom: 60px;
    padding: 8px;
  }
}



</style>