<template>
  <div>
    <MavLogo></MavLogo>
    <full-screen-button />

    <div class="minimap-container" v-bind:class="{ 'minimap-container-bottom':this.$store.state.btnShow }">
      <div
        v-if="this.isMobile"
        @click="showVolumeFn"
        class="sound-container tooltip"
      >
        <span class="tooltiptext-left">Hangerő</span>

        <img
          v-if="!isMuted"
          src="soundon.svg"
          id="sound-size"
          alt="sound-button"
        />
        <img v-else src="sound.svg" id="sound-size" alt="sound-button" />
      </div>

      <div
        v-if="!this.isMobile"
        @click="showVolumeFn"
        class="sound-container tooltip"
      >
        <span class="tooltiptext-left">Hangerő</span>
        <div v-if="this.showVolume" id="failure-size">
          <img src="failure.svg" alt="" />
        </div>
        <img
          v-if="this.globalVolume && !this.showVolume"
          src="soundon.svg"
          id="sound-size"
          alt="sound-button"
        />
        <img
          v-if="!this.globalVolume && !this.showVolume"
          src="sound.svg"
          id="sound-size"
          alt="sound-button"
        />
      </div>
      <volume-setter
        id="audio3"
        :audioVolume="this.getAudioVolume()"
        @volumeUnmuted="unMute"
        @volumeMuted="mute"
        v-if="showVolume"
      ></volume-setter>
      <minimap :markers="this.minimapMarkers" />

      <div @click="playVideo" class="play-container tooltip">
        <span class="tooltiptext">Lejátszás</span>
        <img src="play.svg" id="play-size" alt="play-button" />
      </div>
    </div>

    <div v-if="!this.$store.state.btnShow" class="around-container tooltip">
      <span class="tooltiptext">Forgatás</span>
      <img src="around.svg" class="around-img" alt="play-button" />
    </div>
    <img
      src="/tutorial/utkozespont-frame-bottom-right.svg"
      alt=""
      class="bottom-right camera-effect"
    />
    <img
      src="/tutorial/utkozespont-frame-bottom-left.svg"
      alt=""
      class="bottom-left camera-effect"
    />
    <img
      src="/tutorial/utkozespont-frame-top-right.svg"
      alt=""
      class="top-right camera-effect"
    />
    <img
      src="/tutorial/utkozespont-frame-top-left.svg"
      alt=""
      class="top-left camera-effect"
    />
    <NextButton :text="'Összegzés'" v-if="wait" to="/quiz"></NextButton>
    <PrevButton
      @previousClicked="goPrev"
      :text="'Vissza'"
      v-if="wait"
    ></PrevButton>
    <loading v-if="showLoading" :scene="scene" />
    <a-scene cursor="rayOrigin: mouse">
      <a-assets id="assets3" timeout="1">
      
        <video
          v-if="!this.isLowTexture"
          id="vid3"
          preload="auto"
          src="videos/Mav_3_4096_rf24.mp4"
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
          loop
          muted
        >
          <audio id="audio3" src="mavmix3.mp3" loop="true"></audio>
        </video>

        <video
          v-else
          id="vid3"
          preload="auto"
          src="videos/mav_1920_3.mp4"
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
          loop
          muted
        >
          <audio id="audio3" src="mavmix3.mp3" loop="true"></audio>
        </video>
      </a-assets>
      <a-videosphere foooo src="#vid3"></a-videosphere>
      <a-camera
        id="camera3"
        rotation="0 0 0"
        look-controls="enabled:true; magicWindowTrackingEnabled: true; reverseMouseDrag: true"
        zoom-controls
        fov="70"
        rotation-reader
        position="0 0 0"
        wasd-controls="false"
      ></a-camera>
      <Mark
        v-for="marker in markers"
        :key="marker.id"
        :id="marker.id"
        :rotation="marker.rotation"
        :position="marker.position"
        v-on:click.native="toggleModal(marker.id)"
      ></Mark>
    </a-scene>

    <ImageModal
      @closeModalEvent="closeModal"
      name="clue12"
      v-if="this.modals.clue12"
      :background="'./clue/szonda.jpg'"
      :text="'Ezek szerint nem fogyasztott alkoholt.'"
    ></ImageModal>
    <ImageModal
      @closeModalEvent="closeModal"
      name="clue13"
      v-if="this.modals.clue13"
      :background="'./clue/orvosijelentes.jpg'"
      :text="'Végződhetett volna sokkal rosszabbul is...'"
    ></ImageModal>
    <VideoModal
      @closeModalEvent="closeModal"
      name="clue14"
      v-if="this.modals.clue14"
      :video="'serult.mp4'"
      :subtitle="'vtt/serult.vtt'"
    ></VideoModal>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
// import Camera from "../components/Camera.vue";
import ImageModal from "../components/ImageModal.vue";
import VideoModal from "../components/VideoModal.vue"; // import PrevButton from "../components/PrevButton.vue";
import NextButton from "../components/NextButton.vue";
import PrevButton from "../components/PrevButton.vue";
import Mark from "../components/Mark.vue";
// import CameraReset from "../components/CameraReset.vue";
import Minimap from "../components/Minimap.vue";
import MavLogo from "../components/MavLogo";
import FullScreenButton from "../components/FullScreenButton";
import VolumeSetter from "../components/VolumeSetter.vue";

// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Loading,
    // Camera,
    NextButton,
    PrevButton,
    Mark,
    ImageModal,
    VideoModal,
    // CameraReset,
    Minimap,
    FullScreenButton,
    MavLogo,
    VolumeSetter,
  },
  data: function () {
    return {
      showLoading: true,
      modals: {
        clue12: false,
        clue13: false,
        clue14: false,
      },
      wait: false,
      isMuted: false,
      showVolume: false,
      scene: null,
      isPlaying: false,
      markers: [
        {
          id: "clue12",
          name: "szonda",
          rotation: "0 -10 0",
          position: "1.6 -1.8 -4",
        },
        {
          id: "clue13",
          name: "orvosijelentes",
          rotation: "0 -30 0",
          position: "3.4 -1.8 -4",
        },
        {
          id: "clue14",
          name: "rendor",
          rotation: "0 90 0",
          position: "-4 -0.7 -0.5",
        },
      ],
      minimapMarkers: [
        {
          id: "clue14",
          left: "15%",
          top: "40%",
          right: "0px",
          bottom: "0px",
        },
        {
          id: "clue12",
          top: "20%",
          right: "38%",
          bottom: "0px",
        },
        {
          id: "clue13",
          top: "20%",
          right: "26%",
          bottom: "0px",
        },
      ],
    };
  },
  mounted: function () {
    this.setInitAudio();
    let vid = document.querySelector("#vid3");
    var playPromise = vid.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // Automatic playback started!
          // Show playing UI.
          // console.log(success)
          // We can now safely pause video...
          vid.pause();
        })
        .catch((error) => {
          // Auto-play was prevented
          console.log(error);
          // Show paused UI.
        });
    }
    if (vid.readyState !== 4) {
      //HAVE_ENOUGH_DATA
      vid.addEventListener("canplaythrough", this.onCanPlay, false);
      vid.addEventListener("load", this.onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
    } else {
      //video is ready
      this.showLoading = false;
    }

    var audio = document.getElementById("audio3");
    audio.ontimeupdate = function () {
      if (this.currentTime / this.duration > 0.98) {
        this.currentTime = 0;
        this.play();
      }
    };
    this.$store.commit("setBtnShow", true);
    this.wait = true;
    this.scene = document.querySelector("a-scene");
    setTimeout(function () {
      // document.querySelector("#vid3").play();
    }, 3200);
  },
  computed: {
    isClicked: function () {
      return this.$store.getters.getIsClicked;
    },
    clickedMarkers() {
      return this.$store.getters.clickedMarkers;
    },
    allMarkers() {
      return this.$store.getters.allMarkers;
    },
    globalVolume: function () {
      return this.$store.getters.getGlobalVolume;
    },
    isLowTexture: function () {
      return this.$store.getters.getIsLowTexture;
    },
    isMobile: function () {
      return this.$store.getters.getIsMobile;
    },
  },
  methods: {
    setInitAudio() {
      let audio = document.getElementById("audio3");
      if (!this.isMobile) {
        audio.volume = this.globalVolume / 100;
      } else if (this.isMobile) {
        if (this.globalVolume === 0) {
          audio.muted = true;
          this.mute();
        } else {
          audio.muted = false;
          this.unMute();
        }
      }
    },
    getAudioVolume() {
      /* let vid = document.getElementById("audio3");
      return vid.volume; */
      return this.globalVolume;
    },
    goPrev: function () {
      this.$router.push({ path: "still_2" });
    },
    onCanPlay() {
      let vid = document.querySelector("#vid3");
      let audio = document.querySelector("#audio3");
      vid.removeEventListener("canplaythrough", this.onCanPlay, false);
      vid.removeEventListener("load", this.onCanPlay, false);
      //video is ready
      vid.play();
      audio.play();
      this.showLoading = false;
    },
    unMute() {
      this.isMuted = false;
    },
    mute() {
      this.isMuted = true;
    },
    showVolumeFn: function () {
      let audio = document.querySelector("#audio3");
      if (this.isMobile) {
        if (!this.showVolume) {
          audio.volume = 0;
          audio.muted = true;
          this.mute();
          this.$store.commit("setGlobalVolume", 0);
        } else if (this.showVolume) {
          audio.volume = 0.7;
          audio.muted = false;
          this.unMute();
          this.$store.commit("setGlobalVolume", 70);
        }
      }

      this.showVolume = !this.showVolume;
    },
    closeModal(msg) {
      let clonedObj = {
        ...this.modals,
        [msg]: false,
      };
      this.modals = clonedObj;

      document.querySelector("#vid3").play();
      document.querySelector("#audio3").play();
    },
    toggleModal(clue) {
      // this.$emit(clue);
      let clonedObj = {
        ...this.modals,
        [clue]: true,
      };
      this.modals = clonedObj;

      // this.$modal.show(clue);
      if (clue == "clue14") {
        document.querySelector("#vid3").pause();
        document.querySelector("#audio3").pause();
      } else {
        document.querySelector("#vid3").pause();
      }
    },
    calculateResult() {
      let allItems = this.allMarkers.length;
      let foundItems = this.clickedMarkers.length;
      alert(`${allItems} db-ból megtaláltál ${foundItems} db-ot gratulálok!`);
    },
    playVideo: function () {
      document.querySelector("#vid3").play();
      document.querySelector("#audio3").play();
    },
    checkVideoLoaded: function () {
      let vid = document.querySelector("#vid3");
      if (vid.readyState === 4) {
        if (!this.isPlaying) {
          vid.play();
          this.isPlaying = true;
        }
      }
    },
  },
};
</script>
<style>
.vue {
  position: relative;
  z-index: 1;
}
</style>