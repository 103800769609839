<template>
    
    <div class="mav-main">
        <aside class="mav-main-nav">
            <div class="mav-main-nav-logo">
                <a href="#">
                    <img src="utkozespont-logo.svg" alt="Ütközéspont logo">
                </a>
            </div>
            <div class="mav-main-nav-item" >
                <a id="tutorial-link" class="mav-main-nav-item-button mav-main-nav-item-button-primary">Játék indítása</a>
            </div>
        </aside>
        <full-screen-button></full-screen-button>
        <footer class="mav-main-footer">
            <div class="mav-main-footer-social">
                <div class="mav-main-footer-social-icon">
                    <a href="https://www.facebook.com/mavcsoport/" target="_blank">
                        <img src="utkozespont-facebook.svg" alt="Facebook">
                    </a>
                </div>
                <!-- <div class="mav-main-footer-social-icon">
                    <a href="#">
                        <img src="utkozespont-twitter.svg" alt="Twitter">
                    </a>
                </div> -->
                <div class="mav-main-footer-social-icon">
                    <a href="https://www.instagram.com/mavcsoport.hu/" target="_blank">
                        <img src="utkozespont-instagram.svg" alt="Instagram">
                    </a>
                </div>
            </div>
            <div class="mav-main-footer-line"></div>
        </footer>
        <div class="mav-main-frame">
            <div class="mav-main-frame-item mav-main-frame-top-left">
                <img src="utkozespont-frame-top-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-top-right">
                <img src="utkozespont-frame-top-right.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-left">
                <img src="utkozespont-frame-bottom-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-right">
                <img src="utkozespont-frame-bottom-right.svg" alt="Frame item">
            </div>
        </div>
    </div>
</template>

<script>
import FullScreenButton from '../components/FullScreenButton.vue';
// import FullScreenButton from '../components/FullScreenButton.vue';
export default {
    name: "Home",
    components: {
        FullScreenButton
    },
    methods: {
        startGame: function() {
            alert('indit');
        },
    },
    mounted: function() {
        var self = this;
        document.getElementById('tutorial-link').addEventListener('click', function () {
            self.$router.push({ path: '/guide' })
        })
    },
    computed: {
        isIos() {
            return this.$store.getters.getIsIos;
        }
    },
};
</script>

<style>

.container2 {
    width: 100vw;
    height: 100vh;
    background-image: url("../../public/mavbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: lighten;
  /* background: linear-gradient(180deg, rgba(0, 17, 53, 0) 73.99%, #001135 99.89%); */
}
.btn-section {
    text-align: left !important;
    margin-left: 83px;
    position: absolute;
}
#mavlogo {
    margin-bottom: 41px;
}
.bg-yellow {
background: linear-gradient(88.37deg, #FFE9A7 -28.29%, #FFCC33 103.23%);
}
.bg-blue {
    background: linear-gradient(87.46deg, #0075FF -46.67%, #00287A 67.69%);
box-shadow: 0px 4px 40px rgba(0, 17, 53, 0.75);
}
.btn-cont {
    width: 323.32px;
height: 60px;
border-radius: 100px;
display: flex;
justify-content: center;
align-items:center;
transform: rotate(-180deg);
}
.btn-cont-border {
    cursor: pointer;
    width: 345px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    backdrop-filter: blur(42px);
    /* Note: backdrop-filter has minimal browser support */

border-radius: 100px;
}
#start-text {

font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 23px;
transform: rotate(-180deg);
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

/* mav-blue */

color: #00287A;
}
/* .btn-text {
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 23px;
transform: rotate(-180deg);
display: flex;
align-items: center;
text-align: center;


color: #FFFFFF;
} */
.social-section {
    margin-left: 89px;
    margin-top:131px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
}
.social-line {
    width: 592px;
    height: 0px;
    opacity: 0.3;
border: 1px solid #FFFFFF;
margin-left: 62px;
}
.mg-right {
    margin-right: 32px;
}
/* @media only screen and (min-width: 1440px) {
  
  .btn-section {
      top: 88px;
  }
  .social-section {
      bottom: 60px;
  }
  #mavlogo {
      width: 341px;
  }

  .start-text {
      font-size: 18px;
  }
}

@media only screen and (min-width: 1920px) {
  
  #mavlogo {
      width: 470px;
      margin-bottom: 54px;
  }
  .btn-cont-border {
     width: 474px;
    height: 87px;

    margin-bottom: 20px;
  }
  .btn-cont {
      width: 447.2px;
    height: 68px;
  }
  .btn-section {
      top: 136px;
  }
  .btn-text {
      font-size: 20px;
  }
  #start-text {
      font-size: 20px;
  }
} */

/* GERII CSSSS */
*, *:before, *:after {
    box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol, ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 30px;
    background-image: url(../../public/utkozespont-bg.jpg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 15% center;
}

.mav-main-nav {
    position: relative;
    top: 40px;
    left: 40px;
    z-index: 1;
    max-width: 340px;
    margin-top: 50px;
}

.mav-main-nav-logo {
    margin-bottom: 40px;
}

.mav-main-nav-logo a {
    display: flex;
    align-items: center;
}

.mav-main-nav-item {
    padding: 10px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 100px;
    /* margin-bottom: 10px; */
}

.mav-main-nav-item-button {
    font-weight: 600;
    line-height: 1;
    font-size: 18px;
    color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary {
    color: #00287A;
    background-color: #FFCC33;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary::after {
    content: "";
    background: linear-gradient(90deg, #FFCC33 30%, #FFE9A7 105%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary {
    color: #00287A;
    background-color: #fff;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary:hover::after {
    opacity: 0;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary {
    color: #fff;
    background-color: #00287A;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary::after {
    content: "";
    background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary:hover {
    color: #00287A;
    background-color: #fff;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary:hover::after {
    opacity: 0;
}

.mav-main-footer {
    position: relative;
    z-index: 1;
    bottom: 40px;
    left: 40px;
    display: flex;
    align-items: center;
    max-width: 54%;
}

.mav-main-footer-social {
    display: flex;
    align-items: center;
}

.mav-main-footer-social-icon a {
    display: flex;
    align-items: center;
}

.mav-main-footer-social-icon img {
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-footer-social-icon:hover img {
    opacity: 0.5;
}

.mav-main-footer-line {
    flex: 1;
    height: 1px;
    background-color: #fff;
    opacity: 0.3;
}

.mav-main-footer-social-icon {
    margin-right: 30px;
}

/* .mav-main-fullscreen {
    position: absolute;
    top: 70px;
    right: 70px;
    z-index: 1;
}

.mav-main-fullscreen-icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
} */

.mav-main-fullscreen-icon:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: transparent;
}

.mav-main-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    transform: translate(-50%, -50%);
    z-index: 0;
    pointer-events: none;
    touch-action: none;
}

.mav-main-frame-item {
    position: absolute;
    width: 50px;
    height: 50px;
    opacity: 0.3;
}

.mav-main-frame-item.mav-main-frame-top-left {
    top: 0;
    left: 0;
}

.mav-main-frame-item.mav-main-frame-top-right {
    top: 0;
    right: 0;
}

.mav-main-frame-item.mav-main-frame-bottom-left {
    bottom: 0;
    left: 0;
}

.mav-main-frame-item.mav-main-frame-bottom-right {
    bottom: 0;
    right: 0;
}

.mav-main-frame-hidden {
    display: none;
}

@media only screen and (max-width: 1439px) and (max-height: 799px) {
    .mav-main {
        background-position: 25% center;
    }

    .mav-main-footer {
        max-width: 50%;
    }

    .mav-main-nav {
        margin-top: 0;
        max-width: 300px;
    }

    .mav-main-nav-item-button {
        height: 50px;
    }
}

@media only screen and (max-width: 980px) and (max-height: 500px) {
    .mav-main {
        background-position: 25% center;
        padding: 20px;
    }

    .mav-main-footer {
        max-width: 50%;
        bottom: 30px;
        left: 30px;
    }

    .mav-main-nav {
        margin-top: 0;
        max-width: 220px;
        top: 30px;
        left: 30px;
    }

    .mav-main-nav-item-button {
        height: 45px;
        font-size: 16px;
    }

    .mav-main-nav-logo {
        margin-bottom: 20px;
    }

    /* .mav-main-fullscreen {
        top: 50px;
        right: 50px;
    }

    .mav-main-fullscreen-icon {
        width: 45px;
        height: 45px;
    } */

    .mav-main-frame {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
    }

    .mav-main-frame-item {
        width: 45px;
        height: 45px;
    }
}

@media only screen and (max-width: 800px) and (max-height: 400px) {
    .mav-main {
        background-position: 20% center;
        padding: 15px;
    }

    .mav-main-footer {
        max-width: 50%;
        bottom: 20px;
        left: 20px;
    }

    .mav-main-nav {
        max-width: 200px;
        top: 20px;
        left: 20px;
    }

    .mav-main-nav-item {
        padding: 8px;
    }

    .mav-main-nav-item-button {
        height: 40px;
        font-size: 14px;
    }

    .mav-main-nav-logo {
        margin-bottom: 20px;
    }

    /* .mav-main-fullscreen {
        top: 32px;
        right: 35px;
    }

    .mav-main-fullscreen-icon {
        width: 38px;
        height: 38px;
    }

    .mav-main-fullscreen-icon img {
        width: 16px;
        height: 16px;
    } */

    .mav-main-frame {
        width: calc(100% - 25px);
        height: calc(100% - 25px);
    }

    .mav-main-frame-item {
        width: 40px;
        height: 40px;
    }

    .mav-main-footer-social-icon img {
        max-width: 16px;
        max-height: 16px;
    }
}
</style>