<template>
  <a-circle @click="addMarkerToClickedMarkers"
    light="type: ambient"
    :rotation="this.rotation"
    :id="'outside-' + this.id"
    :position="this.position" 
    scale="0.15 0.15 0.15"
    opacity='0.3'
    animation__circle="property: scale; to: 0.2 0.2 0.2; dur: 200; startEvents: grow"
    animation__leave="property: scale; to: 0.15 0.15 0.15; dur: 200; startEvents: shrink">
    <a-circle 
      :id="'inside-' + this.id"
      scale="0.35 0.35 0.35"
      opacity='1'>
    </a-circle>
  </a-circle>
</template>

<script>
  export default {
    name:"Mark",
    props: ["id","position","rotation"],
    mounted: function() {
        var self = this;
        document.getElementById(`outside-${this.id}`).addEventListener('mouseenter', () => {
          document.getElementById('outside-' + self.id).emit("grow")
        })

        document.getElementById(`outside-${this.id}`).addEventListener('mouseleave', () => {
          document.getElementById('outside-' + self.id).emit("shrink")
        })
    },
    methods: {
      addMarkerToClickedMarkers() {
        if(!this.$store.state.clickedMarkers.includes(this.id)){
          this.$store.commit("addClickedMarker", this.id);
        }
        return 0;
      },
    },
    computed: {
      getClickedMarkers() {
        return this.$store.getters.getClickedMarkers;
      }
    }
  }
</script>

<style>
.top {
  z-index: 10;
}
</style>