<template>
    <div class="btn" @click="moveCamera()">
        <div class="btn-inner"> 
            <img class="img" src="reset.svg"  height="17px" width="15px" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            movedPos: 0,
            one: 1,
            movedX: 0,
        }
    },
    methods: {
    
    toDegree: function (rad) {
      var pi = Math.PI;
      return rad * (180 / pi);
    },
    toRad: function (degree) {
      var pi = Math.PI;
      return degree * (pi / 180);
    },
    moveCamera: function() {
      if(this.$store.getters.getIsMobile){
        return this.$helpers.resetCamera(0,0);
        }
        return this.moveCameraToDegree(0,0);
    },
    getDegreeDiff: function(deg_1, deg_2)
    {   
    return (deg_1-deg_2+540) % 360 - 180;
    },
    subtractMultipleRounds: function(degree) {
      var hanyszor = degree / 360;
      hanyszor = hanyszor >= 0 ? Math.floor(hanyszor) : Math.floor(hanyszor) + 1;
      if (Math.abs(hanyszor) >= 1) {
        degree = degree - hanyszor * 360;
      }
      return degree;
    },
     moveCameraToDegree(x_destination,y_destination) {
       var start, x_start, y_start, x_rotation, y_rotation, y_start_degree;
      var duration = 1000;
      var camera = document.querySelector("a-camera");
      start = null;
      x_start = camera.components["look-controls"].pitchObject.rotation.x;
      x_rotation = x_destination-x_start;
      y_start = camera.components["look-controls"].yawObject.rotation.y;
      y_start_degree = this.toDegree(y_start);

      const dest_szembe = 360-180
      y_rotation = this.toRad(y_destination) - y_start;
      // let yDegree = this.toDegree(y_rotation);
      y_start_degree = this.subtractMultipleRounds(y_start_degree);
      // yDegree = this.subtractMultipleRounds(y_rotation);

      if(y_start <= y_start_degree <= dest_szembe){
        y_rotation = this.toRad(-1*this.getDegreeDiff(y_start_degree, y_destination));
      }else {
        y_rotation = this.toRad(this.getDegreeDiff(y_start_degree, y_destination));
      }
      requestAnimationFrame(step);

      function step(timestamp) {
        if (start === null) start = timestamp;
        var progress = timestamp - start;
        camera.components["look-controls"].pitchObject.rotation.x =
          x_start + (x_rotation * progress) / duration;
        camera.components["look-controls"].yawObject.rotation.y =
          y_start + (y_rotation * progress) / duration;
        if (progress < duration) {
          requestAnimationFrame(step);
        }
      }
    },
    //Ez lesz PCre
    resetCameraAnimated: function (x_destination, y_destination) {
      var start, x_start, y_start, x_rotation, y_rotation;
      var duration = 1000;
      var camera = document.querySelector("a-camera");
      start = null;
      x_start = camera.components["look-controls"].pitchObject.rotation.x;

      x_rotation = x_destination - x_start;

      y_start = camera.components["look-controls"].yawObject.rotation.y;
      y_rotation = y_destination - y_start;
      let yDegree = this.toDegree(y_rotation);
      var hanyszor = yDegree / 360;
      hanyszor = hanyszor >= 0 ? Math.floor(hanyszor) : Math.floor(hanyszor) + 1;
      if (Math.abs(hanyszor) >= 1) {
        yDegree = yDegree - hanyszor * 360;
      }
      y_rotation = this.toRad(yDegree);
      requestAnimationFrame(step);

      function step(timestamp) {
        if (start === null) start = timestamp;
        var progress = timestamp - start;
        camera.components["look-controls"].pitchObject.rotation.x =
          x_start + (x_rotation * progress) / duration;
        camera.components["look-controls"].yawObject.rotation.y =
          y_start + (y_rotation * progress) / duration;
        if (progress < duration) {
          requestAnimationFrame(step);
        }
      }
    },
  },
};
</script>

<style scoped>

.img {
    max-width: 18.33px;
    max-height: 18.33px;
}

.btn {
    cursor: pointer;
    position: absolute;
    display: flex;
    z-index: 1;
    user-drag: none; 
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
    background: rgba(255, 204, 51, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 100px;
    justify-content: center;
    align-items: center;

}

.btn-inner {
    background: #FFCC33;
    margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 100px;
}

@media only screen and (min-width: 320px) {
    .btn {
        width: 24px;
        height: 24px;
    }

    .btn-inner {
        width: 16px;
        height: 16px;
    }

    .img {
        width: 9px;
        height: 11px;
    }
}

@media only screen and (min-width: 768px) { 
    .btn {
        width: 28px;
        height: 28px;
    }

    .btn-inner {
        width: 20px;
        height: 20px;
    }

    .img {
        width: 10px;
        height: 12px;
    }
}

@media only screen and (min-width: 1024px) { 
    .btn {
        width: 35px;
        height: 35px;
    }

    .btn-inner {
        width: 25px;
        height: 25px;
    }

    .img {
        width: 11px;
        height: 13px;
    }
}

@media only screen and (min-width: 1440px) { 
    .btn {
        width: 40px;
        height: 40px;
    }

    .btn-inner {
        width: 30px;
        height: 30px;
    }

    .img {
        width: 13px;
        height: 17px;
    }
}

</style>