<template>
  <div>
    <svg
      v-if="isClicked"
      class="mapMarkerr"
      :style="{
        top: this.marker.top,
        left: this.marker.left,
        right: this.marker.right,
        bottom: this.marker.bottom,
      }"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.8"
        cx="5.16667"
        cy="4.83334"
        r="4.66667"
        fill="white"
      />
    </svg>

    <svg
      v-else
      class="mapMarkerr"
      :style="{
        top: this.marker.top,
        left: this.marker.left,
        right: this.marker.right,
        bottom: this.marker.bottom,
      }"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.2"
        cx="5.16667"
        cy="4.83334"
        r="4.66667"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Mapmarker",
  props: ["marker"],
  data: function () {
    return {
      isClicked: false,
    };
  },
  mounted: function () {
    // console.log("asd",this.clickedMarkers);
    if (this.clickedMarkers.includes(this.marker.id)) {
      this.isClicked = true;
    }
  },
  computed: {
    clickedMarkers() {
      return this.$store.getters.getClickedMarkers;
    },
  },
  watch: {
    clickedMarkers(newMarkers) {
      // Our fancy notification (2).
      if (newMarkers.includes(this.marker.id)) {
        this.isClicked = true;
      }
    },
  },
};
</script>

<style scoped>
.mapMarkerr {
  position: absolute;
}

@media only screen and (min-width: 320px) {
  .mapMarkerr {
    height: 7px;
    width: 7px;
  }
}

@media only screen and (min-width: 768px) {
  .mapMarkerr {
    height: 8px;
    width: 8px;
  }
}

@media only screen and (min-width: 1440px) {
  .mapMarkerr {
    height: 9.33px;
    width: 9.33px;
  }
}
</style>