<template>
  <div>
    <div class="circle">
      <!-- //1920 KÖRÜLI SZÉLESSÉG -->
      <svg v-if="this.windowWidth > 1440" id="svg" width="200" height="200" viewBox="0 0 200 200">
  <g transform="translate(100,100)">
    <path  d="M0 0-80-59A99 99 0 0 1 80-59Z" fill="#FDFDFD33"/>
  </g>
</svg>

     <svg v-if="this.windowWidth > 1200  && this.windowWidth <= 1440" id="svg" width="200" height="200" viewBox="0 0 200 200">
  <g transform="translate(100,100)">
    <path  d="M0 0-86-52A99 99 0 0 1 86-52Z" fill="#FDFDFD33"/>
  </g>
</svg>

<svg v-if="this.windowWidth <= 1200 && this.windowWidth > 1000" id="svg" width="200" height="200" viewBox="0 0 200 200">
  <g transform="translate(100,100)">
    <path  d="M0 0-79-60A99 99 0 0 1 79-60Z" fill="#FDFDFD33"/>
  </g>
</svg>

<svg v-if="this.windowWidth <= 1000 && this.windowWidth > 450" id="svg" width="200" height="200" viewBox="0 0 200 200">
  <g transform="translate(100,100)">
    <path  d="M0 0-68-73A99 99 0 0 1 68-73Z" fill="#FDFDFD33"/>
  </g>
</svg>

<svg v-if="this.windowWidth < 450" id="svg" width="200" height="200" viewBox="0 0 200 200">
  <g transform="translate(100,100)">
    <path  d="M0 0-34-94A99 99 0 0 1 34-94Z" fill="#FDFDFD33"/>
  </g>
</svg>




<!-- <img class="innerCircle" src="innerCircle.svg" alt="yellow circle"> -->
<camera-reset ></camera-reset>
<!-- <img class="mapMarker" v-for="marker in markers" :key="marker.id" src="mapMarker.svg" alt="innerCircle"> -->
<map-marker v-for="marker in markers" :marker=marker :key="marker.id"> </map-marker>
<!-- <img class=" mapMarker2 " src="mapMarker.svg" alt="innerCircle">
<img class=" mapMarker3 " src="mapMarker.svg" alt="innerCircle"> -->

    </div>
  </div>
</template>


<script>
import MapMarker from './MapMarker.vue';
import CameraReset from './CameraReset.vue';
export default {
  name: "Minimap",
  props: ["markers"],
  components: {
    MapMarker,
    CameraReset
  },
  computed: {
    degree: function () {
      return this.$store.getters.getDegree;
    },
  },
  data: function() {
    return {
      windowWidth: window.innerWidth,
      beginning: 220,
      ending: 320,
      txt: "",
    }
  },
  methods: {
    toRadians(deg) {
      return (deg * Math.PI) / 180;
    },
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  mounted: function () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    // svg.style.transform = 'rotate(' + -30 + 'deg)';
    // var self = this;
    //console.log(this.degree);
    /* setTimeout(function() {
    self.$store.commit("setRotateDegree",5)
  },1500) */
   /*  var cx = 70;
    var cy = 70;
    var radius = 70;
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    ctx.moveTo(cx, cy);
    ctx.arc(cx, cy, radius, self.toRadians(self.beginning), self.toRadians(self.ending));
    ctx.lineTo(cx, cy);
    ctx.globalAlpha = 0.5;
    ctx.strokeStyle = "gray";
    ctx.stroke();
    ctx.fillStyle = "gray";
    ctx.fill(); */

   //this.rotateMinimap(360);
  },
};
</script>

<style scoped>
.circle {
  border-radius: 200px;
  height: 140px;
  width: 140px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 2;
 background: rgba(32, 88, 183, 0.04);
box-sizing: border-box;
box-shadow: 0px 4px 100px 20px rgba(0, 40, 122, 0.2);
backdrop-filter: blur(42px);
-webkit-backdrop-filter: blur(42px);
display: flex;
position:relative;
justify-content: center;
align-items: center;
margin-left: 15px;
margin-right: 15px;
/* Note: backdrop-filter has minimal browser support */

}

#myCanvas {
  z-index: 10;
  position: "absolute";
}
#id {
  position: absolute;
}
.innerCircle {
  position: absolute;
  align-self: center;
}


@media only screen and (min-width: 320px) {
    .circle {
      height: 90px;
      width: 90px;
    }
    .innerCircle {
      height: 8px;
      width: 8px;
    }
}

@media only screen and (min-width: 768px) {
    .circle {
      height: 100px;
  width: 100px;
    }
    .innerCircle {
      height: 10px;
      width: 10px;
    }
}

@media only screen and (min-width: 1024px) {
    .innerCircle {
      height: 14px;
      width: 14px;
    }
    .circle {
      height: 120px;
  width: 120px;
    }
}

@media only screen and (min-width: 1440px) {
  .innerCircle {
      height: 14px;
      width: 14px;
    }
    .circle {
      height: 140px;
  width: 140px;
    }

}




</style>