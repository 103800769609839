<template>
  <div>
    <div v-if="this.isClicked" class="blur"></div>
    <img src="/tutorial/utkozespont-frame-bottom-right.svg" alt="" class="bottom-right camera-effect">
    <img src="/tutorial/utkozespont-frame-bottom-left.svg" alt="" class="bottom-left camera-effect">
    <img src="/tutorial/utkozespont-frame-top-right.svg" alt="" class="top-right camera-effect">
    <img src="/tutorial/utkozespont-frame-top-left.svg" alt="" class="top-left camera-effect">
    
    <MavLogo></MavLogo>
    

    

    <!-- <div class="around-container">
      <div>
          <img class="around-img" src="around.svg" alt="around">
      </div>
     </div> -->

    <div class="around-container tooltip">
      <span class="tooltiptext">Forgatás</span>
      <img src="around.svg" class="around-img" alt="play-button">
    </div>
    <!-- <router-link class="vue" to="/about" tag="button">other</router-link> -->
    <!-- <router-link class="vue" to="/video_2" tag="button">Next Video</router-link> -->
        <!-- <camera-reset-button/> -->
    <full-screen-button />
    <h1 class="subtitle" v-if="isSubtitlesEnabled">{{ currentCue }}</h1>
    <!-- <button class="vue" @click="toggleSubtitle">Felirat be/ki</button> -->

    
    <NextButton text="Összegzés" to="/outro"></NextButton>

    <div class="minimap-container" v-bind:class="{ 'minimap-container-bottom':this.$store.state.btnShow }">
      <div v-if="this.isMobile" @click="showVolumeFn" class="sound-container tooltip">
       <span class="tooltiptext-left">Hangerő</span>
       
      <img v-if="!this.isMuted" src="soundon.svg" id="sound-size" alt="sound-button">
      <img v-else src="sound.svg" id="sound-size" alt="sound-button">
    </div>

    <div v-if="!this.isMobile" @click="showVolumeFn" class="sound-container tooltip">
       <span class="tooltiptext-left">Hangerő</span>
       <div v-if="this.showVolume" id="failure-size">
        <img  src="failure.svg" alt="">
      </div>
      <img v-if="this.globalVolume && !this.showVolume" src="soundon.svg" id="sound-size" alt="sound-button">
      <img v-if="!this.globalVolume && !this.showVolume" src="sound.svg" id="sound-size" alt="sound-button">
    </div>
    <volume-setter id="audio" :audioVolume="this.getAudioVolume()" @volumeUnmuted="unMute" @volumeMuted="mute" v-if="showVolume"></volume-setter>

      <minimap :markers="this.minimapMarkers"/>

      <div @click="playVideo" class="play-container tooltip">
      <span class="tooltiptext">Lejátszás</span>
      <img src="play.svg" id="play-size" alt="play-button">
    </div>
    </div>
    <!-- <Hint></Hint> -->
    <!-- <CameraReset></CameraReset> -->
    <!-- <volume-setter :audioVolume="this.getAudioVolume()" @volumeUnmuted="unMute" @volumeMuted="mute" v-if="showVolume"></volume-setter> -->
      <!-- <button class="vue" @click="showVolumeFn">Show Volume</button>
      <button class="vue" @click="checkVideoLoaded">Check Video Lodaed</button>
      <button class="vue" @click="playVideo">Play video</button>
      <button class="vue" @click="moveCameraToDegree(0,117)">Move camera</button>
      <button class="vue" @click="consoleMarkers()">ConsoleClickedMarkers</button> -->
    <!-- <loading :scene="scene" :minLoading="3000" /> -->
    <loading v-if="showLoading" :scene="scene" />
    <a-scene getrenderer cursor="rayOrigin: mouse">
      <a-assets id="assets2" timeout="1">
        <!-- Using the asset management system. 
          <video id="vid" muted playsinline crossorigin='anonymous' loop="true" src="360.mp4"/>
          https://github.com/aframevr/aframe/issues/1846
          <video id="vid" preload="auto" src="https://cdn.aframe.io/videos/bunny.mp4" autoplay loop crossorigin="anonymous" muted playsinline webkit-playsinline></video>

          -->
        <video
          v-if="!this.isLowTexture"
          id="vid"
          preload="auto"
          src="videos/Mav_1_4096_rf24.mp4"
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
          loop="true"
          muted
          autoplay
        >
        <audio
          id="audio"
          src="mavmix1.mp3"
          loop="true">
        </audio>
        
        </video>

        <video
          v-else
          id="vid"
          preload="auto"
          src="videos/mav_1920_1.mp4"
          crossorigin="anonymous"
          playsinline
          webkit-playsinline
          loop="true"
          muted
          autoplay
        >
        <audio
          id="audio"
          src="mavmix1.mp3"
          loop="true">
        </audio>
        </video>

      </a-assets>

      <!-- Using the asset management system. -->
      <a-videosphere src="#vid" ></a-videosphere>
      <!-- <a-sound src="src: url(mavmixTest.mp3)" autoplay="true" loop="true"></a-sound> -->
      <a-entity sound="src: #audio"></a-entity>
      <!-- <a-asset-item id="checkicon" src="../../check.svg"></a-asset-item> -->
      <!-- <a-sky src="puydesancy.jpg" rotation="0 -130 0"></a-sky> -->
      <!-- <a-entity id="camera" camera="userHeight: 1.6" look-controls cursor="rayOrigin: mouse"></a-entity> -->
      <!-- <Camera></Camera> -->
      <a-entity id="cameraentity" resetorientation
    ><a-camera
      id="camera"
      rotation="0 0 0"
      look-controls="enabled:true; magicWindowTrackingEnabled: true; reverseMouseDrag: true"
      zoom-controls
      fov="70"
      rotation-reader
      position="0 0 0"
      wasd-controls="false"
    ></a-camera
  ></a-entity>

      <Mark
        v-for="marker in markers"
        :key="marker.id"
        :id="marker.id"
        :rotation="marker.rotation"
        :position="marker.position"
        v-on:click.native="toggleModal(marker.id)"
      ></Mark>
        <!-- TEST MARKER -->
        <!-- <a-circle
          rotation="0 80 0"
          id="'outside-test"
          position="-7.45 0.3 -0.6" 
          scale="0.15 0.15 0.15"
          opacity='0.3'>
          <a-circle 
            id="'inside-test"
            scale="0.35 0.35 0.35"
            opacity='1'>
          </a-circle>
        </a-circle> -->

      <a-entity :id="'glass-ring-10'" style="z-index: 10" position="-2.7 -0.1 0.98" rotation="0 120 0" >
          <a-image id="location" src="roncs.svg" width="2" height="2" scale="0.2 0.2 0.2"
              animation__circle="property: scale; to: 0.25 0.25 0.25; dur: 200; startEvents: grow"
              animation__leave="property: scale; to: 0.2 0.2 0.2; dur: 200; startEvents: shrink"></a-image>
      </a-entity>
    </a-scene>

      <ImageModal @closeModalEvent="closeModal" name="clue1" v-if="this.modals.clue1" :text='"Hmm...nem sok kellett, hogy eljussanak a végállomásig..."' :background="'./clue/menetrend.jpg'"></ImageModal>
      <ImageModal @closeModalEvent="closeModal" name="clue2" v-if="this.modals.clue2" :text='"Ajjaj, egy kerékpár! Vajon van más sérült is?"' :background="'./clue/bicikli.jpg'"></ImageModal>
      <ImageModal @closeModalEvent="closeModal" name="clue3" v-if="this.modals.clue3" :text='"Egy bringaszállító..."' :background="'./clue/tetocsomagtarto.jpg'"></ImageModal>
      <!-- <VideoModal @closeModalEvent="closeModal" name="clue4" v-if="this.modals.clue4" :video="'helyszinelo.mp4'" :subtitle="'test.vtt'"></VideoModal> -->
      <VideoModal @closeModalEvent="closeModal" name="clue6" v-if="this.modals.clue6" :video="'mozdonyvezeto.mp4'" :subtitle="'vtt/mozdonyvezeto.vtt'"></VideoModal>
      <ImageModal @closeModalEvent="closeModal" name="clue5" v-if="this.modals.clue5" :text="'Úgy tűnik elég hosszan tolta a vonat maga előtt a gépkocsit...'"  :background="'./clue/tavolsagmero.jpg'"></ImageModal>

  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
// import Camera from "../components/Camera.vue";
import ImageModal from "../components/ImageModal.vue";
import VideoModal from "../components/VideoModal.vue";
import NextButton from "../components/NextButton.vue";
// import Hint from "../components/Hint.vue";
// import CameraReset from "../components/CameraReset.vue";
import FullScreenButton from "../components/FullScreenButton";
import Minimap from "../components/Minimap.vue";
import Mark from "../components/Mark.vue";
import VolumeSetter from "../components/VolumeSetter.vue";
import MavLogo from "../components/MavLogo";
import { VueTyper } from 'vue-typer'
// import CameraResetButton from "../components/CameraResetButton.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Loading,
    // Camera,
    NextButton,
    MavLogo,
    VideoModal,
    ImageModal,
    // CameraReset,
    // Hint,
    FullScreenButton,
    Minimap,
    Mark,
    VolumeSetter,
    VueTyper
    // CameraResetButton,
  },
  data: function () {
    return {
      showLoading: true,
      isLowQuality: false,
      clue1: false,
      modals: {
        clue1: false,
        clue2: false,
        clue3: false,
        // clue4: false,
        clue5: false,
        clue6: false,
      },
      showImg: false,
      scene: null,
      isSubtitlesEnabled: false,
      showVolume: false,
      currentCue: "",
      isModalVisible: false,
      isMuted: false,
      mapMarkers: [
        {}
      ],
      markers: [
        {
          id: "clue1",
          rotation:"0 80 0",
          position: "-7.45 0.3 -0.6",
        },
        {
          id: "clue2",
          rotation:"-20 170 0",
          position: "-0.1 -2.5 3",
        },
        {
          id: "clue3",
          rotation:"0 110 0",
          position: "-7.45 -0.1 1.7",
        },
        /* {
          id: "clue4",
          rotation:"-10 150 0",
          position: "-3 -2 4",
        }, */
        {
          id: "clue5",
          rotation:"0 220 0",
          position: "3.4 -1.5 4",
        },
        {
          id: "clue6",
          rotation:"10 80 0",
          position: "-7.45 -0.2 -1",
        },
      ],
      minimapMarkers: [
        {
          id: "clue6",
          left: "21%",
          top: "43%",
          right: "0px",
          bottom: "0px",
        },
        
        {
          id: "clue1",
          left: "10%",
          top: "45%",
          right: "0px",
          bottom: "0px",
        },
        {
          id: "clue3",
          left: "14%",
          top: "52%",
          right: "0px",
          bottom: "0px",
        },
        /* {
          id: "clue4",
          left: "30%",
          top: "63%",
          right: "0px",
          bottom: "0px",
        }, */
        {
          id: "clue2",
          top: "63%",
          left: "41%",
        },
        {
          id: "clue5",
          left: "61%",
          top: "65%",
          right: "0px",
          bottom: "0px",
        }, 
        
        
      ]
    };
  },
  mounted: function () { 
    this.setInitAudio();
    // this.$store.commit("setBtnShow",false);
    var self = this;
    let vid = document.querySelector("#vid");
    var playPromise = vid.play();
 
  if (playPromise !== undefined) {
    playPromise.then(() => {
      // Automatic playback started!
      // Show playing UI.
      // console.log("sus",success)
      // We can now safely pause video...
      vid.pause();
    })
    .catch(error => {
      // Auto-play was prevented
      console.log(error);
      // Show paused UI.
    });
  }
    if(vid.readyState !== 4){ //HAVE_ENOUGH_DATA
      vid.addEventListener('canplaythrough', this.onCanPlay, false);
      vid.addEventListener('load', this.onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
    }else{
      //video is ready
      this.showLoading = false;
    }
    var audio = document.getElementById('audio')
    // audio.volume = 0.4;

    audio.ontimeupdate = function() {
      if((this.currentTime / this.duration)>0.98){
        this.currentTime = 0;
        document.getElementById('vid').currentTime = 0
        this.play();
      }
    };
    /* this.$aframe.registerComponent('rotation-reader', {
    tick: function () {
        //let rotationY = 0;
        let svg = document.getElementById("svg");
        var rotation = this.el.getAttribute('rotation');
          // console.log(rotation.x);
          // console.log(rotation.y);
          if(svg) {
            svg.style.transform = 'rotate(' + -1*rotation.y + 'deg)';
          }
           // self.oldDegree = rotation.y;
         // self.$store.commit("setRotateDegree",rotation.y);
    }
  }); */

        document.getElementById('glass-ring-10').addEventListener('mouseenter', () => {
          document.getElementById('location').emit("grow")
        })

        document.getElementById('glass-ring-10').addEventListener('mouseleave', () => {
          document.getElementById('location').emit("shrink")
        })


    document.getElementById("glass-ring-10").addEventListener('click', function() {
      //  let camera = document.getElementById("camera");
      if(self.$store.getters.getIsMobile){
        self.$helpers.resetCamera(self.$helpers.toRad(-6),self.$helpers.toRad(110));
        }else {
        self.moveCameraToDegree(-6,110);
      }
      var sceneEl = document.querySelector("a-scene");
              var camera = sceneEl.querySelector("#camera");
            setTimeout(function() {
               requestAnimationFrame(step);
              var start = null;
              
              let fov = Number(camera.getAttribute("camera").fov);
              function step(timestamp) {
                if (start === null) start = timestamp;
                var progress = timestamp - start;
                if(progress===0){
                  progress=1;
                }
                let newFov = fov - (fov*progress) / 800;
                camera.setAttribute("camera", "fov", newFov);
                if(progress < 800) {
                  requestAnimationFrame(step);
                }else {
                  camera.setAttribute("camera", "fov",0);
                }
              } 
              setTimeout(function() {
              self.$router.push({ path: '/still_2' }).catch(() => {})
              }, 900)
              
            },1100)
    })

    // document.querySelector("#vid").play();
  },
  methods: {
    setInitAudio() {
      let audio = document.getElementById("audio");
      if(!this.isMobile){
      audio.volume = this.globalVolume / 100;
      } else if(this.isMobile) {
        if(!this.globalVolume) {
          audio.muted = true;
          this.mute();
        }else {
          audio.muted = false;
          this.unMute();
        }
      }
    },
    getAudioVolume() {
      /* let vid = document.getElementById("audio");
      return vid.volume; */
      return this.globalVolume;

    },
    setShow() {
      this.showImg = true
    },
    unMute() {
      this.isMuted = false;
    },
    mute() {
      this.isMuted = true
    },
    onCanPlay(){
      let vid = document.querySelector("#vid");
      let audio = document.querySelector("#audio")
      vid.removeEventListener('canplaythrough', this.onCanPlay, false);
      vid.removeEventListener('load', this.onCanPlay, false);
      //video is ready
      vid.play();
      audio.play();
      this.showLoading = false;
    },
    closeModal(msg) {
      let clonedObj = {
        ...this.modals,
        [msg] : false,
      }
      this.modals = clonedObj;
      // this.modals.msg = false;
      document.querySelector("#vid").play();
      document.querySelector("#audio").play();
    },
    toggleModal(clue) {
      // this.$emit(clue);
      let clonedObj = {
        ...this.modals,
        [clue] : true,
      }
      this.modals = clonedObj;

      if(clue == 'clue6') {
        document.querySelector("#vid").pause();
        document.querySelector("#audio").pause();
      } else {
        document.querySelector("#vid").pause();
      }
    },
    
    playVideo: function() {
      document.querySelector("#vid").play();
      document.querySelector("#audio").play();
    },
    moveCameraToDegree(x_destination,y_destination) {
      var start, x_start, y_start, x_rotation, y_rotation, y_start_degree;
      var duration = 1000;
      var camera = document.querySelector("a-camera");
      start = null;
      x_start = camera.components["look-controls"].pitchObject.rotation.x;
      x_destination = this.$helpers.toRad(x_destination);
      x_rotation = x_destination-x_start;
      y_start = camera.components["look-controls"].yawObject.rotation.y;
      y_start_degree = this.$helpers.toDegree(y_start);

      const dest_szembe = 360-y_destination;
      y_rotation = this.$helpers.toRad(y_destination) - y_start;
      // let yDegree = this.$helpers.toDegree(y_rotation);
      y_start_degree = this.$helpers.subtractMultipleRounds(y_start_degree);
      // yDegree = this.$helpers.subtractMultipleRounds(y_rotation);

      if(y_start <= y_start_degree <= dest_szembe){
        y_rotation = this.$helpers.toRad(-1*this.$helpers.getDegreeDiff(y_start_degree, y_destination));
      }else {
        y_rotation = this.$helpers.toRad(this.$helpers.getDegreeDiff(y_start_degree, y_destination));
      }
      requestAnimationFrame(step);

      function step(timestamp) {
        if (start === null) start = timestamp;
        var progress = timestamp - start;
        camera.components["look-controls"].pitchObject.rotation.x =
          x_start + (x_rotation * progress) / duration;
        camera.components["look-controls"].yawObject.rotation.y =
          y_start + (y_rotation * progress) / duration;
        if (progress < duration) {
          requestAnimationFrame(step);
        }
      }
    },
    checkVideoLoaded: function() {
      let vid = document.querySelector("#vid");
      if ( vid.readyState === 4 ) { vid.play() }
    },
    showVolumeFn: function() {
      let audio = document.querySelector("#audio");
      if(this.isMobile) {
        if(!this.isMuted) {
          audio.muted = true;
          this.mute();
          this.$store.commit("setGlobalVolume",0);
        }else if(this.isMuted) {
          audio.muted = false;
          this.unMute()
       this.$store.commit("setGlobalVolume",70);
        }
      }
     /*  if(!this.isMobile && this.showVolume){
        let audio = document.querySelector("#audio");
        audio.volume = 0;
      }else if(!this.isMobile && !this.showVolume){
        let audio = document.querySelector("#audio");
        audio.volume = 0.7;
      //  this.$store.state.globalVolume = 70;
      }
      //  this.$store.state.globalVolume = 0; */
       this.showVolume =! this.showVolume;
    },
    toggleSubtitle: function() {
      this.isSubtitlesEnabled= !this.isSubtitlesEnabled
    },
    resetCameraAnimated: function (x_destination, y_destination) {
      var start, x_start, y_start, x_rotation, y_rotation;
      var duration = 1000;
      var camera = document.querySelector("a-camera");
      start = null;
      x_start = camera.components["look-controls"].pitchObject.rotation.x;

      x_rotation = x_destination - x_start;

      y_start = camera.components["look-controls"].yawObject.rotation.y;
      y_rotation = y_destination - y_start;

      let yDegree = this.$helpers.toDegree(y_rotation);

      var hanyszor = yDegree / 360;
      hanyszor = hanyszor >= 0 ? Math.floor(hanyszor) : Math.floor(hanyszor) + 1;
      if (Math.abs(hanyszor) >= 1) {
        yDegree = yDegree - hanyszor * 360;
      }
      y_rotation = this.$helpers.toRad(yDegree);
      y_rotation = this.$helpers.toRad(y_destination);
      requestAnimationFrame(step);

      function step(timestamp) {
        if (start === null) start = timestamp;
        var progress = timestamp - start;
        camera.components["look-controls"].pitchObject.rotation.x =
          x_start + (x_rotation * progress) / duration;
        camera.components["look-controls"].yawObject.rotation.y =
          y_start + (y_rotation * progress) / duration;
        if (progress < duration) {
          requestAnimationFrame(step);
        }
      }
    },
  },
  computed: {
    isClicked: function() {
      return this.$store.getters.getIsClicked;
    },
    globalVolume: function() {
      return this.$store.getters.getGlobalVolume
    },
    isLowTexture: function() {
      return this.$store.getters.getIsLowTexture
    },
    isMobile: function() {
      return this.$store.getters.getIsMobile;
    }
  },
};
</script>
<style>
/* source-sans-pro-regular - latin */
/* MOBILOS VERZO MIATT TOOLTIPTEXT OFF */
.tooltip .tooltiptext {
  display:none;
}

.minimap-container {
  display:flex;
  flex-direction: row;
  position: absolute;
  z-index: 1;
  align-items:center;
  bottom: 23px;
  left: 50%;
  transform: translateX(-50%);
}

.minimap-container-bottom {
  bottom: 23px;
}


.modal-mask {
  background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
      background-color: transparent !important;
    -webkit-backdrop-filter: blur(12px) !important;
    backdrop-filter: blur(12px) !important; 
}
.tooltip .tooltiptext-left {
  display:none;
}

.img-cont {
  display:flex;
  width: 100%;
}

/* Fullscreen modal on mobile devices */
/* @media only screen and (max-width: 768px) {
  .mymodal {
    width: 100%;
    height: 50%;
    background-size: cover;
  }
  .typer-container {
    max-width: 60vw;
    font-size: 12px;
  }

} */

@media (hover: hover) and (pointer: fine) {
  .tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}
.tooltip .tooltiptext {
  display:inline;
  visibility: hidden;
  width: 85px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  color: #FFF;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 120%;
  font-size: 0.875em;
font-style: normal;
font-weight: normal;
line-height: 18px;
  background: #001135;
  backdrop-filter: blur(12px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #001135 transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext-left {
  display:inline;
  visibility: hidden;
  width: 85px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
    font-size: 0.875em;
font-style: normal;
font-weight: normal;
line-height: 18px;
  background: #001135;
  /* backdrop-filter: blur(12px); */
border: 1px solid rgba(255, 255, 255, 0.3);
  right: 120%;
}

.tooltip .tooltiptext-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #001135;
}
.tooltip:hover .tooltiptext-left {
  visibility: visible;
}

}

.play-container {
  user-drag: none; 
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  align-self:center;
    border: 1px solid rgba(255, 255, 255, 0.6); 
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(0, 40, 122, 0.04);
  backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);
  border-radius: 100px;
}

.sound-container {
  cursor: pointer;
  z-index: 10;
width: 50px;
user-drag: none; 
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
align-self:center;
height: 50px;
border: 1px solid rgba(255, 255, 255, 0.3);
justify-content: center;
align-items: center;
display: flex;
background: rgba(0, 40, 122, 0.04);
backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);
border-radius: 100px;
}

#fullscreen-container {
    cursor: pointer;
    
width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
background: rgba(0, 40, 122, 0.04);
border: 1px solid rgba(255, 255, 255, 0.3);
box-sizing: border-box;
backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);
border-radius: 100px;
z-index: 10;
position: absolute;
}
#fullscreen-button {
  z-index: 10;
}

.around-container {
    position: absolute;
    width: 75px;
    height: 75px;
    left: 50px;
    bottom: 50px;
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
   border: 1px solid rgba(255, 255, 255, 0.6); 
  z-index: 10;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(0, 40, 122, 0.04);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 100px;
}

.around-img {
  height: 55px;
  width: 50px;
}

.vue {
  position: relative;
  z-index: 1;
}
.subtitle {
  position: fixed;
  width: 300px;
  background: #eee;
  bottom: 0px;
  right: 25%;
  left: 50%;
  margin-left: -150px;
  z-index: 999999;
}
.blur {
  height:100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  z-index: 1;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid white;
  position: absolute; 
  top: 50%; 
  right: 3%; 
  transform: translate(-3%, -50%);
  cursor: pointer;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; 
  border-right: 20px solid white; 
  position: absolute; 
  top: 50%; 
  left: 3%; 
  transform: translate(-3%, -50%);
  cursor: pointer;
}



.vue-typer .char {
  color: white !important; 
}

.vue-typer .custom.caret {
  display: none;
}

@media only screen and (min-width: 320px) {
  .camera-effect {
    height: 30px;
    width: 30px;
  }
  #failure-size {
    width: 10px;
    height: auto;
  }

  #fullscreen-container {
    right: 28px;
    top: 32px;
    height: 30px;
    width: 30px;
  }
  #fullscreen-button {
        height: 15px;
    width: 15px;
  }
  
  .top-right {
    right: 15px;
  top: 15px;
  user-drag: none; 
user-select: none;
-moz-user-select: none;
 -webkit-user-drag: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
  }
  .top-left {
    
    top: 15px;
  left: 15px;
  user-drag: none; 
  -webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
  }
  .bottom-left {
    bottom: 15px;
    left: 15px;
    user-drag: none; 
user-select: none;
 -webkit-user-drag: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
  }
  .bottom-right {
    right: 15px;
    bottom: 15px;
    user-drag: none; 
     -webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
  }

  .play-container {
    height: 30px;
    width: 30px;
  }
  .sound-container {
height: 30px;
width: 30px;

  }
  #sound-size {
    width: 14px;
    height: 13px;
  }
  #play-size {
    height: 11px;
    width: 12px;
  

  }
  #failure-size {
    display: flex;
    justify-content:center;
    align-items:center;
  }

  .around-container {
    width: 50px;
    height: 50px;
  }
  .around-img {
    height: 20px
  }

}

@media only screen and (min-width: 375px) {
  .play-container {
    height: 30px;
    width: 30px;
  }
  .sound-container {
height: 30px;
width: 30px;

  }
  #sound-size {
    width: 14px;
    height: 13px;
  }
 
  #play-size {
    height: 11px;
    width: 12px;
  }

  .around-img {
    height: 30px
  }
}

@media only screen and (min-width: 425px) {
  .play-container {
    height: 30px;
    width: 30px;
  }
  .sound-container {
height: 30px;
width: 30px;

  }
  #sound-size {
    width: 14px;
    height: 13px;
  }
 
  #play-size {
    height: 11px;
    width: 12px;
  }
}

@media only screen and (min-width: 600px) {
  /* .minimap-container {
    bottom: 23px;
  } */

  .minimap-container-bottom {
    bottom: 23px;
  }

  .around-container {
    left: 50px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .around-container {
    top: 90px;
    bottom: auto;
    left: 35px;
  }

  /* .minimap-container {
    bottom: 80px;
  } */

  .minimap-container-bottom {
    bottom: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .around-container {
    left: 35px;
    bottom: 35px;
  }
}

@media only screen and (min-width: 768px) {
  .camera-effect {
    height: 35px;
    width: 35px;
  }

  #fullscreen-container {
    right: 43px;
    top: 52px;
    height: 40px;
    width: 40px;
  }
  #fullscreen-button {
        height: 15px;
    width: 15px;
  }

  .top-right {
    right: 25px;
  top: 28px;
  }
  .top-left {
    top: 28px;
  left: 30px;
  }
  .bottom-left {
    bottom: 26px;
    left: 30px;
  }
  .bottom-right {
    right: 30px;
    bottom: 26px;
  }

  .play-container {
    height: 35px;
    width: 35px;
  }
  .sound-container {
height: 35px;
width: 35px;
  }
  #sound-size {
    width: 16px;
    height: 15px;
  }
 
  #play-size {
    height: 11px;
    width: 12px;
  }

  .around-container {
    width: 50px;
    height: 50px;
  }
  .around-img {
    height: 35px
  }
}


@media only screen and (min-width: 1024px) {
  .camera-effect {
    height: 40px;
    width: 40px;
  }
  #fullscreen-container {
    right: 45px;
    top: 53px;
    height: 40px;
    width: 40px;
  }
   .top-right {
    right: 25px;
    top: 28px;
  }
  .top-left {
    top: 28px;
    left: 30px;
  }
  .bottom-left {
    bottom: 26px;
    left: 30px;
  }
  .bottom-right {
    right: 30px;
    bottom: 26px;
  }
  .play-container {
    height: 38px;
    width: 38px;
  }
  .sound-container {
    height: 38px;
    width: 38px;
  }
   #sound-size {
        height: 15px;
    width: 16px;
  } 
  .around-container {
    width: 60px;
    height: 60px;
  }
  .around-img {
    height: 45px
  }

}


@media only screen and (min-width: 1440px) {
  #fullscreen-container {
    right: 66px;
    top: 69px;
    height: 50px;
    width: 50px;
  }
  #fullscreen-button {
        height: 16.6px;
    width: 16.6px;
  }
  .camera-effect {
    height: 49px;
    width: 49px;
  }
  .top-right {
    right: 25px;
  top: 28px;
  }
  .top-left {
    top: 28px;
  left: 30px;
  }
  .bottom-left {
    bottom: 26px;
    left: 30px;
  }
  .bottom-right {
    right: 30px;
    bottom: 26px;
  }
  .play-container {
height:50px;
width: 50px;

  }
  .sound-container {
height: 50px;
width: 50px;
  }

  #play-size {
    width: 11.67px;
  height:13.34px
  }
  #sound-size {
    width: 16px;
    height: 16px;
  }
  #failure-size {
    width: 10px;
    height: auto;
  }
  .around-container {
    width: 75px;
    height: 75px;
  }
  .around-img {
    height: 55px;
    width: 50px;
  }
}




@media only screen and (min-width: 1920px) {
  .play-container {
height:50px;
width: 50px;
  }
  .sound-container {
height: 50px;
width: 50px;
  }
}
</style>