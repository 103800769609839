<template>
  <img class="logo" src="utkozespont-logo.svg" alt="mav logo">
</template>

<script>
export default {

}
</script>

<style>
.logo {
    position:absolute;
    z-index: 200;
    pointer-events: none;
    user-drag: none; 
     -webkit-user-drag: none;
user-select: none;
-moz-user-select: none;
-webkit-user-drag: none;
-webkit-user-select: none;
-ms-user-select: none;
}

@media only screen and (min-width: 320px) {
   .logo {
      width: 150px;
    }
}

@media only screen and (min-width: 425px) {
   .logo {

    }
}

@media only screen and (min-width: 600px) {
   .logo {
    }
}

@media only screen and (max-width: 768px) {
   .logo {
     top: 35px;
     left: 35px;
    }
}

@media only screen and (min-width: 768px) {
   .logo {
     top: 50px;
     left: 50px;
    }
}

@media only screen and (min-width: 1024px) {
    .logo {

    }
}

@media only screen and (min-width: 1440px) {
    .logo {
      width: 220px;
    }
}
</style>