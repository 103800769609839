import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLowTexure: false,
    isMobile: false,
    quizPoints: 0,
    isIos: false,
    allMarkers: [
      {id: 1},
      {id: 2},
      {id: 3},
      {id: 4},
      {id: 5},
      {id: 6},
      {id: 7},
      {id: 8},
      {id: 9},
      {id: 10},
      {id: 11},
      {id: 12},
      {id: 13},
      {id: 14},
    ],
    quizAnswers: [],
    clickedMarkers: [],
    isClicked: false, 
    isAnimationEnded: false,
    rotateDegree: 40,
    btnShow: false,
    userId: null,
    globalVolume: 70,
  },
  mutations: {
    setIsLowTexture(state,value) {
      state.isLowTexure = value;
    },
    setQuizAnswers(state,value) {
      state.quizAnswers = value;
    },
    setGlobalVolume(state,value) {
      state.globalVolume = value;
    },
    setBtnShow(state,value) {
      state.btnShow = value
    },
    setQuizPoints(state,points) {
      state.quizPoints = points;
    },
    addClickedMarker(state,marker) {
      state.clickedMarkers.push(marker);
    },
    resetClickedMarkers(state) {
      state.clickedMarkers = [];
    },
    toggleIsClicked(state) {
      state.isClicked = !state.isClicked;
    },
    endAnimation(state) {
      state.animationEnd = true;
    },
    setRotateDegree(state, payload) {
      state.rotateDegree = payload
    },
    setIsMobile(state, payload) {
      state.isMobile= payload;
    },
    setIsIos(state,payload) {
      state.isIos = payload;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getQuizAnswers(state) {
      return state.quizAnswers;
    },
    getGlobalVolume(state) {
      return state.globalVolume;
    },
    getQuizPoints(state) {
      return state.quizPoints;
    },
    getClickedMarkers(state) {
      return state.clickedMarkers;
    },
    allMarkers(state) {
      return state.allMarkers;
    },
    getIsClicked(state) {
      return state.isClicked;
    },
    getAnimationEnd(state) {
      return state.isAnimationEnded;
    },
    getDegree(state) {
      return state.rotateDegree;
    },
    getIsMobile(state) {
      return state.isMobile;
    },
    getIsIos(state) {
      return state.isIos;
    },
    getIsLowTexture(state) {
      return state.isLowTexure;
    }
  }
})
