<template>
  
    <div class="mav-main mav-tutorial">
        <div class="mav-tutorial-title">
            <div class="mav-tutorial-title-logo">
                <a href="#">
                    <img src="utkozespont-logo.svg" alt="Ütközéspont logo">
                </a>
            </div>
            <div class="mav-tutorial-title-divider"></div>
            <div class="mav-tutorial-title-text">
                A játék célja
            </div>
        </div>
        <full-screen-button></full-screen-button>
        
        <div class="mav-main-content mav-tutorial-content">
            <div class="mav-tutorial-content-title">
          <!-- Nézz körbe a baleset helyszínén és derítsd ki hogyan történt! Találd meg a nyomokat és emlékezz minél többre! -->
                Derítsd ki, hogyan történt a baleset! (Játékidő: 10 perc)
                <section style="font-size: 16px; font-weight: 500; margin-top: 18px;">
                    1) Nézz körbe a baleset helyszínén, tekints be a roncsba és a mentőautóba is!<br>
                    2) Vizsgáld meg a nyomokat, jegyezd meg az összegyűjtött információkat!<br>
                    3) Töltsd ki a hiányzó adatokat és oldd meg az esetet!
                </section>
        </div>
            <div class="mav-tutorial-boxes">
                <div class="mav-tutorial-boxes-box">
                    <div style="max-height: 80px" class="mav-tutorial-boxes-box-icon">
                        <img src="/tutorial/utkozespont-tutorial-drag-icon.png" alt="Drag icon">
                    </div>
                    <div class="mav-tutorial-boxes-box-text">
                        A játékban 360 fokban körbe tudsz nézni, ha az ujjadat vagy az egeret a képen tartva elhúzod.
                    </div>
                </div>
                <div class="mav-tutorial-boxes-box">
                    <div class="mav-tutorial-boxes-box-icon">
                        <img src="/tutorial/utkozespont-tutorial-spot-icon.png" alt="Drag icon">
                    </div>
                    <div class="mav-tutorial-boxes-box-text">
                        Az elrejtett nyomok megtalálásához ezt a jelzést kell keresned. Ha rákattintasz, közelebbről is megnézheted az adott részletet.
                    </div>
                </div>
                <div class="mav-tutorial-boxes-box">
                    <div class="mav-tutorial-boxes-box-icon">
                        <img src="/tutorial/utkozespont-tutorial-map-icon.png" alt="Drag icon">
                    </div>
                    <div class="mav-tutorial-boxes-box-text">
                        A középső térképen láthatod, hogy az elrejtett nyomok hogyan helyezkednek el ahhoz képest, hogy éppen merre nézel.
                    </div>
                </div>
                <div class="mav-tutorial-boxes-box">
                    <div class="mav-tutorial-boxes-box-icon">
                        <img src="/tutorial/utkozespont-tutorial-pin-icon.png" alt="Drag icon">
                    </div>
                    <div class="mav-tutorial-boxes-box-text">
                       Hogy a következő nyomozási helyszínre léphess, ezt a jelzést kell keresned.
                    </div>
                </div>
                
            </div>
            <div class="mav-tutorial-signs">
                <div class="mav-tutorial-signs-sign">
                    <div class="mav-tutorial-signs-sign-icon">
                        <img src="/tutorial/utkozespont-volume-icon.svg" alt="Sign icon">
                    </div>
                    <div class="mav-tutorial-signs-sign-text">
                        Hangerő szabályzó
                    </div>
                </div>
                <div class="mav-tutorial-signs-sign">
                    <div class="mav-tutorial-signs-sign-icon">
                        <img src="/tutorial/utkozespont-play-icon.svg" alt="Sign icon">
                    </div>
                    <div class="mav-tutorial-signs-sign-text">
                        Videó indítása
                    </div>
                </div>
                <div class="mav-tutorial-signs-sign">
                    <div class="mav-tutorial-signs-sign-icon">
                        <img src="/tutorial/utkozespont-fullscreen-icon.svg" alt="Sign icon">
                    </div>
                    <div class="mav-tutorial-signs-sign-text">
                        Teljes képernyős mód
                    </div>
                </div>
                <div class="mav-tutorial-signs-sign">
                </div>
                <!-- <div class="mav-tutorial-signs-sign">
                    <div class="mav-tutorial-signs-sign-icon">
                        <img src="/tutorial/utkozespont-fullscreen-icon.svg" alt="Sign icon">
                    </div>
                    <div class="mav-tutorial-signs-sign-text">
                        Non ridiculus fames diam tortor malesuada cursus id.
                    </div>
                </div> -->

            </div>
        </div>
        <!-- <footer class="mav-main-footer mav-buttons-footer-right">
            <div class="mav-button-outer">
                <a id="back-btn" class="mav-button mav-button-icon mav-button-icon-left">
                    <img src="utkozespont-yellow-arrow-left.svg" alt="Arrow icon">
                    <span>Vissza</span>
                </a>
            </div>
            <div class="mav-button-outer">
                <a id="intro-btn" class="mav-button mav-button-icon mav-button-icon-right mav-button-secondary">
                    <span>Tovább</span>
                    <img src="utkozespont-yellow-arrow-right.svg" alt="Arrow icon">
                </a>
            </div>
        </footer> -->
        <footer class="mav-main-footer mav-buttons-footer mav-buttons-footer-right">
            <div class="mav-button-outer">
                <a id="intro-btn" class="mav-button mav-button-icon mav-button-icon-right mav-button-secondary">
                    <span>Tovább</span>
                    <img src="/tutorial/utkozespont-yellow-arrow-right.svg" alt="Arrow icon">
                </a>
            </div>
        </footer>
        <div class="mav-main-frame">
            <div class="mav-main-frame-item mav-main-frame-top-left">
                <img src="/utkozespont-frame-top-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-top-right">
                <img src="/utkozespont-frame-top-right.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-left">
                <img src="/utkozespont-frame-bottom-left.svg" alt="Frame item">
            </div>
            <div class="mav-main-frame-item mav-main-frame-bottom-right">
                <img src="/utkozespont-frame-bottom-right.svg" alt="Frame item">
            </div>
        </div>
    </div>
</template>

<script>
import FullScreenButton from '../components/FullScreenButton.vue';
export default {
  components: { FullScreenButton },
    name: "Guide",
    mounted: function() {
        var self = this;
    var prevScrollpos = window.pageYOffset;
    this.topPos = document.querySelector(".mav-tutorial-title").style.top
   let rect =  document.querySelector(".mav-tutorial-title").getBoundingClientRect();
   self.topPos = rect.top;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (currentScrollPos < prevScrollpos) {
            if(currentScrollPos < 100) {
            document.querySelector(".mav-tutorial-title").style.top = self.topPos+'px';
            let fs = document.querySelector(".mav-main-fullscreen");
            if(fs) fs.style.top = self.topPos+'px';

            }
        } else {
            if(currentScrollPos > 0){
            document.querySelector(".mav-tutorial-title").style.top = "-70px";
            let fs = document.querySelector(".mav-main-fullscreen")
            if(fs) fs.style.top = "-70px";
            }
        }
        prevScrollpos = currentScrollPos;
    }

    localStorage.clear()

    if (typeof this.$route.query.uuid !== 'undefined') {
        localStorage.uuid = self.$route.query.uuid
        self.$store.state.userId = self.$route.query.uuid
    }



        //OPTIMIZATION GERI
        var name = "unknown-browser";
        if (navigator.userAgent.indexOf("MSIE") != -1 || navigator.userAgent.indexOf("rv:11.0") != -1) name = "msie";
        else if (navigator.userAgent.indexOf("Edge") != -1) name = "microsoft-edge";
        else if (navigator.userAgent.indexOf("Firefox") != -1) name = "firefox";
        else if (navigator.userAgent.indexOf("Opera") != -1) name = "opera";
        else if (navigator.userAgent.indexOf("Chrome") != -1) name = "chrome";
        else if (navigator.userAgent.indexOf("Safari") != -1) name = "safari";

        var OSName = "unknown-os";

        if (navigator.appVersion.indexOf("Win") != -1) OSName = "windows";

        if (navigator.appVersion.indexOf("Mac") != -1) OSName = "macos";

        if (navigator.appVersion.indexOf("X11") != -1) OSName = "unix";

        if (navigator.appVersion.indexOf("Linux") != -1) OSName = "linux";

        var IEVersion = "not-ie";

        if (navigator.userAgent.indexOf("rv:11.0") != -1) IEVersion = "ie ie-11";

        else if (navigator.userAgent.indexOf("MSIE 10.0") != -1) IEVersion = "ie ie-10";

        else if (navigator.userAgent.indexOf("MSIE 9.0") != -1) IEVersion = "ie ie-9";

        else if (navigator.userAgent.indexOf("MSIE 8.0") != -1) IEVersion = "ie ie-8";

        else if (navigator.userAgent.indexOf("MSIE 7.0") != -1) IEVersion = "ie ie-7";

        var windowsVersion = 'not-windows';

        if (navigator.userAgent.indexOf("Windows NT 5.0") != -1) windowsVersion = "windows-2000";

        else if (navigator.userAgent.indexOf("Windows NT 5.1") != -1) windowsVersion = "windows-xp";

        else if (navigator.userAgent.indexOf("Windows NT 6.0") != -1) windowsVersion = "windows-vista";

        else if (navigator.userAgent.indexOf("Windows NT 6.1") != -1) windowsVersion = "windows-7";

        else if (navigator.userAgent.indexOf("Windows NT 6.2") != -1) windowsVersion = "windows-8";

        else if (navigator.userAgent.indexOf("Windows NT 6.3") != -1) windowsVersion = "windows-8-1";

        else if (navigator.userAgent.indexOf("Windows NT 10.0") != -1) windowsVersion = "windows-10";

        var device = "not-mobile"

        if (navigator.userAgent.match(/Android|BlackBerry|Kindle|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) device = "mobile";

        var isKindle = "not-kindle"

        if (navigator.userAgent.match(/Kindle|KFTHWI/i)) isKindle = "kindle";

        var body = document.body;
        body.classList.add(name);

        body.classList.add(OSName);

        body.classList.add(device);

        body.classList.add(IEVersion);

        body.classList.add(windowsVersion);

        body.classList.add(isKindle);


        document.getElementById('intro-btn').addEventListener('click', function () {
            self.$router.push({ path: '/intro' })
        })
    }
}
</script>

<style>
.mav-tutorial-title {
  transition: top 0.3s;
}
.mav-main-fullscreen {
  transition: top 0.3s;
}

.mav-main {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 30px;
    background-image: url(../../public/utkozespont-bg.jpg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 15% center;
}

.mav-main>* {
    z-index: 1;
}

.mav-main-top-position-helper {
    width: 0px;
    height: 0px;
    opacity: 0;
    visibility: hidden;
}

.mav-main-content {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.mav-tutorial-content-title {
font-style: normal;
font-weight:bold;
font-size: 20px;
line-height: 25px;
color: #FFF;
text-align: center;
align-self:center;
margin-top: 20px;
width: calc(100% - 40px);
margin-bottom: 50px;
}

.mav-main-content.mav-tutorial-content {
    justify-content: center;
}

.mav-main.mav-tutorial {
    background-image: url(../../public/tutorial/utkozespont-tutorial-bg.jpg);
    background-size: cover;
    background-position: center;
}

body.firefox .mav-main.mav-tutorial {
    background-image: url(../../public/tutorial/utkozespont-tutorial-bg-blurred.jpg);
}

.mav-tutorial::before {
    content: "";
    background: linear-gradient(180deg, rgba(47, 35, 24, 0.2) 0%, rgba(47, 35, 24, 0) 103.5%);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.mav-tutorial-title {
    position: fixed;
    z-index: 2;
    top: 70px;
    left: 70px;
    display: flex;
    align-items: center;
}

.mav-tutorial-title .mav-tutorial-title-logo {
    max-width: 220px;
    margin-right: 30px;
}

.mav-tutorial-title .mav-tutorial-title-divider {
    margin-right: 30px;
    height: 50px;
    width: 1px;
    background-color: #fff;
    opacity: 0.3;
}

.mav-tutorial-title .mav-tutorial-title-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    color: #fff;
}

.mav-tutorial-boxes {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
}

.mav-tutorial-boxes .mav-tutorial-boxes-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    padding-top: 40px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
}

body.firefox .mav-tutorial-boxes .mav-tutorial-boxes-box {
    background: rgba(0, 18, 55, 0.2);
}

.mav-tutorial-boxes .mav-tutorial-boxes-box .mav-tutorial-boxes-box-icon {
    margin-top: -80px;
}

.mav-tutorial-boxes .mav-tutorial-boxes-box .mav-tutorial-boxes-box-icon img {
    max-height: 80px;
    max-width: 80px;
}

.mav-tutorial-boxes .mav-tutorial-boxes-box .mav-tutorial-boxes-box-text {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.7;
    padding: 20px 40px 30px;
}

.mav-tutorial-signs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
}

.mav-tutorial-signs .mav-tutorial-signs-sign {
    display: flex;
    align-items: center;
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
}

.mav-tutorial-signs .mav-tutorial-signs-sign .mav-tutorial-signs-sign-icon {
    min-width: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 100px;
    margin-right: 20px;
}

body.firefox .mav-tutorial-signs .mav-tutorial-signs-sign .mav-tutorial-signs-sign-icon {
    background: rgba(0, 18, 55, 0.2);
}

.mav-tutorial-signs .mav-tutorial-signs-sign .mav-tutorial-signs-sign-text {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.7;

    
}

.mav-button-outer {
    padding: 8px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 100px;
}

body.firefox .mav-button-outer {
    background: rgba(0, 18, 55, 0.2);
}

.mav-button {

    font-weight: 600;
    line-height: 1;
    font-size: 14px;
    color: #00287A;
    height: 40px;
    padding: 5px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-width: 140px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-button-primary {
    color: #00287A;
    background-color: #FFCC33;
}

.mav-button-primary::after {
    content: "";
    background: linear-gradient(90deg, #FFCC33 30%, #FFE9A7 105%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-button-primary {
    color: #00287A;
    background-color: #fff;
}

.mav-button-secondary {
    color: #fff;
    background-color: #00287A;
}

.mav-button-secondary::after {
    content: "";
    background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-button.mav-button-icon {
    justify-content: center;
}

.mav-button.mav-button-icon-left img {
    margin-right: 14px;
}

.mav-button.mav-button-icon-right img {
    margin-left: 14px;
}

.mav-main-nav {
    position: relative;
    top: 40px;
    left: 40px;
    max-width: 340px;
    margin-top: 50px;
}

.mav-main-nav-logo {
    margin-bottom: 40px;
}

.mav-main-nav-logo a {
    display: flex;
    align-items: center;
}

.mav-main-nav-item {
    padding: 10px;
    background: rgba(0, 40, 122, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(42px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 100px;
    /* margin-bottom: 10px; */
}

body.firefox .mav-main-nav-item {
    background: rgba(0, 18, 55, 0.2);
}

.mav-main-nav-item-button {

    font-weight: 600;
    line-height: 1;
    font-size: 18px;
    color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary {
    color: #00287A;
    background-color: #FFCC33;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary::after {
    content: "";
    background: linear-gradient(90deg, #FFCC33 30%, #FFE9A7 105%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-nav-item-button.mav-main-nav-item-button-primary {
    color: #00287A;
    background-color: #fff;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary {
    color: #fff;
    background-color: #00287A;
}

.mav-main-nav-item-button.mav-main-nav-item-button-secondary::after {
    content: "";
    background: linear-gradient(90deg, #00287A 40%, #0075FF 160%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-footer {
    position: relative;
    bottom: 70px;
    left: 70px;
    display: flex;
    align-items: center;
    max-width: 54%;
}

.mav-main-footer.mav-buttons-footer {
    max-width: 100%;
    width: calc(100% - 140px);
    justify-content: space-between;
    position: fixed;
}

.mav-buttons-footer.mav-buttons-footer-right {
    justify-content: flex-end;
}

.mav-main-footer-social {
    display: flex;
    align-items: center;
}

.mav-main-footer-social-icon a {
    display: flex;
    align-items: center;
}

.mav-main-footer-social-icon img {
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}

.mav-main-footer-line {
    flex: 1;
    height: 1px;
    background-color: #fff;
    opacity: 0.3;
}

.mav-main-footer-social-icon {
    margin-right: 30px;
}

/* .mav-main-fullscreen {
    position: fixed;
    top: 70px;
    right: 70px;
    z-index: 2;
}

.mav-main-fullscreen-icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: rgba(0, 40, 122, 0.04);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
} */

body.firefox .mav-main-fullscreen-icon {
    background: rgba(0, 18, 55, 0.1);
}

.mav-main-frame {
    pointer-events: none;
    touch-action: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    transform: translate(-50%, -50%);
    z-index: 0;
}

.mav-main-frame-item {
    position: absolute;
    width: 50px;
    height: 50px;
    opacity: 0.3;
}

.mav-main-frame-item.mav-main-frame-top-left {
    top: 0;
    left: 0;
}

.mav-main-frame-item.mav-main-frame-top-right {
    top: 0;
    right: 0;
}

.mav-main-frame-item.mav-main-frame-bottom-left {
    bottom: 0;
    left: 0;
}

.mav-main-frame-item.mav-main-frame-bottom-right {
    bottom: 0;
    right: 0;
}

.mav-main-frame-hidden {
    display: none;
}

@media only screen and (max-width: 1439px), (max-height: 799px) {
    .mav-main {
        background-position: 25% center;
        padding: 20px;
    }

    .mav-main-footer {
        max-width: 50%;
    }

    .mav-main-frame {
        width: calc(100% - 40px);
        height: calc(100% - 40px);
    }

    .mav-main-nav {
        margin-top: 0;
        max-width: 300px;
    }

    .mav-main-nav-item-button {
        height: 50px;
    }

    .mav-tutorial-boxes {
        margin-bottom: 60px;
        width: calc(100% - 30px);
    }

    .mav-tutorial-boxes .mav-tutorial-boxes-box {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mav-tutorial-signs {
        width: calc(100% - 30px);
    }

    .mav-tutorial-signs .mav-tutorial-signs-sign {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mav-tutorial-title {
        top: 50px;
        left: 50px;
    }

    /* .mav-main-fullscreen {
        top: 50px;
        right: 50px;
    } */

    .mav-main-footer {
        bottom: 50px;
        left: 50px;
    }

    .mav-main-footer.mav-buttons-footer {
        width: calc(100% - 100px);
    }
}

@media only screen and (max-width: 980px), (max-height: 600px) {
    .mav-main-footer {
        max-width: 50%;
    }

    .mav-main-nav {
        margin-top: 0;
        max-width: 220px;
        top: 40px;
        left: 40px;
    }

    .mav-main-nav-item-button {
        height: 45px;
        font-size: 16px;
    }

    .mav-main-nav-logo {
        margin-bottom: 20px;
    }

    /* .mav-main-fullscreen {
        top: 40px;
        right: 40px;
    }

    .mav-main-fullscreen-icon {
        width: 45px;
        height: 45px;
    } */

    .mav-main-frame-item {
        width: 45px;
        height: 45px;
    }

    .mav-main-footer.mav-buttons-footer {
        width: calc(100% - 80px);
    }

    .mav-main-footer {
        bottom: 40px;
        left: 40px;
    }

    .mav-tutorial-title {
        top: 40px;
        left: 40px;
    }

    .mav-tutorial-title .mav-tutorial-title-logo {
        max-width: 150px;
        margin-right: 20px;
    }

    .mav-tutorial-title .mav-tutorial-title-divider {
        margin-right: 20px;
        height: 45px;
    }

    .mav-tutorial-title .mav-tutorial-title-text {
        font-size: 16px;
        line-height: 1.2;
    }

    .mav-main-content {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mav-button-outer {
        padding: 6px;
    }

    .mav-button {
        height: 35px;
        min-width: 130px;
    }

    .mav-tutorial-boxes .mav-tutorial-boxes-box {
        flex: 0 0 calc(50% - 30px);
        margin-bottom: 70px;
    }

    .mav-tutorial-boxes .mav-tutorial-boxes-box .mav-tutorial-boxes-box-text {
        font-weight: 600;
        font-size: 13px;
        line-height: 1.5;
        padding: 10px 30px 20px;

    }

    .mav-tutorial-boxes .mav-tutorial-boxes-box .mav-tutorial-boxes-box-icon img {
        max-height: 70px;
        max-width: 70px;
    }

    .mav-tutorial-boxes {
        padding-top: 30px;
        margin-bottom: -20px;
    }

    .mav-tutorial-signs {
        margin-bottom: -30px;
    }

    .mav-tutorial-signs .mav-tutorial-signs-sign {
        flex: 0 0 calc(50% - 30px);
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 800px), (max-height: 400px) {
    .mav-main {
        background-position: 20% center;
        padding: 15px;
    }

    .mav-main-footer {
        max-width: 50%;
        bottom: 30px;
        left: 30px;
    }

    .mav-main-footer.mav-buttons-footer {
        width: calc(100% - 60px);
    }

    .mav-main-nav {
        max-width: 200px;
        top: 30px;
        left: 30px;
    }

    .mav-main-nav-item {
        padding: 8px;
    }

    .mav-main-nav-item-button {
        height: 40px;
        font-size: 14px;
    }

    .mav-main-nav-logo {
        margin-bottom: 20px;
    }

    /* .mav-main-fullscreen {
        top: 30px;
        right: 30px;
    }

    .mav-main-fullscreen-icon {
        width: 38px;
        height: 38px;
    }

    .mav-main-fullscreen-icon img {
        width: 16px;
        height: 16px;
    } */

    .mav-main-frame {
        width: calc(100% - 25px);
        height: calc(100% - 25px);
    }

    .mav-main-footer-social-icon img {
        max-width: 16px;
        max-height: 16px;
    }

    .mav-tutorial-title {
        top: 30px;
        left: 30px;
    }

    .mav-tutorial-boxes {
        width: 100%;
    }

    .mav-tutorial-signs {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .mav-tutorial-boxes .mav-tutorial-boxes-box {
        flex: 0 0 calc(100% - 30px);
    }

    .mav-tutorial-signs .mav-tutorial-signs-sign {
        flex: 0 0 calc(100% - 30px);
    }

    .mav-tutorial-title {
        max-width: 250px;
    }

    .mav-tutorial-title .mav-tutorial-title-divider {
        display: none;
    }

    .mav-main-footer {
        max-width: 100%;
    }
}

@media only screen and (max-width: 370px) {
    .mav-tutorial-title-text {
        display:none;
    }
}
</style>